import { useContext, useEffect, useRef, useState } from "react";
import { ManageDataSourcesContext, Pages } from "./context";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ComposedTextInput } from "../../../components/ComposedTextInput";
import { CreateLayerSourceModel } from "../../../api/ManagerApi";
import { ConfirmModal } from "../ConfirmModal";
import { SimpleMapViewer } from "../../../components/SimpleMapViewer";
import { useSurveyState } from "../../../SurveyContext";
import usePromise from "../../../utils/usePromise";
import { AnyLayer, CustomLayerInterface, Layer } from "mapbox-gl";
import { isLayerSource } from "../../../utils/apiUtils";
import { InfoIcon, WarningIcon } from "@chakra-ui/icons";
import { MapRef } from "react-map-gl";

export const StartAddCustomLayer = () => {
  const { state, setState } = useContext(ManageDataSourcesContext);
  const { mapboxApi } = useSurveyState();
  const [name, setName] = useState("Custom Layer");
  const [styleId, setStyleId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [previewSource, setPreviewSource] = useState<CreateLayerSourceModel>();
  const [clusters, setClusters] = useState<string[]>([]);
  const mapRef = useRef<MapRef>(null!);

  useEffect(
    () =>
      setState((c) => ({ ...c, nextPage: c.sourcesToImport.length ? Pages.Confirm : undefined })),
    [setState]
  );

  useEffect(() => {
    if (!name || !styleId || !accessToken) return;

    setPreviewSource({
      name,
      styleId,
      accessToken,
      clusters,
      bounds: {
        north: 53.62,
        east: 7.79,
        south: 50.5,
        west: 2.23,
      },
      type: isLayerSource.expected,
    });
  }, [name, styleId, accessToken, clusters]);

  const [availableClusters] = usePromise(async () => {
    const raw = await mapboxApi.styles.get(styleId, accessToken).then((r) => r.data);

    const customLayers = raw.layers
      .filter((l: AnyLayer): l is Exclude<AnyLayer, CustomLayerInterface> =>
        l.hasOwnProperty("metadata")
      )
      .filter((l) => l.metadata && !l.metadata["mapbox:featureComponent"]);

    const clusters = customLayers.reduce((acc, curr) => {
      const id = curr.metadata["mapbox:group"];
      const name = raw.metadata["mapbox:groups"][id].name;
      const layers = acc.get(name) ?? [];
      acc.set(name, layers);
      layers.push(curr);
      return acc;
    }, new Map<string, Layer[]>());

    return clusters;
  }, [previewSource, mapboxApi.styles]);

  return (
    <>
      <Text my={2}>Wat is de naam van de kaartlaag</Text>
      <FormControl variant="floating" isRequired my={4}>
        <FormLabel>
          <Text fontSize="md">Naam</Text>
        </FormLabel>
        <Input value={name} onChange={(e) => setName(e.target.value.trim())} />
      </FormControl>
      <Text my={2}>
        Voer de style identificatie in voor de kaartlaag. Deze bestaat uit een gebruikersnaam en een
        identifier
      </Text>
      <ComposedTextInput
        autoComplete="disabled"
        prefix="mapbox://styles/"
        value={styleId}
        onValueChange={setStyleId}
        placeholder="mapbox/streets-v12"
      />
      <Text my={2}>Geef een geldige mapbox accesstoken op.</Text>
      <Flex gap={2} alignItems="center" mb={4}>
        <Input
          onChange={(e) => setAccessToken(e.currentTarget.value.trim())}
          value={accessToken}
          autoComplete="disabled"
        />
      </Flex>
      {
        <ConfirmModal
          modalProps={{ size: "5xl" }}
          bodyNode={
            previewSource ? (
              <>
                <Flex h="xl" gap={4}>
                  <SimpleMapViewer source={{ ...previewSource, clusters }} ref={mapRef} />
                  {!!availableClusters?.size && (
                    <CheckboxGroup>
                      <Accordion
                        allowMultiple
                        overflowY="auto"
                        overflowX="hidden"
                        w="lg"
                        defaultIndex={[0]}
                      >
                        {Array.from(availableClusters, ([name, layers]) => (
                          <AccordionItem my={2}>
                            <AccordionButton fontStyle="italic" justifyContent="space-between">
                              <span>
                                {name} ({layers.length})
                              </span>
                              {layers.length > 1 ? (
                                layers.every(({ id }) => clusters.includes(id)) ? (
                                  <Checkbox
                                    isChecked
                                    onChange={() => {
                                      const newClusters = new Set(clusters);
                                      layers.forEach(({ id }) => newClusters.delete(id));
                                      setClusters(Array.from(newClusters));
                                    }}
                                  >
                                    Alles deselecteren
                                  </Checkbox>
                                ) : (
                                  <Checkbox
                                    isChecked={false}
                                    onChange={() => {
                                      const newClusters = new Set(clusters);
                                      layers.forEach(({ id }) => newClusters.add(id));
                                      setClusters(Array.from(newClusters));
                                    }}
                                  >
                                    Alles selecteren
                                  </Checkbox>
                                )
                              ) : null}
                            </AccordionButton>
                            <AccordionPanel py={2}>
                              <Stack>
                                {layers.map((layer) => (
                                  <Checkbox
                                    key={layer.id}
                                    maxW="full"
                                    my={1}
                                    isChecked={clusters.includes(layer.id)}
                                    onChange={() => {
                                      setClusters((c) =>
                                        c.includes(layer.id)
                                          ? c.filter((id) => id !== layer.id)
                                          : [...c, layer.id]
                                      );
                                    }}
                                  >
                                    {layer.id}
                                  </Checkbox>
                                ))}
                              </Stack>
                            </AccordionPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </CheckboxGroup>
                  )}
                </Flex>
                <Text textAlign="end" color="blue.700" mt={4}>
                  <InfoIcon mr={2} verticalAlign="middle" />
                  Het huidige zicht op de kaart wordt bij de kaartlaag opgeslagen.
                </Text>
              </>
            ) : (
              <Text colorScheme="red">
                <WarningIcon /> De kaartlaag kon niet worden geladen
              </Text>
            )
          }
          confirmNode="Opslaan en doorgaan"
          onConfirm={() => {
            const glBounds = mapRef.current.getMap().getBounds();
            const bounds = {
              north: glBounds.getNorth(),
              east: glBounds.getEast(),
              south: glBounds.getSouth(),
              west: glBounds.getWest(),
            };

            setState({
              ...state,
              previousText: "Nog een bron toevoegen",
              previousPage: Pages.StartAddCustom,
              currentPage: Pages.Confirm,
              nextPage: undefined,
              sourcesToImport: [
                ...state.sourcesToImport,
                Object.assign({}, previewSource, { clusters, bounds }),
              ],
            });
          }}
          titleNode="Selecteer clusters"
          buttonNode={
            <Button disabled={!styleId || !accessToken || !previewSource}>Bekijk kaartlaag</Button>
          }
        />
      }
    </>
  );
};
