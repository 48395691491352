import type { ComponentStyleConfig } from "@chakra-ui/react";

export const CheckboxTheme: ComponentStyleConfig = {
  baseStyle: {
    container: {
      bg: "gray.100",
      py: 1,
      px: 3,
      w: "max-content",
      h: "min-content",
      fontSize: "1rem",
      borderRadius: "1000px",
      _hover: {
        ".chakra-checkbox__control": {
          borderColor: "blue.500",
        },
      },
      ".chakra-checkbox__label": {
        w: "max-content",
      },
    },
    control: {
      bg: "white",
      h: "1rem",
      w: "1rem",
      borderColor: "black",
      borderWidth: "1px",
      borderRadius: 4,
      transition: "all 0.2s filter 0s",
      _checked: {
        bg: "blue.500",
        borderColor: "blue.500",
        _hover: {
          bg: "blue.600",
          borderColor: "blue.600",
        },
      },
      _indeterminate: {
        bg: "blue.500",
        borderColor: "blue.500",
        _hover: {
          bg: "blue.600",
          borderColor: "blue.600",
        },
      },
      _hover: {
        borderColor: "blue.500",
      },
    },
  },
  variants: {
    white: {
      container: {
        bg: "white",
      },
    },
    star: {
      container: {
        bg: "transparent",
      },
      control: {
        bg: "none",
        backgroundImage: "/images/IconFavorite.svg",
        border: "none",
        width: "1.75rem",
        height: "1.75rem",
        div: {
          display: "none !important",
        },
        _hover: {
          bg: "none",
          backgroundImage: "/images/IconFavorite.svg",
          filter: "var(--filter-teal-600)",
        },
        _checked: {
          filter: "var(--filter-teal-500)",
          bg: "none",
          backgroundImage: "/images/IconFavoriteActive.svg",
          _hover: {
            filter: "var(--filter-teal-600)",
            bg: "none",
            backgroundImage: "/images/IconFavoriteActive.svg",
          },
        },
      },
    },
    starInverted: {
      container: {
        bg: "transparent",
      },
      control: {
        bg: "none",
        backgroundImage: "/images/IconFavorite.svg",
        filter: "var(--filter-white)",
        border: "none",
        width: "1.75rem",
        height: "1.75rem",
        div: {
          display: "none !important",
        },
        _hover: {
          bg: "none",
          backgroundImage: "/images/IconFavorite.svg",
          filter: "var(--filter-white)",
        },
        _checked: {
          filter: "var(--filter-white)",
          bg: "none",
          backgroundImage: "/images/IconFavoriteActive.svg",
          _hover: {
            filter: "var(--filter-white)",
            bg: "none",
            backgroundImage: "/images/IconFavoriteActive.svg",
          },
        },
      },
    },
  },
};
