import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Radio,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useSurveyState } from "../../SurveyContext";

type Props = {
  onUpdate: () => void;
};

const questionTypes = [
  { label: "Multiple Pins", value: "LocationPickerQuestion" },
  { label: "Open vraag", value: "TextQuestion" },
  { label: "Bestand", value: "FileUploadQuestion" },
  { label: "Ja/nee vraag", value: "BooleanQuestion" },
  { label: "Meerkeuze", value: "ChoiceQuestion" },
  { label: "Waardering", value: "RatingQuestion" },
  { label: "Tracker", value: "TrackingQuestion" },
];

const otherTypes = [{ label: "Uitlegblok", value: "Element" }];

export const AddQuestionModal = ({ onUpdate }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState("LocationPickerQuestion");
  const { surveyId, pageId: surveyPageId } = useParams();
  const { api } = useSurveyState();

  const addQuestion = useCallback(() => {
    if (!surveyId || !surveyPageId) return;
    api.surveys
      .createElement(parseInt(surveyId), {
        type: value,
        pageId: parseInt(surveyPageId),
        index: null,
      })
      .then((r) => {
        if (r.status === 201) {
          onUpdate();
          onClose();
        }
      });
  }, [surveyId, surveyPageId, api.surveys, value, onUpdate, onClose]);

  return (
    <>
      <Button onClick={onOpen} mt={4} ml={4}>
        Vraag toevoegen
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Vraag toevoegen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup value={value} onChange={setValue}>
              <Text fontWeight={"bold"} my={2}>
                Vragen
              </Text>
              <Stack>
                {questionTypes.map((questionType) => (
                  <Radio value={questionType.value} key={questionType.value}>
                    {questionType.label}
                  </Radio>
                ))}
              </Stack>
              <Text fontWeight={"bold"} my={2}>
                Overig
              </Text>
              <Stack>
                {otherTypes.map((questionType) => (
                  <Radio value={questionType.value} key={questionType.value}>
                    {questionType.label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Annuleren
            </Button>
            <Button onClick={addQuestion}>Vraag toevoegen</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
