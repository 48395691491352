import { Select } from "@chakra-ui/react";
import { useState } from "react";

const statuses = [
  { value: "open", label: "Open" },
  { value: "closed", label: "Gesloten" },
];

export const StatusDropdown = () => {
  const [status, setStatus] = useState(statuses[0].value);

  return (
    <Select value={status} onChange={(e) => setStatus(e.target.value)}>
      {statuses.map((status) => (
        <option key={status.value} value={status.value}>
          {status.label}
        </option>
      ))}
    </Select>
  );
};
