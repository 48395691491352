import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Text,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { CreateApiKeyModel } from "../../api/ManagerApi";
import { AddIcon } from "@chakra-ui/icons";

type Props = {
  onAdd: (isPublic: boolean, displayName: string) => void;
};

export const AddApiKeyModal = ({ onAdd }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const displayNameRef = useRef<HTMLInputElement>(null);
  const isPublicRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<{ [K in keyof CreateApiKeyModel]?: string[] }>({});

  const clearError = useCallback(
    (fieldName: keyof CreateApiKeyModel) =>
      setErrors((e) => ({ ...e, [fieldName]: undefined })),
    []
  );

  const create = useCallback(() => {
    if (isPublicRef.current && displayNameRef.current) {
      onAdd(isPublicRef.current.checked, displayNameRef.current.value);
      onClose();
    }
  }, [onAdd, onClose]);

  return (
    <>
      <Button onClick={onOpen} my={4} display="flex" ml="auto">
        <AddIcon mr={2} />
        API Key toevoegen
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>API Key toevoegen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Wat is de naam van de nieuwe Api Key?</Text>
            <FormControl variant="floating" isInvalid={Boolean(errors?.displayName?.length)}>
              <FormLabel>
                <Text fontSize="md">Naam</Text>
              </FormLabel>
              <Input
                required
                ref={displayNameRef}
                onClick={(e) => e.currentTarget.select()}
                onChange={() => clearError("displayName")}
              />
              {errors.displayName?.map((e) => <FormErrorMessage key={e}>{e}</FormErrorMessage>)}
            </FormControl>
            <Text mt={3}>
              Is deze Api Key bedoeld voor publiek gebruik?
              <br />
              Dit betekent dat deze key uitsluitend voor het inzenden van data gebruikt kan worden.
            </Text>
            <Checkbox my={2} ref={isPublicRef} defaultChecked={false}>
              Dit is een publieke key.
            </Checkbox>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Annuleren
            </Button>
            <Button colorScheme={"blue"} onClick={create}>
              Aanmaken
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
