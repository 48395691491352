import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Flex,
  Button,
  NumberInputField,
  NumberInput,
  Select,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TextQuestionModel, UpdateTextQuestionModel } from "../api/ManagerApi";
import { QuestionBox } from "../components/QuestionBox";
import { useSurveyState } from "../SurveyContext";
import { isTextQuestion } from "../utils/apiUtils";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";

type Props = {
  questionId: number;
};

// TODO: find a way to emit this with Swagger.
type SupportedSubTypes = "text" | "textarea" | "email" | "phone" | "url";

const subTypeWithDisplayText: Array<{ type: string; displayText: string }> = [
  { type: "text", displayText: "Tekst" },
  { type: "textarea", displayText: "Tekstvak" },
  { type: "email", displayText: "E-mail" },
  { type: "phone", displayText: "Telefoon" },
  { type: "url", displayText: "URL" },
];

export const OpenQuestion = ({ questionId }: Props) => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const { api, pageToken, updateSurveyToken, updatePageToken } = useSurveyState();
  const [valid, setValid] = useState(true);
  const [, updateView] = useForceUpdate("View");

  useEffect(() => updateQuestionToken(), [pageToken, updateQuestionToken]);

  const [question] = usePromise(() => {
    return api.surveys.getElement(Number(surveyId), questionId).then((r) => {
      if (isTextQuestion(r.data)) return r.data;
    });
  }, [questionRefreshToken]);

  const updateQuestion = useCallback(
    (questionModel: Partial<TextQuestionModel>) => {
      if (!surveyId || !question || !surveyPageId) return;
      api.surveys
        .updateElement(Number(surveyId), question.id, {
          pageId: Number(surveyPageId),
          ...question,
          ...questionModel,
        } as UpdateTextQuestionModel & { subType: SupportedSubTypes })
        .then(updateSurveyToken)
        .then(() => setValid(true))
        .then(updateQuestionToken)
        .catch(() => {
          setValid(false);
          Object.assign(question, questionModel);
          updateView();
        });
    },
    [
      api.surveys,
      question,
      surveyId,
      surveyPageId,
      updateSurveyToken,
      updateQuestionToken,
      updateView,
    ]
  );

  const deleteQuestion = useCallback(() => {
    if (!surveyId || !question) return;
    api.surveys
      .deleteElement(parseInt(surveyId), question.id)
      .then(updateSurveyToken)
      .then(updatePageToken);
  }, [api.surveys, question, surveyId, updatePageToken, updateSurveyToken]);

  if (!question) return null;

  return (
    <QuestionBox questionType="Open vraag" questionTitle={question?.title} valid={valid}>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Vraag</Text>
        </FormLabel>
        <Input
          defaultValue={question?.title}
          onBlur={(e) =>
            updateQuestion({
              title: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Omschrijving</Text>
        </FormLabel>
        <Input
          defaultValue={question?.content}
          onBlur={(e) =>
            updateQuestion({
              content: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <Checkbox
        mt={4}
        defaultChecked={question.required}
        onChange={(e) =>
          updateQuestion({
            required: e.target.checked,
          })
        }
      >
        Verplicht
      </Checkbox>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Type</Text>
        </FormLabel>
        <Select
          defaultValue={question?.subType ?? "text"}
          onChange={(e) => {
            updateQuestion({
              subType: e.target.value as SupportedSubTypes,
              maxLength:
                ["text", "textarea"].includes(e.target.value) && !question.subType ? 100 : null,
            });
          }}
        >
          {subTypeWithDisplayText.map((entry) => (
            <option key={entry.type} value={entry.type}>
              {entry.displayText}
            </option>
          ))}
        </Select>
      </FormControl>
      <Flex gap={4} alignItems="end">
        {["text", "textarea"].includes(question.subType) && (
          <FormControl variant="floating" w={"25%"}>
            <FormLabel>
              <Text fontSize="md">Karakter limiet</Text>
            </FormLabel>
            <NumberInput
              defaultValue={question.maxLength ?? 0}
              onChange={(vas, van) =>
                updateQuestion({
                  maxLength: vas.length > 0 ? van : 0,
                })
              }
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>
        )}
      </Flex>
      <Flex>
        <Button ml="auto" colorScheme="red" onClick={deleteQuestion}>
          Vraag verwijderen
        </Button>
      </Flex>
    </QuestionBox>
  );
};
