import { useCallback, useContext, useEffect } from "react";
import { ManageDataSourcesContext, Pages } from "./context";
import { RadioGroup, Stack, Radio, Text } from "@chakra-ui/react";

const options = [
  { value: Pages.StartAddCustomPano, label: "Panorama" },
  { value: Pages.StartAddCustomLayer, label: "Kaartlaag" },
];

export const StartAddCustom = () => {
  const { state, setState } = useContext(ManageDataSourcesContext);

  useEffect(
    () =>
      setState((c) => ({
        ...c,
        nextPage: undefined,
      })),
    [setState]
  );

  const updateValue = useCallback(
    (s: Pages) => setState((c) => ({ ...c, nextPage: s })),
    [setState]
  );

  return (
    <>
      <Text my={2}>Wat voor databron wil je toevoegen?</Text>
      <RadioGroup value={state.nextPage} onChange={(s) => updateValue(Number(s) as Pages)}>
        <Stack>
          {options.map(({ value: v, label }) => (
            <Radio value={v} key={v}>
              {label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </>
  );
};
