import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { QuestionnaireListItemModel } from "../api/ManagerApi";
import { AddSurveyModal } from "../compositions/modals/AddSurveyModal";
import { DeleteSurveyModal } from "../compositions/modals/DeleteSurveyModal";
import { useFrameContext } from "../FrameContext";
import { useSurveyState } from "../SurveyContext";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";
import { format } from "date-fns";
import { LoginModal } from "../compositions/modals/LoginModal";
import { Secure } from "../components/Secure";

export const LandingPage = () => {
  const navigate = useNavigate();
  const { api } = useSurveyState();
  const [interfaceRefreshToken, updateInterfaceToken] = useForceUpdate("Landing");
  const { origin, authenticate } = useFrameContext();
  const [selectedSurveyForDelete, setSelectedSurveyForDelete] =
    useState<QuestionnaireListItemModel>();

  const [surveys] = usePromise(async () => {
    await authenticate();

    return api.surveys.listSurveys().then((r) => r.data);
  }, [api.surveys, origin, authenticate, interfaceRefreshToken]);

  const editSurvey = useCallback(
    (id: number) => {
      api.surveys.getSurvey(id).then((r) => {
        const pageId = r.data.pages[0].id;
        if (pageId) {
          navigate(`/edit/${id}/page/${pageId}`);
          return;
        }
      });
    },
    [api.surveys, navigate]
  );

  return (
    <Box>
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        mb={8}
        py={2}
        bg="blue.500"
        color="white"
        gap={4}
      >
        <Image src="tim-logo.svg" w="40px" h="40px" filter="invert(1)" ml={8} />
        <Heading size="md">The Imagineers enquête beheeromgeving</Heading>
        {window === window.parent && <LoginModal />}
      </Flex>
      <Secure
        message={
          <Box maxW="container.xl" w="100%" px={4} mx="auto">
            <Text>Geef een API Key op om je enquêtes te beheren</Text>
          </Box>
        }
      >
        {!!surveys?.length && (
          <Flex
            w="100%"
            justifyContent="center"
            gap={16}
            my={4}
            textAlign="center"
            color="gray.600"
          >
            <Box px={8} py={4}>
              <Heading as="p">{surveys.length}</Heading>
              <Text>vragenlijsten</Text>
            </Box>
            <Box px={8} py={4}>
              <Heading as="p">{surveys.map((s) => s.responses).reduce((a, b) => a + b, 0)}</Heading>
              <Text>respondenten</Text>
            </Box>
          </Flex>
        )}
        <Box maxW="container.xl" w="100%" px={4} mx="auto">
          <AddSurveyModal onAdd={editSurvey} />
          <DeleteSurveyModal
            survey={selectedSurveyForDelete}
            onDelete={() => {
              updateInterfaceToken();
              setSelectedSurveyForDelete(undefined);
            }}
            onCancel={() => setSelectedSurveyForDelete(undefined)}
          />
          {surveys ? (
            <>
              {surveys.length ? (
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Vragenlijst</Th>
                      <Th>Aangemaakt op</Th>
                      <Th>Laatste wijziging</Th>
                      <Th>Respondenten</Th>
                      <Th>Acties</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {surveys?.map((survey) => (
                      <Tr key={survey.id}>
                        <Td>
                          <Box onClick={() => editSurvey(survey.id)} cursor="pointer">
                            {/* <Button mr={4}> */}
                            <EditIcon mr={4} color="blue.500" />
                            {/* </Button> */}
                            {survey.name}
                          </Box>
                        </Td>
                        <Td>
                          {survey.createdDateTime !== "0001-01-01T00:00:00"
                            ? format(survey.createdDateTime, "dd-MM-yyyy")
                            : "-"}
                        </Td>
                        <Td>
                          {survey.updatedDateTime !== "0001-01-01T00:00:00"
                            ? format(survey.updatedDateTime, "dd-MM-yyyy HH:mm")
                            : "-"}
                        </Td>
                        <Td>{survey.responses}</Td>
                        <Td>
                          <Flex gap={2}>
                            <Link to={`/results/${survey.id}`}>Bekijk resultaten</Link>
                            {survey.responses === 0 && (
                              <Box
                                ml={4}
                                onClick={() => setSelectedSurveyForDelete(survey)}
                                color="red.500"
                                cursor="pointer"
                              >
                                <DeleteIcon mr={2} />
                                Verwijder
                              </Box>
                            )}
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text>Nog geen enquêtes beschikbaar</Text>
              )}
            </>
          ) : (
            <Spinner />
          )}
          <Button mt={8} onClick={() => navigate("/keys")}>
            API Keys beheren
          </Button>
        </Box>
      </Secure>
    </Box>
  );
};
