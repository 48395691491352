import { Box, Text } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { Navigate, useOutletContext, useParams } from "react-router-dom";
import { AnswerModel } from "../api/ManagerApi";
import { ResultsPageOutletContext } from "../pages/Results";
import { useSurveyState } from "../SurveyContext";
import usePromise from "../utils/usePromise";
import { QuestionResult } from "./QuestionResult";
import { format } from "date-fns";

export const SingleResult = () => {
  const { survey } = useOutletContext<ResultsPageOutletContext>();
  const { respondentId: respondentIdRaw } = useParams();
  const { api } = useSurveyState();
  const respondentId = Number(respondentIdRaw);

  const isValidResponseId = useMemo(
    () => survey.responses.some((r) => r.id === respondentId),
    [survey.responses, respondentId]
  );

  const questions = useMemo(() => survey.pages.flatMap((p) => p.elements), [survey]);

  const getQuestionName = useCallback(
    (answer: AnswerModel) => {
      return questions.find((q) => q.id === answer.questionId)?.title ?? "";
    },
    [questions]
  );

  const [response] = usePromise(
    () => api.surveys.getResponse(survey.id, respondentId).then((r) => r.data),
    [api.surveys, survey.id, respondentId]
  );

  const answerViews = useMemo(() => {
    if (!response?.answers.length) return null;

    return response.answers.map((a) => (
      <QuestionResult
        single
        entries={[a]}
        key={a.questionId}
        name={getQuestionName(a)}
        responses={1}
      />
    ));
  }, [response, getQuestionName]);

  if (!isValidResponseId) return <Navigate to=".." />;
  if (!response) return null;

  return (
    <Box maxW="container.xl" w="100%" px={4} mx="auto">
      <Text mb={4}>Ingezonden op: {format(response.submissionDateTime, "dd-MM-yyyy HH:mm")}</Text>
      {answerViews ?? <Text>Er zijn geen vragen beantwoord door deze inzender.</Text>}
    </Box>
  );
};
