import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CreatePanoramaSourceModel, PanoramaSourceModel } from "../api/ManagerApi";
import "./Pano.css";
import {
  CubeGeometry,
  CubeTile,
  EquirectGeometry,
  EquirectTile,
  ImageUrlSource,
  RectilinearView,
  Viewer,
} from "marzipano";
import { PanoramaType } from "../api/enums";
import { useForceUpdate } from "../utils/useForceUpdate";
import { Box } from "@chakra-ui/react";
import { Event } from "../marzipano";

interface PanoViewerProps {
  source?: PanoramaSourceModel | CreatePanoramaSourceModel;
}

export const SimplePanoViewer = ({ source }: PanoViewerProps) => {
  const panoElementRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState({ yaw: 0, pitch: 0 });
  const [token, updateView] = useForceUpdate("PanoViewer");

  const panoElement = panoElementRef.current;

  const marzipanoViewer = useMemo(() => {
    if (!panoElement) return;

    const viewer = new Viewer(panoElement, {
      stage: { progressive: true } as any,
      controls: {
        mouseViewMode: "drag",
      },
    });

    return viewer;
  }, [panoElement]);

  const levels = useMemo(() => {
    if (!source) return [];
    switch (source.panoType) {
      case PanoramaType.Cube:
        return source.levels.map((l) => ({ tileSize: source.levels[0], size: l }));
      case PanoramaType.Sphere:
        return source.levels.map((l) => ({ width: l }));
      default:
        return [];
    }
  }, [source]);

  const geometry = useMemo(() => {
    if (!source || !levels.length) return;
    switch (source.panoType) {
      case PanoramaType.Cube:
        return new CubeGeometry(levels as ConstructorParameters<typeof CubeGeometry>[0]);
      case PanoramaType.Sphere:
        return new EquirectGeometry(levels as ConstructorParameters<typeof EquirectGeometry>[0]);
      default:
        throw new Error("Invalid panorama type");
    }
  }, [source, levels]);

  const imageSource = useMemo(() => {
    if (!source) return;
    const imageUrlSource = new ImageUrlSource((tile) => {
      // @ts-ignore
      if (tile instanceof CubeGeometry.Tile && (tile as CubeTile).z > 0) {
        const cubeTile = tile as CubeTile;
        return {
          url: source.imageUrl
            .replace(/\{h\}/g, String(cubeTile.x + 1))
            .replace(/\{v\}/g, String(cubeTile.y + 1))
            .replace(/\{l\}/g, String(source.levels.length - cubeTile.z))
            .replace(/\{f\}/g, cubeTile.face),
        };
      }

      // @ts-ignore
      if (tile instanceof EquirectGeometry.Tile) {
        const equirectTile = tile as EquirectTile;
        return {
          url: source.imageUrl.replace(/\{l\}/g, String(equirectTile.z)),
        };
      }

      return { url: "" };
    });

    return imageUrlSource;
  }, [source]);

  const marzipanoView = useMemo(() => {
    if (!source?.levels.length) return;

    const initialView = {
      yaw: view.yaw,
      pitch: view.pitch,
      fov: (90 * Math.PI) / 180,
    };

    const limiter = RectilinearView.limit.traditional(source.levels.at(-1)!, (120 * Math.PI) / 180);

    return new RectilinearView(initialView, limiter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source?.levels]);

  const marzipanoScene = useMemo(() => {
    if (!marzipanoViewer || !imageSource || !geometry || !marzipanoView) return;

    const scene = marzipanoViewer.createScene({
      source: imageSource,
      geometry: geometry,
      view: marzipanoView,
      pinFirstLevel: true,
    });

    scene.addEventListener(Event.viewChange, () =>
      setView({ yaw: marzipanoView.yaw(), pitch: marzipanoView.pitch() })
    );

    return scene;
  }, [geometry, imageSource, marzipanoView, marzipanoViewer]);

  useEffect(() => {
    if (marzipanoScene) marzipanoScene.switchTo();
  }, [marzipanoScene]);

  const destructor = useCallback(() => {
    if (marzipanoScene && marzipanoViewer?.hasScene(marzipanoScene))
      marzipanoViewer.destroyScene(marzipanoScene);
  }, [marzipanoScene, marzipanoViewer]);

  useEffect(() => {
    if (panoElement) return;
    updateView();
  }, [token, updateView, panoElement]);

  useEffect(() => destructor, [destructor]);


  return (
    <Box h="full" position="relative" bg="blackAlpha.500" ref={panoElementRef} className="pano" />
  );
};
