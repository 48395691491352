import usePromise from "../utils/usePromise";

import { AnswerModel, QuestionnaireModel } from "../api/ManagerApi";
import { useSurveyState } from "../SurveyContext";
import { Box, Text } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { QuestionResult } from "./QuestionResult";
import { isQuestion } from "../utils/apiUtils";

export const ResultsSummary = () => {
  const { survey } = useOutletContext<{ survey: QuestionnaireModel }>();
  const { api } = useSurveyState();
  const questions = useMemo(() => survey.pages.flatMap((p) => p.elements).filter(isQuestion), [survey]);

  const [results] = usePromise(() => {
    const map = new Map<number, AnswerModel[]>();

    if (!survey) return Promise.resolve(map);

    return api.surveys.listAnswers(survey.id).then((r) => {
      const answerIds = new Set(r.data.map((a) => a.questionId));
      answerIds.forEach((a) => {
        map.set(
          a,
          r.data.filter((v) => v.questionId === a)
        );
      });
      return map;
    });
  }, [api.surveys]);

  const getQuestionName = useCallback(
    (questionId: number) => {
      const question = questions.find((q) => q.id === questionId);
      if (!question) return "";

      return (
        question.title ||
        `${question.type} ${
          questions.filter((q) => q.type === question.type).indexOf(question) + 1
        }`
      );
    },
    [questions]
  );

  const resultViews = useMemo(
    () => questions.map((q) => <QuestionResult name={getQuestionName(q.id)} key={q.id} entries={results?.get(q.id) ?? []} responses={survey.responses.length} />),
    [questions, results, getQuestionName, survey.responses.length]
  );

  if (!survey) return null;

  return (
    <Box maxW="container.xl" w="100%" px={4} mx="auto">
      {resultViews.length ? resultViews : <Text>Nog geen resultaten beschikbaar.</Text>}
    </Box>
  );
};
