import { Box } from "@chakra-ui/react";

interface BarProps {
  count: number;
  total: number;
}

export function Bar({ count, total }: BarProps) {
  return (
    <Box flexGrow={1}>
      <Box h={8} bg="blue.100" borderRadius={4} overflow={"hidden"}>
        <Box w={(count / total) * 100 + "%"} bg="blue.500" h="100%" />
      </Box>
    </Box>
  );
}
