import { Box, Text, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useMemo } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { ResultsPageOutletContext } from "../pages/Results";
import { format } from "date-fns";

export const ResponseList = () => {
  const { survey } = useOutletContext<ResultsPageOutletContext>();

  const responseLinks = useMemo(
    () =>
      survey.responses.map((respondent, index) => {
        return (
          <Tr key={respondent.id}>
            <Td>{index + 1}</Td>
            <Td>
              <div>{format(respondent.submissionDateTime, "dd-MM-yyyy HH:mm")}</div>
            </Td>
            <Td>
              <Link to={respondent.id.toString()}>Bekijk inzending</Link>
            </Td>
          </Tr>
        );
      }),
    [survey.responses]
  );

  return (
    <Box maxW="container.xl" w="100%" px={4} mx="auto">
      {responseLinks.length ? (
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Inzenddatum</Th>
              <Th>Acties</Th>
            </Tr>
          </Thead>
          <Tbody>{responseLinks}</Tbody>
        </Table>
      ) : (
        <Text>Nog geen resultaten beschikbaar.</Text>
      )}
    </Box>
  );
};
