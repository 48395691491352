import { ReactNode, useCallback, useMemo, useState } from "react";
import { ConfirmModal } from "../compositions/modals/ConfirmModal";
import {
  CheckboxGroup,
  Stack,
  Checkbox,
  Tag,
  TagLabel,
  TagCloseButton,
  ButtonProps,
} from "@chakra-ui/react";

interface MultiSelectProps<T extends string | number> {
  currentValue: T[];
  options: T[] | { value: T; label: string }[];
  onUpdate: (newValue: T[]) => void;
  modalTitle: ReactNode;
  shownOptions?: number;
  nullable?: boolean;
  elementOptions?: Omit<ButtonProps, "color" | "onClick" | "colorScheme">;
}
export const MultiSelect = function <T extends string | number>({
  currentValue,
  options,
  onUpdate,
  shownOptions = 2,
  modalTitle,
  nullable = false,
  elementOptions,
}: MultiSelectProps<T>) {
  const [value, setValue] = useState<T[]>(currentValue.slice());

  const type: "string" | "number" | null = (options as any[]).reduce<"string" | "number" | null>(
    (type, c) => {
      const v = typeof c === "object" ? c.value : c;
      if (type === null || c === undefined) return null;
      if (type === undefined && ["string", "number"].includes(typeof v)) return typeof v as any;
      if (type !== typeof v) return null;
      return type;
    },
    undefined as any
  );

  if (type === null) throw new Error("All options must be of the same type");

  const getLabel = useCallback(
    (v: T) => {
      if ((options as unknown[]).every((o) => typeof o === "object")) {
        return (
          (options as Exclude<typeof options, T[]>).find((o) => o.value === v)?.label ??
          v.toString()
        );
      }
      return v.toString();
    },
    [options]
  );

  const finalElementOptions = useMemo<
    Omit<ButtonProps, "color" | "onClick" | "colorScheme">
  >(() => {
    return {
      as: "p",
      cursor: "pointer",
      justifyContent: "flex-start",
      px: 1,
      variant: "ghost",
      w: "full",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "brand.gray.300",
      ...(elementOptions ?? {}),
    };
  }, [elementOptions]);

  return (
    <ConfirmModal
      titleNode={modalTitle}
      bodyNode={
        <CheckboxGroup
          value={value}
          onChange={(v: T[]) =>
            setValue(v.map((x) => (type === "string" ? (String(x) as T) : (Number(x) as T))))
          }
        >
          <Stack>
            {options.length > 1 ? (
              value.length === options.length ? (
                <Checkbox isChecked isDisabled={!nullable} onChange={() => setValue([])}>
                  Alles deselecteren
                </Checkbox>
              ) : (
                <Checkbox
                  isChecked={false}
                  onChange={() =>
                    setValue(
                      (options as any).map((o: any) => (typeof o === "object" ? o.value : o))
                    )
                  }
                >
                  Alles selecteren
                </Checkbox>
              )
            ) : null}
            {options.map((c) =>
              typeof c === "object" ? (
                <Checkbox key={c.value} value={c.value}>
                  {c.label}
                </Checkbox>
              ) : (
                <Checkbox key={c} value={c}>
                  {c}
                </Checkbox>
              )
            )}
          </Stack>
        </CheckboxGroup>
      }
      confirmNode="Opslaan"
      confirmNodeProps={{
        isDisabled: !nullable && !value.length
      }}
      primaryColor="blue"
      onCancel={() => setValue(currentValue.slice())}
      onConfirm={() => onUpdate(value)}
      buttonNode={
        <>
          {currentValue.slice(0, shownOptions).map((c) => (
            <Tag key={c} mr={2}>
              <TagLabel>{getLabel(c)}</TagLabel>
              {nullable ||
                (currentValue.length > 1 && (
                  <TagCloseButton
                    onClick={(e) => {
                      setValue((v) => {
                        const newValue = v.filter((cv) => cv !== c);
                        onUpdate(
                          newValue.map((x) =>
                            type === "string" ? (String(x) as T) : (Number(x) as T)
                          )
                        );
                        return newValue;
                      });
                      e.stopPropagation();
                    }}
                  />
                ))}
            </Tag>
          ))}
          {currentValue.length > shownOptions && (
            <Tag>
              <TagLabel title={currentValue.slice(shownOptions).map(o => `'${o}'`).join(", ")}>+{currentValue.length - shownOptions}</TagLabel>
            </Tag>
          )}
        </>
      }
      buttonNodeProps={finalElementOptions}
    />
  );
};
