import { Button, Flex, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { QuestionBox } from "../components/QuestionBox";
import { isElement } from "../utils/apiUtils";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";
import { useSurveyState } from "../SurveyContext";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ElementModel, UpdateElementModel } from "../api/ManagerApi";

type Props = {
  questionId: number;
};

export const ElementBlock = ({ questionId }: Props) => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const { api, updatePageToken, updateSurveyToken, pageToken } = useSurveyState();
  const [valid, setValid] = useState(true);
  const [, updateView] = useForceUpdate("View");

  useEffect(() => updateQuestionToken, [pageToken, updateQuestionToken]);

  const [question] = usePromise(() => {
    return api.surveys.getElement(Number(surveyId), questionId).then((r) => {
      if (isElement(r.data)) return r.data;
    });
  }, [questionRefreshToken]);

  const updateQuestion = useCallback(
    (questionModel: Partial<ElementModel>) => {
      if (!surveyId || !question || !surveyPageId) return;
      api.surveys
        .updateElement(Number(surveyId), question.id, {
          pageId: Number(surveyPageId),
          ...question,
          ...questionModel,
        } as UpdateElementModel)
        .then(updateSurveyToken)
        .then(() => setValid(false))
        .then(updateQuestionToken)
        .catch(() => {
          setValid(true);
          Object.assign(question, questionModel);
          updateView();
        });
    },
    [api.surveys, question, surveyId, surveyPageId, updateSurveyToken, updateQuestionToken, updateView]
  );

  const deleteQuestion = useCallback(() => {
    if (!surveyId || !question) return;
    api.surveys.deleteElement(parseInt(surveyId), question.id).then(updateSurveyToken).then(updatePageToken);
  }, [api.surveys, question, surveyId, updatePageToken, updateSurveyToken]);

  if (!question) return null;

  return (
    <QuestionBox questionType="Uitlegblok" questionTitle={question.title} valid={valid}>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Titel</Text>
        </FormLabel>
        <Input
          defaultValue={question.title}
          onBlur={(e) => updateQuestion({ title: e.currentTarget.value })}
        />
      </FormControl>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Omschrijving</Text>
        </FormLabel>
        <Input
          defaultValue={question.content}
          onBlur={(e) => updateQuestion({ content: e.currentTarget.value })}
        />
      </FormControl>
      <Flex>
        <Button ml="auto" mt={4} colorScheme="red" onClick={deleteQuestion}>
          Blok verwijderen
        </Button>
      </Flex>
    </QuestionBox>
  );
};
