export const distinct = <T, C extends (c: T) => any>(iterable: Iterable<T>, by: C): Array<T> => {
  const seen = new Set();

  return Array.from(iterable).reduce((acc, item) => {
    const value = by(item);
    if (!seen.has(value)) {
      acc.push(item);
    }
    seen.add(value);
    return acc;
  }, [] as T[]);
};

export const sequenceEqual = <T>(
  a: Iterable<T>,
  b: Iterable<T>,
  comparer: (a: T, b: T) => boolean = (a, b) => a === b
): boolean => {
  const [arrA, arrB] = [Array.from(a), Array.from(b)];

  return arrA.length === arrB.length && arrA.every((v, i) => comparer(v, arrB[i]));
};

export const truthy = <T extends {}>(v: T | null | undefined): v is T => Boolean(v);
