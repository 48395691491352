import { HeatmapLayer, MapRef, Marker } from "react-map-gl";
import "./MapResult.css";

import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import MapGL, { Source, Layer, Popup } from "react-map-gl";
import { LocationPickerAnswerModel } from "../api/ManagerApi";
import { useSurveyState } from "../SurveyContext";
import { useOutletContext } from "react-router-dom";
import usePromise from "../utils/usePromise";
import { DefaultMarker } from "./DefaultMarker";
import styled from "@emotion/styled";
import { Box, Button, Flex } from "@chakra-ui/react";
import {
  assertPolymorhpicType,
  isGeoLocation,
  isLayerSource,
  isLocationPickerQuestion,
} from "../utils/apiUtils";
import mapboxgl from "mapbox-gl";
import { RequestedCommand, useFrameContext } from "../FrameContext";
import { WordpressContentBase } from "../api/WordpressApi";
import { distinct, truthy } from "../utils/iterableUtils";
import { ResultsPageOutletContext } from "../pages/Results";
import { useForceUpdate } from "../utils/useForceUpdate";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

type Props = {
  entries: LocationPickerAnswerModel[];
  heatmap?: boolean;
};

export default function MapResult({ entries, heatmap = false }: Props) {
  const { wordpressApi } = useSurveyState();
  const { request } = useFrameContext();
  const { survey } = useOutletContext<ResultsPageOutletContext>();
  const [heatmapJson, setHeatmapJson] = useState<any | null>(null);
  const [isHeatmap, setIsHeatmap] = useState(false);
  const [filter, setFilter] = useState<number | null>(null);
  const [selectedScenario, setSelectedScenario] = useState(0);
  const [popupInfo, setPopupInfo] = useState<{
    lat: number;
    lon: number;
    description: string;
  } | null>(null);

  const mapRef = useRef<MapRef>(null!);
  const [map, setMap] = useState(() => mapRef.current?.getMap());
  const [token, updateToken] = useForceUpdate("MapResult");

  const surveyPage = useMemo(
    () => survey.pages.find((p) => p.elements.some((e) => e.id === entries[0].questionId)),
    [survey, entries]
  );

  const layerSource = useMemo(() => {
    const source = survey.dataSources.find((d) => d.id === surveyPage?.dataSourceId);
    assertPolymorhpicType(isLayerSource, source);
    return source;
  }, [survey, surveyPage]);

  const [scenarios] = usePromise(async () => {
    if (request) {
      const response = await request(RequestedCommand.Get, {
        from: "scenarios",
      });

      return response?.data as WordpressContentBase[];
    } else {
      return wordpressApi.scenarios.list().then((x) => x.data);
    }
  }, [request, wordpressApi]);

  const onSelectAnswer = useCallback((longitude: number, latitude: number) => {
    mapRef.current?.flyTo({ center: [longitude, latitude], duration: 1000 });
  }, []);

  useEffect(() => {
    setPopupInfo(null);
  }, [isHeatmap, filter]);

  const question = useMemo(
    () =>
      surveyPage?.elements
        .filter(isLocationPickerQuestion)
        .find((e) => e.id === entries[0].questionId),
    [surveyPage, entries]
  );

  const filters = useMemo(
    () =>
      question?.pins.map((p) => {
        return { pinId: p.id, label: p.label };
      }) ?? [],
    [question]
  );

  const locations = useMemo(() => entries.flatMap((e) => e.pins.filter(isGeoLocation)), [entries]);

  const availableScenarios = useMemo(
    () =>
      scenarios
        ? distinct(locations, (p) => p.scenarioId)
            .map((p) => scenarios.find((s) => s.id === p.scenarioId))
            .filter(truthy)
        : [],
    [locations, scenarios]
  );

  useEffect(
    () =>
      setSelectedScenario((current) =>
        availableScenarios.some((p) => p.id === current)
          ? current
          : availableScenarios.length === 1
          ? availableScenarios[0].id
          : 0
      ),
    [availableScenarios]
  );

  useEffect(() => {
    if (!heatmap) return;

    const geojson = {
      name: "NewFeatureType",
      type: "FeatureCollection",
      features: locations
        .filter((l) => filter === null || filter === l.pinDefinition)
        .filter((l) => !selectedScenario || l.scenarioId === selectedScenario)
        .map((c, i) => {
          return {
            type: "Feature",
            geometry: { type: "Point", coordinates: [c.longitude, c.latitude] },
            properties: {
              index: i,
            },
          };
        }),
    };
    setHeatmapJson(geojson);
  }, [locations, filter, selectedScenario, heatmap]);

  const getColor = useCallback(
    (pinId: number) => question?.pins.find((p) => p.id === pinId)?.color ?? "#1F3BE6",
    [question]
  );

  useEffect(() => {
    if (!map) {
      if (mapRef.current) {
        const newMap = mapRef.current.getMap();
        if (newMap) return setMap(newMap);
      }
    }
    updateToken();
  }, [map, token, updateToken]);

  useEffect(() => {
    if (map?.isStyleLoaded()) {
      map
        .getStyle()
        .layers.filter(
          (l: any) =>
            l.type !== "background" &&
            !l.source?.startsWith("mapbox://") &&
            !l.metadata?.hasOwnProperty("mapbox:featureComponent")
        )
        .forEach((layer) => {
          map.setLayoutProperty(
            layer.id,
            "visibility",
            layerSource.clusters.includes(layer.id) || layer.id === "heatmap" ? "visible" : "none"
          );
        });
    }
  }, [layerSource, token, map]);

  return (
    <>
      <Flex columnGap={12} rowGap={4} wrap="wrap">
        {heatmap && (
          <Box>
            Toon als:
            <Flex gap={4}>
              <Button onClick={() => setIsHeatmap(true)} bg={isHeatmap ? "blue.800" : "blue.500"}>
                Heatmap
              </Button>
              <Button onClick={() => setIsHeatmap(false)} bg={!isHeatmap ? "blue.800" : "blue.500"}>
                Pins
              </Button>
            </Flex>
          </Box>
        )}
        {filters.length > 0 && (
          <Box>
            Filters:
            <Flex gap={4}>
              <Button
                onClick={() => setFilter(null)}
                bg={filter === null ? "blue.800" : "blue.500"}
              >
                Toon alles
              </Button>

              {filters.map((f) => {
                return (
                  <Button
                    onClick={() => setFilter(f.pinId)}
                    bg={filter === f.pinId ? "blue.800" : "blue.500"}
                    key={f.pinId}
                  >
                    {f.label}
                  </Button>
                );
              })}
            </Flex>
          </Box>
        )}
        {availableScenarios.length > 1 && (
          <Box>
            Scenario's:
            <Flex gap={4}>
              <Button
                onClick={() => setSelectedScenario(0)}
                bg={!selectedScenario ? "blue.800" : "blue.500"}
              >
                Alle scenario's
              </Button>
              {availableScenarios.map((p) => (
                <Button
                  key={p.id}
                  onClick={() => setSelectedScenario(p.id)}
                  bg={selectedScenario === p.id ? "blue.800" : "blue.500"}
                >
                  {p.title.rendered}
                </Button>
              ))}
            </Flex>
          </Box>
        )}
      </Flex>
      <Box display="flex" gap="1rem" my="1rem">
        {layerSource && (
          <MapGL
            initialViewState={{
              latitude: locations[0].latitude ?? 53,
              longitude: locations[0].longitude ?? 5,
              zoom: 10,
            }}
            key={layerSource.accessToken}
            mapStyle={"mapbox://styles/" + layerSource.styleId}
            mapboxAccessToken={layerSource.accessToken}
            ref={mapRef}
            style={{
              flex: 3,
              height: "500px",
            }}
          >
            {heatmapJson && isHeatmap && (
              <Source type="geojson" data={heatmapJson}>
                <Layer {...heatmapLayer} />
              </Source>
            )}
            {locations &&
              !isHeatmap &&
              locations
                .filter((l) => filter === null || filter === l.pinDefinition)
                .filter((l) => !selectedScenario || l.scenarioId === selectedScenario)
                .map((c, i) => {
                  return (
                    <Marker
                      latitude={c.latitude}
                      longitude={c.longitude}
                      offset={[7, -12]}
                      key={i}
                      onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setPopupInfo({
                          lat: c.latitude,
                          lon: c.longitude,
                          description: c.comment.length > 0 ? c.comment : "Geen opmerking",
                        });
                      }}
                    >
                      <MarkerIconStyles>
                        <DefaultMarker
                          selected={popupInfo?.lat === c.latitude && popupInfo.lon === c.longitude}
                          color={getColor(c.pinDefinition)}
                        />
                      </MarkerIconStyles>
                    </Marker>
                  );
                })}
            {popupInfo && (
              <Popup
                latitude={popupInfo.lat}
                longitude={popupInfo.lon}
                anchor="top"
                onClose={() => setPopupInfo(null)}
              >
                {popupInfo.description}
              </Popup>
            )}
          </MapGL>
        )}
        <ListContainer>
          {locations
            .filter((l) => l.comment.length > 0)
            .filter((l) => filter === null || filter === l.pinDefinition)
            .map((l, i) => {
              return (
                <ListItem
                  key={i}
                  onClick={() => {
                    setPopupInfo({
                      lat: l.latitude,
                      lon: l.longitude,
                      description: l.comment,
                    });
                    onSelectAnswer(l.longitude, l.latitude);
                  }}
                >
                  {l.comment}
                </ListItem>
              );
            })}
        </ListContainer>
      </Box>
    </>
  );
}

const MAX_ZOOM_LEVEL = 18;

const heatmapLayer: HeatmapLayer = {
  id: "heatmap",
  maxzoom: MAX_ZOOM_LEVEL,
  type: "heatmap",
  paint: {
    "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, MAX_ZOOM_LEVEL, 2],
    "heatmap-weight": ["interpolate", ["linear"], ["zoom"], 0, 0.5, MAX_ZOOM_LEVEL, 0.5],
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.2,
      "rgb(103,169,207)",
      0.4,
      "rgb(209,229,240)",
      0.6,
      "rgb(253,219,199)",
      0.8,
      "rgb(239,138,98)",
      0.9,
      "rgb(255,201,101)",
    ],
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, MAX_ZOOM_LEVEL, 20],
  },
};

export const MarkerIconStyles = styled.div`
  width: auto;
  height: auto;
  pointer-events: none;
  display: inline-block;

  > svg {
    width: 44px;
    height: 44px;

    > path {
      cursor: pointer;
      pointer-events: auto;
      fill: red;
    }
  }
`;

const ListContainer = styled.div`
  max-height: 500px;
  flex: 1;
  overflow-y: auto;
`;

const ListItem = styled.div`
  padding: 1rem 0.5rem;
  border-bottom: 1px solid var(--color-neutral-30);
  cursor: pointer;
`;
