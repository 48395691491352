import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useSurveyState } from "./SurveyContext";

enum ReceivedCommand {
  Init = "init",
}

export enum RequestedCommand {
  Get = "get",
  Authenticate = "authenticate",
}

interface IParentFrameContext {
  origin?: string;
  isProxy: boolean;
  request?: IFrameRequest<RequestedCommand>;
  respond: (event: MessageEvent<IMessage<ReceivedCommand>>) => void;
  authenticate: () => Promise<void>;
}

const requestTransactions = new Set<string>();
const finishedTransactions = new Set<string>();

const FrameContext = createContext<IParentFrameContext>({
  isProxy: false,
  request: undefined,
  respond: () => undefined,
  authenticate: () => Promise.resolve(),
});

export const FrameContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [port, setPort] = useState<MessagePort>();
  const [origin, setOrigin] = useState<string>();
  const [isProxy, setIsProxy] = useState(false);
  const { login } = useSurveyState();

  const verifyOrigin = useCallback(async (origin: string): Promise<boolean> => {
    // TODO: verify origin is trusted with API
    return Promise.resolve(true);
  }, []);

  useEffect(() => {
    if (!port && window.parent !== window) {
      if (window.parent !== window.top)
        throw new Error("[SecurityError]: Parent frame is not top frame");
      const initListener = (event: MessageEvent<IMessage<ReceivedCommand>>) => {
        const message = event.data;
        if (message.command === ReceivedCommand.Init) {
          window.removeEventListener("message", initListener);
          if (event.ports?.[0]) {
            const origin = message.data?.proxy ?? event.origin;

            verifyOrigin(origin).then((verified) => {
              if (verified) {
                console.info(
                  `Received connection attempt from ${event.origin}, with SEP-host ${origin}.`
                );
                event.ports[0].postMessage({
                  transaction: event.data.transaction,
                  command: ReceivedCommand.Init,
                });
                if (origin !== event.origin) setIsProxy(true);
                setOrigin(origin);
                setPort(event.ports[0]);
              } else {
                throw new Error("[SecurityError]: Origin is not trusted");
              }
            });
          }
        }
      };
      window.addEventListener("message", initListener);
      return () => window.removeEventListener("message", initListener);
    }
  }, [port, verifyOrigin]);

  const request: IFrameRequest<RequestedCommand> = useCallback((command, data) => {
      if (!port) return Promise.resolve(undefined);

      const message: IMessage<RequestedCommand> = {
        transaction: crypto.randomUUID(),
        command,
        data,
      };

      requestTransactions.add(message.transaction);

      return new Promise((resolve, reject) => {
        const resolver = (event: MessageEvent<IMessage<RequestedCommand>>) => {
          const response = event.data;
          if (response.transaction !== message.transaction || !requestTransactions.has(message.transaction)) return;

          console.log(`Received response for message ${message.transaction} [${message.command}]`)

          requestTransactions.delete(message.transaction);
          finishedTransactions.add(message.transaction);

          port.removeEventListener("message", resolver);
          port.removeEventListener("messageerror", resolver);

          switch (event.type) {
            case "message":
              return resolve(response);
            case "messageerror":
              return reject(response);
          }
        };

        port.addEventListener("message", resolver);
        port.addEventListener("messageerror", resolver);

        port.postMessage(message);
      });
    },
    [port]
  );

  const respond = useCallback(
    (event: MessageEvent<IMessage<ReceivedCommand>>) => {
      if (!port) return;

      const message = event.data;

      // Ignore messages that are a response for a request we made
      if (requestTransactions.has(message.transaction) || finishedTransactions.has(message.transaction)) return;

      const response: IMessage<ReceivedCommand> = {
        transaction: message.transaction,
        command: message.command,
      };

      // TODO: Handle commands

      port.postMessage(response);
    },
    [port]
  );

  useEffect(() => {
    if (port) {
      port.addEventListener("message", respond);
      port.start();

      return () => {
        port.close();
        port.removeEventListener("message", respond);
      }
    }
  }, [port, respond]);

  const authenticate = useCallback(async () => {
    if (!port) return Promise.reject();
    const user = await request(RequestedCommand.Authenticate);
    return user?.data?.key ? Promise.resolve(login(user.data.key)) : Promise.reject();
  }, [request, port, login]);

  return window !== window.parent ? (
    <FrameContext.Provider value={{ request, respond, origin, isProxy, authenticate }}>{children}</FrameContext.Provider>
  ) : (
    <>{children}</>
  );
};

export const useFrameContext = () => React.useContext(FrameContext);

export interface IMessage<T extends ReceivedCommand | RequestedCommand> {
  transaction: string;
  command: T;
  data?: any;
}

export interface IFrameRequest<T extends RequestedCommand> {
  (command: T, data?: any): Promise<IMessage<T> | undefined>;
}
