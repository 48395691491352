import { BoundaryModel, HttpClient, RequestParams } from "./ManagerApi";

export class WordpressApi extends HttpClient {
  config = (params: RequestParams = {}) =>
    this.request<WordpressConfig>({
      path: `/acf/v3/options/options`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  panos = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressPano[], any>({
        path: `/wp/v2/pano`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressPano>({
        path: `/wp/v2/pano/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  layerGroups = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressLayergroup[], any>({
        path: `/wp/v2/layergroups`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressLayergroup>({
        path: `/wp/v2/layergroups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scenarios = {
    list: (query = {}, params: RequestParams = {}) =>
      this.request<WordpressContentBase[], any>({
        path: `/wp/v2/scenario`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.request<WordpressContentBase>({
        path: `/wp/v2/scenario/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}

export interface WordpressBase {
  date: string;
  modified: string;
  slug: string;
  link: string;
  id: number;
  status: "publish" | "draft" | "future" | "pending" | "private";
}

export interface WordpressContentBase extends WordpressBase {
  title: {
    rendered: string;
  };
}

export interface WordpressPano extends WordpressContentBase {
  acf: {
    scenarios: {
      resource: string[];
      scenario: number;
    }[];
  };
}

export interface WordpressConfig {
  acf: {
    panorama_folder: string;
    mapbox_access_token: string;
    mapbox_style_url: string;
    default_scenario: number;
    initial_bounding_box: BoundaryModel;
  };
}

export interface WordpressLayergroup extends WordpressContentBase {
  acf: {
    mapbox_layer_groups: {
      mapbox_layer_group: string[];
      scenario: number[];
    }[];
  };
}
