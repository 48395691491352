import { MinusIcon } from "@chakra-ui/icons";

import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { QuestionBox } from "../components/QuestionBox";
import { isChoiceQuestion } from "../utils/apiUtils";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";
import { useSurveyState } from "../SurveyContext";
import { useParams } from "react-router-dom";
import { ChoiceQuestionModel, UpdateChoiceQuestionModel } from "../api/ManagerApi";
import { useCallback, useEffect, useState } from "react";

type Props = {
  questionId: number;
};

export const MultipleChoiceQuestion = ({ questionId }: Props) => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const { api, updatePageToken, updateSurveyToken, pageToken } = useSurveyState();
  const [valid, setValid] = useState(true);
  const [, updateView] = useForceUpdate("View");

  useEffect(() => updateQuestionToken(), [pageToken, updateQuestionToken])

  const [question] = usePromise(() => {
    return api.surveys.getElement(Number(surveyId), questionId).then((r) => {
      if (isChoiceQuestion(r.data)) return r.data;
    });
  }, [questionRefreshToken]);

  const updateQuestion = useCallback(
    (questionModel: Partial<ChoiceQuestionModel>) => {
      if (!surveyId || !question || !surveyPageId) return;
      api.surveys
        .updateElement(Number(surveyId), question.id, {
          pageId: Number(surveyPageId),
          ...question,
          ...questionModel,
        } as UpdateChoiceQuestionModel)
        .then(updateSurveyToken)
        .then(() => setValid(true))
        .then(updateQuestionToken)
        .catch(() => {
          setValid(false);
          Object.assign(question, questionModel);
          updateView();
        });
    },
    [
      api.surveys,
      question,
      surveyId,
      surveyPageId,
      updateSurveyToken,
      updateQuestionToken,
      updateView,
    ]
  );

  const updateOption = useCallback(
    (opt: { value: string; label: string }) => {
      if (!question) return;
      const options = question.options;
      const option = options.find((o) => o.value === opt.value);
      if (!option) return;
      option.label = opt.label;
      updateQuestion({ options });
    },
    [question, updateQuestion]
  );

  const addOption = useCallback(() => {
    if (!question) return;
    const options = question.options;
    options.push({ value: crypto.randomUUID(), label: undefined });
    updateQuestion({ options });
  }, [question, updateQuestion]);

  const deleteQuestion = useCallback(() => {
    if (!surveyId || !question) return;
    api.surveys
      .deleteElement(parseInt(surveyId), question.id)
      .then(updateSurveyToken)
      .then(updatePageToken);
  }, [api.surveys, question, surveyId, updatePageToken, updateSurveyToken]);

  if (!question) return null;

  return (
    <QuestionBox questionType="Meerkeuzevraag" questionTitle={question?.title} valid={valid}>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Vraag</Text>
        </FormLabel>
        <Input
          defaultValue={question.title}
          onBlur={(e) => updateQuestion({ title: e.currentTarget.value })}
        />
      </FormControl>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Omschrijving</Text>
        </FormLabel>
        <Input
          defaultValue={question.content}
          onBlur={(e) => updateQuestion({ content: e.currentTarget.value })}
        />
      </FormControl>
      <Flex gap={4}>
        <Checkbox
          mt={4}
          defaultChecked={question.required}
          onChange={(e) =>
            updateQuestion({
              required: e.target.checked,
            })
          }
        >
          Verplicht
        </Checkbox>
        <Checkbox
          onChange={(e) => updateQuestion({ allowMultiple: e.currentTarget.checked })}
          mt={4}
          defaultChecked={question.allowMultiple}
        >
          Meerdere antwoorden toestaan
        </Checkbox>
      </Flex>
      <Flex gap={4} mt={4} flexDir={"column"}>
        {question.options.map((option) => (
          <Box display={"flex"} gap={2} key={option.value}>
            <Input
              defaultValue={option.label ?? ""}
              onBlur={(e) => updateOption({ value: option.value, label: e.currentTarget.value })}
            />
            {question.options.length > 2 && (
              <Button
                colorScheme={"red"}
                onClick={() =>
                  updateQuestion({
                    options: question.options.filter((o) => o.value !== option.value),
                  })
                }
              >
                <MinusIcon boxSize={3} />
              </Button>
            )}
          </Box>
        ))}
      </Flex>
      <Flex>
        <Button mt={4} onClick={addOption}>
          Optie toevoegen
        </Button>
        <Button ml="auto" mt={4} colorScheme="red" onClick={deleteQuestion}>
          Vraag verwijderen
        </Button>
      </Flex>
    </QuestionBox>
  );
};
