import { Box, Flex, Heading, Image, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useSurveyState } from "../SurveyContext";
import usePromise from "../utils/usePromise";
import {
  EditIcon,
  DeleteIcon,
  RepeatIcon,
  ArrowLeftIcon,
  LockIcon,
  StarIcon,
  CopyIcon,
} from "@chakra-ui/icons";
import { LoginModal } from "../compositions/modals/LoginModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiKeyListItemModel, UpdateApiKeyModel } from "../api/ManagerApi";
import { useForceUpdate } from "../utils/useForceUpdate";
import { ConfirmModal } from "../compositions/modals/ConfirmModal";
import { Link, useNavigate } from "react-router-dom";
import { getPublicPermissions, getPrivatePermissions } from "../utils/permissionUtils";
import { AddApiKeyModal } from "../compositions/modals/AddApiKeyModal";
import { UpdateApiKeyModal } from "../compositions/modals/UpdateApiKeyModal";

const matchKey = (key: string, apiKey: ApiKeyListItemModel) =>
  key.split(".")[1] === apiKey.publicKey.replace(/-/g, "");

export const ManageApiKeysPage = () => {
  const { api, regeneratedApiKeys, login } = useSurveyState();
  const navigate = useNavigate();
  const [interfaceRefreshToken, updateInterfaceToken] = useForceUpdate("ManageApiKeysPage");
  const [selectedApiKeyForUpdate, setSelectedApiKeyForUpdate] = useState<
    ApiKeyListItemModel | undefined
  >(undefined);
  const [selectedApiKeyForRegenerate, setSelectedApiKeyForRegenerate] = useState<
    ApiKeyListItemModel | undefined
  >(undefined);
  const [selectedApiKeyForDelete, setSelectedApiKeyForDelete] = useState<
    ApiKeyListItemModel | undefined
  >(undefined);

  const [keys] = usePromise(async () => {
    const { data } = await api.apikeys.listApiKeys();

    return { publicKeys: data.filter((k) => k.public), privateKeys: data.filter((k) => !k.public) };
  }, [api, interfaceRefreshToken]);

  const currentApiKey = useMemo(
    () =>
      keys &&
      [...keys.publicKeys, ...keys.privateKeys].find((k) => matchKey(localStorage.apiKey, k)),
    [keys]
  );

  const addApiKey = useCallback(
    async (isPublic: boolean, displayName: string) => {
      const key = await api.apikeys.createApiKey({
        public: isPublic,
        displayName,
        permissions: isPublic ? getPublicPermissions() : getPrivatePermissions(),
      });

      regeneratedApiKeys.set(key.data.publicKey, key.data.privateKey);

      updateInterfaceToken();
    },
    [updateInterfaceToken, regeneratedApiKeys, api.apikeys]
  );

  const updateApiKey = useCallback(
    async (updateModel: UpdateApiKeyModel) => {
      if (!selectedApiKeyForUpdate) return;

      await api.apikeys.updateApiKey(selectedApiKeyForUpdate.publicKey, updateModel);

      setSelectedApiKeyForUpdate(undefined);

      updateInterfaceToken();
    },
    [updateInterfaceToken, api.apikeys, selectedApiKeyForUpdate]
  );

  const deleteApiKey = useCallback(async () => {
    if (!selectedApiKeyForDelete) return;

    if (!selectedApiKeyForDelete.isRootKey && selectedApiKeyForDelete !== currentApiKey)
      await api.apikeys.deleteApiKey(selectedApiKeyForDelete!.publicKey);

    if (regeneratedApiKeys.has(selectedApiKeyForDelete.publicKey))
      regeneratedApiKeys.delete(selectedApiKeyForDelete.publicKey);

    setSelectedApiKeyForDelete(undefined);

    updateInterfaceToken();
  }, [updateInterfaceToken, api.apikeys, selectedApiKeyForDelete, regeneratedApiKeys, currentApiKey]);

  const regenerateApiKey = useCallback(async () => {
    if (!selectedApiKeyForRegenerate) return;

    const newKey = await api.apikeys.regenerateApiKey(selectedApiKeyForRegenerate!.publicKey);

    if (regeneratedApiKeys.has(selectedApiKeyForRegenerate.publicKey))
      regeneratedApiKeys.delete(selectedApiKeyForRegenerate.publicKey);

    regeneratedApiKeys.set(newKey.data.publicKey, newKey.data.privateKey);

    if (selectedApiKeyForRegenerate === currentApiKey) {
      login(newKey.data.privateKey);
    }

    setSelectedApiKeyForRegenerate(undefined);

    updateInterfaceToken();
  }, [
    updateInterfaceToken,
    api.apikeys,
    selectedApiKeyForRegenerate,
    login,
    currentApiKey,
    regeneratedApiKeys,
  ]);

  const cancelRegenerate = () => setSelectedApiKeyForRegenerate(undefined);
  const cancelUpdate = () => setSelectedApiKeyForUpdate(undefined);
  const cancelDelete = () => setSelectedApiKeyForDelete(undefined);

  return (
    <Box>
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        mb={8}
        py={2}
        bg="blue.500"
        color="white"
        gap={4}
      >
        <Image
          onClick={() => navigate("/")}
          src="tim-logo.svg"
          w="40px"
          h="40px"
          filter="invert(1)"
          ml={8}
        />
        <Heading onClick={() => navigate("/")} size="md">
          The Imagineers enquête beheeromgeving
        </Heading>
        {window === window.parent && <LoginModal />}
      </Flex>
      <Flex
        maxW="container.xl"
        w="100%"
        mx="auto"
        gap={16}
        my={4}
        textAlign="center"
        color="gray.600"
      >
        <Link to="/">
          <ArrowLeftIcon /> Terug naar enquêtes
        </Link>
      </Flex>
      <Flex w="100%" justifyContent="center" gap={16} my={4} textAlign="center" color="gray.600">
        <Box px={8} py={4}>
          <Heading as="p">Beheer API keys</Heading>
        </Box>
      </Flex>
      <Box maxW="container.xl" w="100%" px={4} mx="auto">
        <AddApiKeyModal onAdd={addApiKey} />
        <ConfirmModal
          onConfirm={regenerateApiKey}
          onCancel={cancelRegenerate}
          titleNode="API Key vernieuwen"
          bodyNode="Wil je deze API key vernieuwen? Dit kan niet ongedaan worden gemaakt, bestaande verbindingen met deze key zullen worden verbroken."
          buttonNode={null}
          primaryColor="blue"
          open={!!selectedApiKeyForRegenerate}
        />
        <UpdateApiKeyModal
          publicKey={selectedApiKeyForUpdate?.publicKey}
          onUpdate={updateApiKey}
          onCancel={cancelUpdate}
        />
        <ConfirmModal
          onConfirm={deleteApiKey}
          onCancel={cancelDelete}
          titleNode="API Key verwijderen"
          bodyNode="Wil je deze API key en de bijbehorende rechten verwijderen? Dit kan niet ongedaan worden gemaakt."
          buttonNode={null}
          primaryColor="red"
          open={
            !!selectedApiKeyForDelete &&
            !selectedApiKeyForDelete.isRootKey &&
            selectedApiKeyForDelete !== currentApiKey
          }
        />
        {keys?.publicKeys?.length ? (
          <Box my={4}>
            <Heading as="h2" size="md" mb={4}>
              Publieke keys
            </Heading>
            <Text mb={4}>
              Publieke keys worden gebruikt voor het inzenden van resultaten.
              <br />
              Na het vernieuwen van een key wordt de oude key onbruikbaar en wordt de nieuwe key
              eenmalig in onderstaande lijst getoond.
            </Text>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Naam</Th>
                  <Th>Api Key</Th>
                  <Th>Acties</Th>
                </Tr>
              </Thead>
              <Tbody>
                {keys.publicKeys.map((key) => (
                  <Tr key={key.publicKey}>
                    <Td>{key.displayName || key.publicKey}</Td>
                    <Td>
                      <ApiKey apiKey={regeneratedApiKeys.get(key.publicKey)} />
                    </Td>
                    <Td>
                      <Flex gap={4}>
                        <Text
                          title="Opnieuw genereren"
                          color="blue.500"
                          cursor="pointer"
                          onClick={() => setSelectedApiKeyForRegenerate(key)}
                        >
                          <RepeatIcon />
                        </Text>
                        <Text
                          title="Bewerken"
                          color="blue.500"
                          cursor="pointer"
                          onClick={() => setSelectedApiKeyForUpdate(key)}
                        >
                          <EditIcon />
                        </Text>
                        <Text
                          title="verwijderen"
                          color="red.500"
                          cursor="pointer"
                          onClick={() => setSelectedApiKeyForDelete(key)}
                        >
                          <DeleteIcon />
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : null}
        {keys?.privateKeys?.length ? (
          <Box my={4}>
            <Heading as="h2" size="md" mb={4}>
              Private keys
            </Heading>
            <Text mb={4}>
              Private keys worden gebruikt voor het beheren van je participatieprojecten in de
              Participatie API.
              <br />
              Na het vernieuwen van een key wordt de oude key onbruikbaar en wordt de nieuwe key
              eenmalig in onderstaande lijst getoond.
            </Text>
            <Text mb={4}>
              <b>Deze keys niet delen met anderen!</b>
            </Text>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Naam</Th>
                  <Th>Api Key</Th>
                  <Th>Acties</Th>
                </Tr>
              </Thead>
              <Tbody>
                {keys.privateKeys.map((key) => (
                  <Tr key={key.publicKey}>
                    <Td>
                      {key.isRootKey && (
                        <Text display="inline" title="Hoofdsleutel">
                          <StarIcon />{" "}
                        </Text>
                      )}
                      {key.displayName || key.publicKey}
                    </Td>
                    <Td>
                      <ApiKey apiKey={regeneratedApiKeys.get(key.publicKey)} />
                    </Td>
                    <Td>
                      <Flex gap={4}>
                        <Text
                          title="Opnieuw genereren"
                          color="blue.500"
                          cursor="pointer"
                          onClick={() => setSelectedApiKeyForRegenerate(key)}
                        >
                          <RepeatIcon />
                        </Text>
                        {!key.isRootKey && key !== currentApiKey && (
                          <>
                            <Text
                              title="Bewerken"
                              color="blue.500"
                              cursor="pointer"
                              onClick={() => setSelectedApiKeyForUpdate(key)}
                            >
                              <EditIcon />
                            </Text>
                            <Text
                              title="verwijderen"
                              color="red.500"
                              cursor="pointer"
                              onClick={() => setSelectedApiKeyForDelete(key)}
                            >
                              <DeleteIcon />
                            </Text>
                          </>
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const InaccessibleApiKey = () => (
  <Text>
    <LockIcon /> Om veiligheidsredenen is deze key niet meer zichtbaar
  </Text>
);

const ApiKey = ({ apiKey }: { apiKey?: string }) => {
  const [success, setSuccess] = useState<boolean>();

  useEffect(() => {
    if (success === undefined) return;

    const timeout = setTimeout(() => setSuccess(undefined), 5000);
    return () => clearTimeout(timeout);
  }, [success]);

  const copy = useCallback(() => {
    if (!apiKey) return;
    navigator.clipboard.writeText(apiKey).then(
      () => setSuccess(true),
      () => setSuccess(false)
    );
  }, [apiKey]);

  return apiKey ? (
    <Text>
      {apiKey}{" "}
      <Text display="inline" title="Kopiëren" onClick={copy} color="blue.500" cursor="pointer">
        <CopyIcon />
      </Text>
      {success !== undefined &&
        (success ? (
          <Text as="span" color="green.500" ml={2}>
            Gekopieerd naar klembord!
          </Text>
        ) : (
          <Text as="span" color="red.500" ml={2}>
            Kopiëren mislukt, selecteer de tekst en kopieer handmatig.
          </Text>
        ))}
    </Text>
  ) : (
    <InaccessibleApiKey />
  );
};
