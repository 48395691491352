import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  questionType?: string;
  questionTitle?: string;
  valid: boolean;
}>;

export const QuestionBox = ({ questionType, questionTitle, children, valid }: Props) => {
  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Flex alignItems="center">
              <Text mr={2}>{questionTitle || "(geen titel)"}</Text>
              {valid ? (
                <Box title="Up to date"><CheckCircleIcon color="green.500" /></Box>
              ) : (
                <Box title="Er was een probleem met het opslaan"><WarningTwoIcon color="yellow.500" /></Box>
              )}
            </Flex>
            <Text size="xs" color="gray.300">
              {questionType}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
