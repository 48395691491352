import { Flex, Input, InputProps, Tag } from "@chakra-ui/react";
import { forwardRef, useCallback } from "react";

interface Props extends InputProps {
  prefix?: string;
  usePrefixInValue?: boolean;
  suffix?: string;
  useSuffixInValue?: boolean;
  value?: string;
  trimValue?: boolean | "start" | "end";
  onValueChange: (value: string) => void;
}

export const ComposedTextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      prefix = "",
      usePrefixInValue = false,
      suffix = "",
      useSuffixInValue = false,
      value,
      trimValue = false,
      onValueChange,
      ...inputProps
    },
    ref
  ) => {
    const displayValue = useCallback(
      (value: string) => {
        if (trimValue) {
          if (trimValue === "start") value = value.trimStart();
          if (trimValue === "end") value = value.trimEnd();
          if (trimValue === true) value = value.trim();
        }
        if (prefix && value.startsWith(prefix)) value = value.substring(prefix.length);
        if (suffix && value.endsWith(suffix))
          value = value.substring(0, value.length - suffix.length);
        return value;
      },
      [prefix, suffix, trimValue]
    );

    return (
      <Flex>
        {!!prefix && (
          <Tag borderTopRightRadius={0} borderBottomRightRadius={0} px={3} flexShrink={0}>
            {prefix}
          </Tag>
        )}
        <Input
          {...inputProps}
          ref={ref}
          borderTopLeftRadius={prefix ? 0 : 4}
          borderBottomLeftRadius={prefix ? 0 : 4}
          borderTopRightRadius={suffix ? 0 : 4}
          borderBottomRightRadius={suffix ? 0 : 4}
          value={value && displayValue(value)}
          onChange={(e) => {
            let emittedValue = displayValue(e.currentTarget.value);
            if (usePrefixInValue) emittedValue = prefix + emittedValue;
            if (useSuffixInValue) emittedValue = emittedValue + suffix;
            onValueChange(emittedValue);
            inputProps.onChange?.(e);
          }}
          onBlur={(e) => {
            e.currentTarget.value = displayValue(e.currentTarget.value);
            inputProps.onBlur?.(e);
          }}
        />
        {!!suffix && (
          <Tag borderTopLeftRadius={0} borderBottomLeftRadius={0} px={3} flexShrink={0}>
            {suffix}
          </Tag>
        )}
      </Flex>
    );
  }
);
