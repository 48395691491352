import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { QuestionBox } from "../components/QuestionBox";
import { isBooleanQuestion } from "../utils/apiUtils";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";
import { useSurveyState } from "../SurveyContext";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { BooleanQuestionModel, UpdateBooleanQuestionModel } from "../api/ManagerApi";

type Props = {
  questionId: number;
};

export const BooleanQuestion = ({ questionId }: Props) => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const [, updateView] = useForceUpdate("View");
  const { api, updatePageToken, updateSurveyToken, pageToken } = useSurveyState();
  const [valid, setValid] = useState(true);

  useEffect(() => updateQuestionToken(), [pageToken, updateQuestionToken]);

  const [question] = usePromise(() => {
    return api.surveys.getElement(Number(surveyId), questionId).then((r) => {
      if (isBooleanQuestion(r.data)) return r.data;
    });
  }, [questionRefreshToken]);

  const updateQuestion = useCallback(
    (questionModel: Partial<BooleanQuestionModel>) => {
      if (!surveyId || !question || !surveyPageId) return;
      api.surveys
        .updateElement(Number(surveyId), question.id, {
          pageId: Number(surveyPageId),
          ...question,
          ...questionModel,
        } as UpdateBooleanQuestionModel)
        .then(updateSurveyToken)
        .then(() => setValid(true))
        .then(updateQuestionToken)
        .catch(() => {
          setValid(false);
          Object.assign(question, questionModel);
          updateView();
        });
    },
    [
      api.surveys,
      question,
      surveyId,
      surveyPageId,
      updateSurveyToken,
      updateQuestionToken,
      updateView,
    ]
  );

  const deleteQuestion = useCallback(() => {
    if (!surveyId || !question) return;
    api.surveys
      .deleteElement(parseInt(surveyId), question.id)
      .then(updateSurveyToken)
      .then(updatePageToken);
  }, [api.surveys, question, surveyId, updatePageToken, updateSurveyToken]);

  if (!question) return null;

  return (
    <QuestionBox questionType="Ja/nee vraag" questionTitle={question.title} valid={valid}>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Vraag</Text>
        </FormLabel>
        <Input
          defaultValue={question.title}
          onBlur={(e) => updateQuestion({ title: e.currentTarget.value })}
        />
      </FormControl>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Omschrijving</Text>
        </FormLabel>
        <Input
          defaultValue={question.content}
          onBlur={(e) => updateQuestion({ content: e.currentTarget.value })}
        />
      </FormControl>
      <Flex my={4} gap={4} alignItems="end">
        <Checkbox
          defaultChecked={question.required}
          onChange={(e) =>
            updateQuestion({
              ...question,
              required: e.target.checked,
            })
          }
        >
          Verplicht
        </Checkbox>
      </Flex>
      <Box display={"flex"} gap={4}>
        <FormControl variant="floating" flex={1}>
          <FormLabel>
            <Text fontSize="md">Label</Text>
          </FormLabel>
          <Input
            defaultValue={question.label}
            onBlur={(e) => updateQuestion({ label: e.currentTarget.value })}
          />
        </FormControl>
      </Box>
      <Flex>
        <Button ml="auto" mt={4} colorScheme="red" onClick={deleteQuestion}>
          Vraag verwijderen
        </Button>
      </Flex>
    </QuestionBox>
  );
};
