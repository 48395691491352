import {
  Text,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { ManageDataSourcesContext, Pages } from "./context";
import { useSurveyState } from "../../../SurveyContext";
import { ComposedTextInput } from "../../../components/ComposedTextInput";

const NOK = Object.freeze({ ok: false });

export const StartAddSep = () => {
  const { state, setState } = useContext(ManageDataSourcesContext);
  const { wordpressApi } = useSurveyState();
  const [host, setHost] = useState<string>(state.sepHost);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setState((c) => ({
      ...c,
      nextPage: c.sepHost ? Pages.SelectSepSources : undefined,
    }));
  }, [setState]);

  const testConnection = useCallback(async () => {
    if (!host) return;

    let base: URL;

    try {
      base = new URL("/wp-json", host);
    } catch {
      setError("Deze url is geen geldige url");
      setState((c) => ({ ...c, sepHost: "", nextPage: undefined, sourcesToImport: [] }));
      return;
    }

    const responses = await Promise.all([
      wordpressApi.config({ baseUrl: base.href }),
      wordpressApi.scenarios.list({ baseUrl: base.href }),
      wordpressApi.panos.list({ baseUrl: base.href }),
    ]).catch(() => [NOK]);

    if (responses.every((r) => r.ok)) {
      setState((c) => ({
        ...c,
        sepHost: host,
        nextPage: Pages.SelectSepSources,
        sourcesToImport: [],
      }));
    } else {
      setError("Deze url is geen geldige SEP url");
      setState((c) => ({ ...c, sepHost: "", nextPage: undefined, sourcesToImport: [] }));
    }
  }, [host, wordpressApi, setState]);

  return (
    <>
      <Text my={2}>Vul hieronder de Url van het Stakeholder Engagement Platform in.</Text>
      <FormControl variant="floating" isRequired isInvalid={!!error} my={4}>
        <FormLabel>
          <Text fontSize="md">SEP Url</Text>
        </FormLabel>
        <ComposedTextInput
          prefix="https://"
          usePrefixInValue
          value={host}
          placeholder="example.ik-doe-mee.nl"
          trimValue
          onValueChange={(value) => {
            setState((c) => ({
              ...c,
              nextPage: value === c.sepHost ? Pages.SelectSepSources : undefined,
            }));
            setHost(value);
            setError(undefined);
          }}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
      {!!host && <Button onClick={testConnection}>Valideer</Button>}
    </>
  );
};
