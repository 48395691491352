import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { ManagerApi } from "./api/ManagerApi";
import { WordpressApi } from "./api/WordpressApi";
import { API_KEY_HEADER } from "./api/constants";
import { MapboxApi } from "./api/MapboxApi";
import { useForceUpdate } from "./utils/useForceUpdate";

const SurveyStateContext = createContext<{
  api: ManagerApi<unknown>;
  wordpressApi: WordpressApi;
  mapboxApi: MapboxApi;
  regeneratedApiKeys: Map<string, string>;
  login: (key: string) => void;
  logout: () => void;
  isAuthenticated: boolean;
  readonly surveyToken: symbol;
  updateSurveyToken: () => void;
  readonly pageToken: symbol;
  updatePageToken: () => void;
}>({
  api: new ManagerApi<unknown>(),
  wordpressApi: new WordpressApi(),
  mapboxApi: new MapboxApi(),
  regeneratedApiKeys: new Map<string, string>(),
  login: () => {},
  logout: () => {},
  isAuthenticated: false,
  surveyToken: Symbol("Survey"),
  updateSurveyToken: () => {},
  pageToken: Symbol("Page"),
  updatePageToken: () => {},
});

export const SurveyStateProvider = ({ children }: any) => {
  const [apiKey, setApiKey] = useState<string>(
    () => process.env.REACT_APP_MANAGER_API_KEY || localStorage.apiKey || ""
  );
  const [surveyToken, updateSurveyToken] = useForceUpdate("Survey");
  const [pageToken, updatePageToken] = useForceUpdate("Page");

  const regeneratedApiKeys = useMemo(() => new Map<string, string>(), []);

  const login = useCallback((key: string) => {
    setApiKey(key);
    localStorage.apiKey = key;
  }, []);

  const logout = useCallback(() => {
    setApiKey("");
    localStorage.removeItem("apiKey");
  }, []);

  const isAuthenticated = useMemo(() => !!apiKey, [apiKey]);

  const api = useMemo(
    () =>
      new ManagerApi({
        baseUrl: process.env.REACT_APP_MANAGER_API_BASE_URL,
        securityWorker: () =>
          apiKey
            ? {
                headers: {
                  [API_KEY_HEADER]: apiKey,
                },
              }
            : {},
      }),
    [apiKey]
  );

  const wordpressApi = useMemo(() => new WordpressApi(), []);
  const mapboxApi = useMemo(() => new MapboxApi(), []);

  return (
    <SurveyStateContext.Provider
      value={{
        api,
        wordpressApi,
        mapboxApi,
        regeneratedApiKeys,
        login,
        logout,
        isAuthenticated,
        surveyToken,
        updateSurveyToken,
        pageToken,
        updatePageToken,
      }}
    >
      {children}
    </SurveyStateContext.Provider>
  );
};

export const useSurveyState = () => useContext(SurveyStateContext);
