import type { ComponentStyleConfig } from "@chakra-ui/react";

export const TabTheme: ComponentStyleConfig = {
  variants: {
    line: {
      tabPanel: {
        display: "flex",
        flexDir: "column",
        overflow: "hidden",
      },
      tabPanels: {
        display: "flex",
        flexDir: "column",
        overflow: "hidden",
      },
      tablist: {
        border: "none",
        fontFamily: "heading",
      },
      tab: {
        position: "relative",
        border: "none",
        textTransform: "uppercase",
        fontWeight: "bold",
        color: "gray.700",
        px: 4,
        py: 2,
        _selected: {
          color: "black",
          _after: {
            content: `""`,
            pos: "absolute",
            w: 8,
            h: 1,
            bottom: 0,
            borderRadius: "2px",
            bg: "blue.500",
          },
        },
      },
    },
  },
};
