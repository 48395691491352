import { DownloadIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useCallback } from "react";
import styled from "@emotion/styled";
import { FileModel, FileUploadAnswerModel } from "../api/ManagerApi";
import { useSurveyState } from "../SurveyContext";

type Props = {
  entries: FileUploadAnswerModel[];
};

export const FileUploadResult = ({ entries }: Props) => {
  const { api } = useSurveyState();

  const getFileUrl = useCallback(
    (file: FileModel) =>
      api.files
        .getFile(file.id)
        .then((response) => URL.createObjectURL(response.data as unknown as Blob)),
    [api.files]
  );

  return (
    <FileUploadResultContainer>
      {entries.map((e) =>
        e.files.map((f) => (
          <div key={f.id}>
            <Button
              variant="ghost"
              onClick={() => getFileUrl(f).then((url) => window.open(url, f.name))}
            >
              <DownloadIcon mr={4} color="blue.500" />
              {f.name.replaceAll("+", " ")}
            </Button>
          </div>
        ))
      )}
    </FileUploadResultContainer>
  );
};

const FileUploadResultContainer = styled.div`
  max-height: 400px;
  width: 100%;
  overflow-y: auto;

  > div {
    padding: 0.25rem 0;
    text-indent: 0.5rem;

    &:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
`;
