import { Style } from "mapbox-gl";
import { ApiConfig, HttpClient, RequestParams } from "./ManagerApi";

export class MapboxApi extends HttpClient {
  constructor(apiConfig: ApiConfig = {}) {
    super({
      baseUrl: "https://api.mapbox.com",
      ...apiConfig,
    });
  }

  styles = {
    get: (styleId: string, access_token: string, params: RequestParams = {}) => {
      return this.request<Style>({
        path: `/styles/v1/${styleId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
        query: { access_token },
      })
    }
  }
}
