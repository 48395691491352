import type { ComponentStyleConfig } from "@chakra-ui/react";

export const AccordionTheme: ComponentStyleConfig = {
  baseStyle: {
    container: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "gray.300",
      borderRadius: 6,
      my: 6,
    },
    button: {
      _hover: {
        bg: "unset",
      },
    },
  },
};
