import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { QuestionnaireListItemModel } from "../../api/ManagerApi";
import { useFrameContext } from "../../FrameContext";
import { useSurveyState } from "../../SurveyContext";

type Props = {
  onDelete: () => void;
  survey?: QuestionnaireListItemModel;
  onCancel: () => void;
};

export const DeleteSurveyModal = ({ onDelete, onCancel, survey }: Props) => {
  const { onClose } = useDisclosure();
  const { api } = useSurveyState();
  const { authenticate } = useFrameContext();

  const cancel = useCallback(() => {
    onClose();
    onCancel();
  }, [onClose, onCancel]);

  const deleteSurvey = useCallback(async () => {
    await authenticate();
    await api.surveys.deleteSurvey(survey!.id);
    onClose();
    onDelete();
  }, [survey, authenticate, api.surveys, onDelete, onClose]);

  return (
    <>
      <Modal isOpen={!!survey} onClose={cancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enquête verwijderen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Weet je zeker dat je deze enquête wilt verwijderen?</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={cancel}>
              Annuleren
            </Button>
            <Button colorScheme={"red"} onClick={deleteSurvey}>
              Verwijderen
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
