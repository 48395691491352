import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ManageDataSourcesContext, Pages } from "./context";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { PanoramaType } from "../../../api/enums";
import { useForceUpdate } from "../../../utils/useForceUpdate";
import { AddIcon, WarningIcon } from "@chakra-ui/icons";
import { useSurveyState } from "../../../SurveyContext";
import usePromise from "../../../utils/usePromise";
import { useParams } from "react-router-dom";
import { isPanoramaSource } from "../../../utils/apiUtils";
import { ConfirmModal } from "../ConfirmModal";
import { SimplePanoViewer } from "../../../components/SimplePanoViewer";
import { CreatePanoramaSourceModel } from "../../../api/ManagerApi";
import { ComposedTextInput } from "../../../components/ComposedTextInput";

const panoTypes = [
  { value: PanoramaType.Cube, label: "Cube" },
  { value: PanoramaType.Sphere, label: "Sphere" },
];

enum URLErrors {
  InUse = "Deze url is al in gebruik",
  MissingPlaceholders = "Niet alle placeholders zijn vermeld in de url",
}

export const StartAddCustomPano = () => {
  const { state, setState, token } = useContext(ManageDataSourcesContext);
  const [name, setName] = useState("Custom panorama");
  const inputRef = useRef<HTMLInputElement>(null);
  const [panoType, setPanoType] = useState<PanoramaType>();
  const [selectionRange, setSelectionRange] = useState<
    [number, number, "forward" | "backward" | "none" | undefined]
  >([0, 0, undefined]);
  const [levels, setLevels] = useState<number[]>([500]);
  const [, updateView] = useForceUpdate("View");
  const { api } = useSurveyState();
  const surveyId = Number(useParams().surveyId);
  const [previewSource, setPreviewSource] = useState<CreatePanoramaSourceModel>();
  const [errors, setErrors] = useState<Record<string, string[]>>({});

  const [currentPanoSources] = usePromise(
    () =>
      token && api.surveys.listDataSources(surveyId).then((r) => r.data.filter(isPanoramaSource)),
    [api, token, surveyId]
  );

  useEffect(
    () =>
      setState((c) => ({ ...c, nextPage: c.sourcesToImport.length ? Pages.Confirm : undefined })),
    [setState]
  );

  const determineHeight = useCallback((width: number) => {
    switch (panoType) {
      case PanoramaType.Cube:
        return width;
      case PanoramaType.Sphere:
        return width / 2;
      default:
        return 0;
    }
  }, [panoType]);

  const insertPlaceholder = useCallback(
    (param: string) => {
      if (!inputRef.current) return;
      const [start, end, direction] = selectionRange;
      const value = inputRef.current.value;

      inputRef.current.value = `${value.slice(0, start)}{${param}}${value.slice(end)}`;

      inputRef.current.focus();

      if (direction !== "backward") {
        inputRef.current.setSelectionRange(
          start + param.length + 2,
          start + param.length + 2,
          direction ?? undefined
        );
      } else {
        inputRef.current.setSelectionRange(start, start, "backward");
      }

      updateView();
    },
    [updateView, selectionRange]
  );

  const validatePlaceHolders = useCallback(
    (value: string) => {
      const levelPlaceholdersSatisfied =
        levels.length === 1 || ["{l}"].every((ph) => value.includes(ph));
      const cubePlaceholdersSatisfied =
        panoType !== PanoramaType.Cube || ["{f}", "{v}", "{h}"].every((ph) => value.includes(ph));

      return levelPlaceholdersSatisfied && cubePlaceholdersSatisfied;
    },
    [panoType, levels]
  );

  const validateUrl = useCallback(
    (value: string) => {
      const urlErrors = new Set<string>();

      if (currentPanoSources?.some((s) => s.imageUrl === value)) {
        urlErrors.add(URLErrors.InUse);
      }

      if (!validatePlaceHolders(value)) {
        urlErrors.add(URLErrors.MissingPlaceholders);
      }

      setErrors((c) => ({ ...c, url: Array.from(urlErrors) }));

      return !urlErrors.size;
    },
    [validatePlaceHolders, currentPanoSources]
  );

  const createPreviewSource = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      if (!inputRef.current || panoType === undefined) return;

      const imageUrl = "https://" + inputRef.current.value.replace("https://", "");

      const isValid = validateUrl(imageUrl);

      if (isValid) {
        setPreviewSource({
          type: "PanoramaSource",
          name,
          imageUrl,
          panoType,
          levels,
        });
      } else {
        setPreviewSource(undefined);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [panoType, levels, name, validateUrl]
  );

  return (
    <>
      <Text my={2}>Geef de panorama een naam</Text>
      <FormControl variant="floating" isRequired>
        <FormLabel>
          <Text fontSize="md">Naam</Text>
        </FormLabel>
        <Input value={name} onChange={(e) => setName(e.target.value.trim())} />
      </FormControl>
      <Text my={2}>Wat voor soort panorama is het</Text>
      <RadioGroup
        value={panoType}
        onChange={(value: string) => setPanoType(Number(value) as PanoramaType)}
      >
        <Stack>
          {panoTypes.map((type) => (
            <Radio value={type.value} key={type.value}>
              {type.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {panoType !== undefined && (
        <>
          <Text mt={4} mb={2}>
            Resoluties (b×h)
          </Text>
          <Wrap gap={2} alignItems="center">
            {levels.map((level, index) => (
              <Tag key={level} colorScheme="blue">
                <TagLabel>
                  <NumberInput defaultValue={level} display="flex" alignItems="center" gap={2}>
                    <NumberInputField
                      px={1}
                      paddingEnd={1}
                      textAlign="end"
                      maxW={16}
                      height={6}
                      style={{ width: `${level.toString().length}ch` }}
                      my={1}
                      boxSizing="content-box"
                      onBlur={(e) => {
                        if (+e.currentTarget.value !== level)
                          setLevels((c) =>
                            c
                              .filter((_, i) => i !== index)
                              .concat(+e.currentTarget.value)
                              .sort((a, b) => a - b)
                          );
                      }}
                      onChange={(e) => e.currentTarget.style.width = `${e.currentTarget.value.length}ch`}
                    /><Text color="gray.500">&times; {determineHeight(level)} px</Text>
                  </NumberInput>
                </TagLabel>
                {levels.length > 1 && (
                  <TagCloseButton
                    onClick={() => setLevels((c) => c.filter((_, i) => i !== index))}
                  />
                )}
              </Tag>
            ))}
            <Tag
              title="Resolutie toevoegen"
              color="blue.500"
              cursor="pointer"
              mx={2}
              lineHeight={0}
              onClick={() => {
                setLevels((c) => [...c, Math.max(...c) * 2]);
              }}
            >
              <AddIcon />
            </Tag>
          </Wrap>
          <Text mt={4} mb={2}>
            Vul hieronder de Url van de panorama in.
          </Text>
          <FormControl variant="floating" isRequired my={4} isInvalid={!!errors["url"]?.length}>
            <FormLabel>
              <Text fontSize="md">Panorama Url</Text>
            </FormLabel>
            <ComposedTextInput
              ref={inputRef}
              prefix="https://"
              usePrefixInValue
              onValueChange={updateView}
              onSelect={(e) =>
                setSelectionRange([
                  e.currentTarget.selectionStart ?? 0,
                  e.currentTarget.selectionEnd ?? 0,
                  e.currentTarget.selectionDirection ?? undefined,
                ])
              }
              onBlur={(e) => validateUrl("https://" + e.currentTarget.value)}
            />
            <FormErrorMessage>
              <ul>{errors["url"]?.map((e, i) => <li key={i}>{e}</li>)}</ul>
            </FormErrorMessage>
          </FormControl>
        </>
      )}
      {(levels.length > 1 || panoType === PanoramaType.Cube) && (
        <Box>
          <Text mt={4} mb={2}>
            Gebruik onderstaande knoppen om placeholders toe te voegen aan de url.
          </Text>
          <Wrap gap={2}>
            {levels.length > 1 && (
              <Tag
                colorScheme={inputRef.current?.value.includes("{l}") ? "green" : "red"}
                onClick={() => insertPlaceholder("l")}
                cursor="pointer"
              >
                <TagLabel>
                  Zoomniveau <em>{"{l}"}</em>
                </TagLabel>
              </Tag>
            )}
            {panoType === PanoramaType.Cube && (
              <>
                <Tag
                  colorScheme={inputRef.current?.value.includes("{f}") ? "green" : "red"}
                  onClick={() => insertPlaceholder("f")}
                  cursor="pointer"
                >
                  <TagLabel>
                    Zijde <em>{"{f}"}</em>
                  </TagLabel>
                </Tag>
                <Tag
                  colorScheme={inputRef.current?.value.includes("{v}") ? "green" : "red"}
                  onClick={() => insertPlaceholder("v")}
                  cursor="pointer"
                >
                  <TagLabel>
                    Tegelnummer (verticaal) <em>{"{v}"}</em>
                  </TagLabel>
                </Tag>
                <Tag
                  colorScheme={inputRef.current?.value.includes("{h}") ? "green" : "red"}
                  onClick={() => insertPlaceholder("h")}
                  cursor="pointer"
                >
                  <TagLabel>
                    Tegelnummer (horizontaal) <em>{"{h}"}</em>
                  </TagLabel>
                </Tag>
              </>
            )}
          </Wrap>
        </Box>
      )}
      <ConfirmModal
        modalProps={{ size: "3xl" }}
        buttonNode={
          <Button
            disabled={Object.values(errors).some((v) => v.length)}
            onClick={createPreviewSource}
          >
            Bekijk panorama
          </Button>
        }
        buttonNodeProps={{ my: 2 }}
        titleNode="Panorama preview"
        bodyNode={
          <Box h="xl">
            {previewSource ? (
              <SimplePanoViewer source={previewSource} />
            ) : (
              <Text colorScheme="red">
                <WarningIcon /> De panorama kon niet worden geladen
              </Text>
            )}
          </Box>
        }
        confirmNode="Opslaan en doorgaan"
        onConfirm={() => {
          if (!previewSource || state.sourcesToImport.includes(previewSource)) return;
          setState((c) => ({
            ...state,
            previousText: "Nog een bron toevoegen",
            previousPage: Pages.StartAddCustom,
            currentPage: Pages.Confirm,
            nextPage: undefined,
            sourcesToImport: [...state.sourcesToImport, previewSource],
          }));
        }}
      />
    </>
  );
};
