import { MinusIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  LocationPickerQuestionModel,
  PinDefinitionModel,
  RestrictionLayerModel,
  UpdateLocationPickerQuestionModel,
} from "../api/ManagerApi";
import { QuestionBox } from "../components/QuestionBox";
import { useSurveyState } from "../SurveyContext";
import { isLayerSource, isLocationPickerQuestion } from "../utils/apiUtils";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";
import { RestrictionLayerType } from "../api/enums";
import { MultiSelect } from "../components/MultiSelect";

type Props = {
  questionId: number;
};

const colorValues: { label: string; value: string }[] = [
  { label: "Blauw", value: "#0000FF" },
  { label: "Rood", value: "#FF0000" },
  { label: "Groen", value: "#00FF00" },
  { label: "Geel", value: "#FFFF00" },
  { label: "Paars", value: "#800080" },
  { label: "Oranje", value: "#FFA500" },
  { label: "Cyaan", value: "#00FFFF" },
  { label: "Magenta", value: "#FF00FF" },
  { label: "Zwart", value: "#000000" },
  { label: "Wit", value: "#FFFFFF" },
];

const getNextColor = (colors: (string | null | undefined)[]) =>
  Object.values(colorValues).find((color) => !colors.includes(color.value))?.value ?? "#0000FF";

export const MultipleLocationsQuestion = ({ questionId }: Props) => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const { api, surveyToken, updateSurveyToken, pageToken, updatePageToken } = useSurveyState();
  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const [valid, setValid] = useState(true);
  const [invalidPins, setInvalidPins] = useState<number[]>([]);
  const [, updateView] = useForceUpdate("View");

  useEffect(() => updateQuestionToken(), [pageToken, updateQuestionToken]);

  const [survey] = usePromise(() => {
    return api.surveys.getSurvey(Number(surveyId)).then((r) => r.data);
  }, [surveyId, questionRefreshToken]);

  const [question] = usePromise(() => {
    return api.surveys.getElement(Number(surveyId), questionId).then((r) => {
      if (isLocationPickerQuestion(r.data)) return r.data;
    });
  }, [questionRefreshToken]);

  const [page] = usePromise(() => {
    return api.surveys.getPage(Number(surveyId), Number(surveyPageId)).then((r) => r.data);
  }, [pageToken]);

  const deleteQuestion = useCallback(() => {
    if (!surveyId || !question) return;
    api.surveys
      .deleteElement(parseInt(surveyId), question.id)
      .then(updateSurveyToken)
      .then(updatePageToken);
  }, [api.surveys, question, surveyId, updatePageToken, updateSurveyToken]);

  const addPin = useCallback(() => {
    if (!surveyId || !question) return;
    const existingColors = question.pins.map((pin) => pin.color);
    const nextColor = getNextColor(existingColors);
    api.surveys
      .createPinDefinition(parseInt(surveyId), question.id, {
        color: nextColor,
        label: "Pin",
        minAmount: 0,
        allowComment: true,
      })
      .then(updateSurveyToken)
      .then(updateQuestionToken);
  }, [api.surveys, question, surveyId, updateSurveyToken, updateQuestionToken]);

  const removePin = useCallback(
    (pinId: number) => {
      if (!surveyId || !question) return;
      api.surveys
        .deletePinDefinition(parseInt(surveyId), question.id, pinId)
        .then(updateSurveyToken)
        .then(updateQuestionToken);
    },
    [api.surveys, question, surveyId, updateSurveyToken, updateQuestionToken]
  );

  const updatePin = useCallback(
    (pinModel: PinDefinitionModel) => {
      if (!surveyId || !question) return;
      const pin = question.pins.find((pin) => pin.id === pinModel.id);
      if (!pin) return;
      api.surveys
        .updatePinDefinition(parseInt(surveyId), questionId, pin.id, {
          label: pinModel.label,
          color: pinModel.color,
          minAmount: pinModel.minAmount,
          maxAmount: pinModel.maxAmount,
          allowComment: pinModel.allowComment,
        })
        .then(updateSurveyToken)
        .then(() =>
          setInvalidPins((pins) =>
            pins.includes(pin.id) ? pins.filter((p) => p !== pin.id) : pins
          )
        )
        .then(updateQuestionToken)
        .catch(() => {
          setInvalidPins((pins) => (pins.includes(pin.id) ? pins : [...pins, pin.id]));
          Object.assign(pin!, pinModel);
          updateView();
        });
    },
    [
      api.surveys,
      question,
      questionId,
      surveyId,
      updateSurveyToken,
      updateQuestionToken,
      updateView,
    ]
  );

  const updateQuestion = useCallback(
    (questionModel: LocationPickerQuestionModel) => {
      if (!surveyId || !surveyPageId || !question) return;

      api.surveys
        .updateElement(Number(surveyId), questionModel.id, {
          title: questionModel.title,
          content: questionModel.content,
          type: questionModel.type,
          pageId: Number(surveyPageId),
        } as UpdateLocationPickerQuestionModel)
        .then(updateSurveyToken)
        .then(() => setValid(true))
        .then(updateQuestionToken)
        .catch(() => {
          setValid(false);
          Object.assign(question, questionModel);
          updateView();
        });
    },
    [
      api.surveys,
      question,
      surveyId,
      surveyPageId,
      updateSurveyToken,
      updateQuestionToken,
      updateView,
    ]
  );

  var layerSource = useMemo(
    () => survey?.dataSources.filter(isLayerSource).find((s) => surveyToken && s.id === page?.dataSourceId),
    [survey, page, surveyToken]
  );

  const addRestriction = useCallback(() => {
    if (!surveyId || !question || !layerSource?.clusters.length) return;
    const clustersInUse = Array.from(new Set(question.restrictions.flatMap((r) => r.cluster)));
    api.surveys
      .createRestrictionLayer(parseInt(surveyId), question.id, {
        restrictionType: RestrictionLayerType.Restricted,
        message: "Hier mag niet geplaatst worden.",
        cluster: [
          layerSource.clusters.find((c) => !clustersInUse.includes(c)) ?? layerSource.clusters[0],
        ],
      })
      .then(updateSurveyToken)
      .then(updateQuestionToken);
  }, [api.surveys, layerSource, question, surveyId, updateSurveyToken, updateQuestionToken]);

  const updateRestriction = useCallback(
    (restriction: RestrictionLayerModel) => {
      if (!surveyId || !question || !layerSource?.clusters.length) return;
      const restrictionLayer = question.restrictions.find((r) => r.id === restriction.id);
      if (!restrictionLayer) return;
      api.surveys
        .updateRestrictionLayer(parseInt(surveyId), question.id, restriction.id, {
          restrictionType: restriction.restrictionType,
          message: restriction.message,
          cluster: restriction.cluster,
          pins: restriction.pins,
        })
        .then(updateSurveyToken)
        .then(() => setValid(true))
        .then(updateQuestionToken)
        .then(updateView)
        .catch(() => {
          setValid(false);
          Object.assign(restrictionLayer, restriction);
          updateView();
        });
    },
    [
      api.surveys,
      layerSource,
      question,
      surveyId,
      updateSurveyToken,
      updateQuestionToken,
      updateView,
    ]
  );

  const deleteRestriction = useCallback(
    (restrictionId: number) => {
      if (!surveyId || !question) return;
      api.surveys
        .deleteRestrictionLayer(parseInt(surveyId), question.id, restrictionId)
        .then(updateSurveyToken)
        .then(updateQuestionToken);
    },
    [api.surveys, question, surveyId, updateSurveyToken, updateQuestionToken]
  );

  if (!question) return null;

  return (
    <QuestionBox
      questionType="Meerdere locaties vraag"
      questionTitle={question?.title}
      valid={valid}
    >
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Vraag</Text>
        </FormLabel>
        <Input
          defaultValue={question?.title}
          onBlur={(e) =>
            updateQuestion({
              ...question,
              title: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Omschrijving</Text>
        </FormLabel>
        <Input
          defaultValue={question?.content}
          onBlur={(e) =>
            updateQuestion({
              ...question,
              content: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <Heading size="sm" mt={4}>
        Opties
      </Heading>
      <Heading size="sm" mt={4}>
        Pins
      </Heading>
      {question?.pins.map((pin) => (
        <Flex key={pin.id} gap={6}>
          {invalidPins.includes(pin.id) && <WarningTwoIcon color="yellow.500" />}
          <FormControl variant="floating" flex={2}>
            <FormLabel>
              <Text fontSize="md">Naam</Text>
            </FormLabel>
            <Input
              defaultValue={pin.label}
              onBlur={(e) =>
                updatePin({
                  ...pin,
                  label: e.currentTarget.value,
                })
              }
            />
          </FormControl>
          <FormControl variant="floating" flex={1.25}>
            <FormLabel>
              <Text fontSize="md">
                Kleur <ColorPreview bg={pin.color ?? "#0000FF"} />
              </Text>
            </FormLabel>
            <Select
              value={pin.color ?? "#0000FF"}
              onChange={(e) =>
                updatePin({
                  ...pin,
                  color: e.currentTarget.value,
                })
              }
            >
              {colorValues.map((color) => (
                <option key={color.value} value={color.value} selected={color.value === pin.color}>
                  {color.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="floating" flex="1 2">
            <FormLabel>
              <Text fontSize="md">Min pins</Text>
            </FormLabel>
            <NumberInput
              defaultValue={pin.minAmount}
              max={pin.maxAmount ?? undefined}
              onChange={(str, num) =>
                updatePin({
                  ...pin,
                  minAmount: num,
                })
              }
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>
          <FormControl variant="floating" flex="1 2">
            <FormLabel>
              <Text fontSize="md">Max pins</Text>
            </FormLabel>
            <NumberInput
              defaultValue={pin.maxAmount ?? ""}
              min={pin.minAmount}
              onChange={(str, num) =>
                updatePin({
                  ...pin,
                  maxAmount: num,
                })
              }
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>
          <FormControl variant="floating" flex={1.5}>
            <FormLabel>
              <Text fontSize="md">Commentaar</Text>
            </FormLabel>
            <Select
              defaultValue={Number(pin.allowComment)}
              onChange={(e) =>
                updatePin({
                  ...pin,
                  allowComment: Boolean(Number(e.currentTarget.value)),
                })
              }
            >
              <option value={1}>Toestaan</option>
              <option value={0}>Niet Toestaan</option>
            </Select>
          </FormControl>
          {question.pins.length > 1 && (
            <Button mt={4} colorScheme={"red"} onClick={() => removePin(pin.id)}>
              <MinusIcon boxSize={3} />
            </Button>
          )}
        </Flex>
      ))}
      <Button mt={4} onClick={addPin}>
        Pin toevoegen
      </Button>
      {layerSource?.clusters && (
        <Box>
          <Heading size="sm" mt={4}>
            Restricties
          </Heading>
          <Stack gap={0}>
            {question.restrictions.map((restriction, i) => (
              <>
                {i > 0 && <StackDivider pt={2} borderBlockEndStyle="solid" borderBlockEndWidth={1} borderColor="gray.300" />}
                <Grid
                  key={restriction.id}
                  templateRows="auto auto"
                  templateColumns="2fr 2fr auto"
                  templateAreas={`
                    "layer type del"
                    "pins  msg  del"
                  `}
                  columnGap={6}
                >
                  <FormControl variant="floating" gridArea="layer" isRequired>
                    <FormLabel>
                      <Text fontSize="md">Laag</Text>
                    </FormLabel>
                    <MultiSelect
                      currentValue={restriction.cluster}
                      modalTitle="Restrictie(s) toewijzen"
                      options={layerSource!.clusters}
                      onUpdate={(cluster) =>
                        updateRestriction({
                          ...restriction,
                          cluster,
                        })
                      }
                    />
                  </FormControl>
                  <FormControl variant="floating" gridArea="pins" isRequired>
                    <FormLabel>
                      <Text fontSize="md">Pins</Text>
                    </FormLabel>
                    <MultiSelect
                      currentValue={restriction.pins}
                      modalTitle="Pin(s) toewijzen"
                      options={question.pins.map((pin) => ({ label: pin.label, value: pin.id }))}
                      onUpdate={(pins) =>
                        updateRestriction({
                          ...restriction,
                          pins,
                        })
                      }
                      shownOptions={5}
                    />
                  </FormControl>
                  <FormControl variant="floating" gridArea="type">
                    <FormLabel>
                      <Text fontSize="md">Type restrictie</Text>
                    </FormLabel>
                    <Select
                      defaultValue={restriction.restrictionType}
                      onChange={(e) =>
                        updateRestriction({
                          ...restriction,
                          restrictionType: Number(e.currentTarget.value) as RestrictionLayerType,
                        })
                      }
                    >
                      <option value={RestrictionLayerType.Mandatory}>Verplichten</option>
                      <option value={RestrictionLayerType.Restricted}>Verbieden</option>
                    </Select>
                  </FormControl>
                  <FormControl variant="floating" gridArea="msg">
                    <FormLabel>
                      <Text fontSize="md">Commentaar</Text>
                    </FormLabel>
                    <Input
                      defaultValue={restriction.message}
                      onChange={(e) =>
                        updateRestriction({ ...restriction, message: e.currentTarget.value })
                      }
                    />
                  </FormControl>
                  <Button
                    mt={4}
                    colorScheme={"red"}
                    onClick={() => deleteRestriction(restriction.id)}
                    gridArea="del"
                    alignSelf="center"
                  >
                    <MinusIcon boxSize={3} />
                  </Button>
                </Grid>
              </>
            ))}
          </Stack>

          <Button mt={4} onClick={addRestriction}>
            Restrictie toevoegen
          </Button>
        </Box>
      )}
      <Flex justifyContent={"space-between"}>
        <Button mt={4} colorScheme={"red"} onClick={deleteQuestion} ml="auto">
          Vraag verwijderen
        </Button>
      </Flex>
    </QuestionBox>
  );
};

const ColorPreview = styled(Box)`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 25%;
  border: 1px solid black;
`;
