import type { ComponentStyleConfig } from '@chakra-ui/react';

export const HeadingTheme: ComponentStyleConfig = {
  baseStyle: {
    fontStretch: 'condensed',
  },
  sizes: {
    sm: {
      fontSize: '1.25rem',
      fontWeight: 'regular',
    },
    md: {
      fontSize: '1.5rem',
    },
    lg: {
      fontSize: '2rem',
    },
  },
};
