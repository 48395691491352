import type { ComponentStyleConfig } from "@chakra-ui/react";

export const SelectTheme: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        bg: "white",
        pl: 3,
        borderColor: "brand.grey.300",
        _hover: {
          borderColor: "blue.500",
        },
        _focusWithin: {
          borderColor: "blue.500",
        },
      },
    },
  },
};
