import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import usePromise from "../../../utils/usePromise";
import { useParams } from "react-router-dom";
import { useSurveyState } from "../../../SurveyContext";
import { CreateLayerSourceModel, CreatePanoramaSourceModel } from "../../../api/ManagerApi";
import { PanoramaType } from "../../../api/enums";
import { CheckboxGroup, Text, Stack, StackDivider, Checkbox, Flex } from "@chakra-ui/react";
import { ManageDataSourcesContext, Pages } from "./context";
import { AnyLayer, CustomLayerInterface, Layer } from "mapbox-gl";
import {
  isCreateLayerSource,
  isCreatePanoramaSource,
  isLayerSource,
  isPanoramaSource,
} from "../../../utils/apiUtils";
import { sequenceEqual } from "../../../utils/iterableUtils";
import { WarningTwoIcon } from "@chakra-ui/icons";

const panoValuePrefix = "pano:";
const layerValuePrefix = "layer:";

export const SelectSepSources = () => {
  const { state, setState } = useContext(ManageDataSourcesContext);
  const surveyId = Number(useParams().surveyId);
  const [selected, setSelected] = useState<string[]>(() =>
    state.sourcesToImport.reduce<string[]>((acc, curr) => {
      if (isCreatePanoramaSource(curr)) return [...acc, panoValuePrefix + curr.name];
      if (isCreateLayerSource(curr)) return [...acc, layerValuePrefix + curr.name];
      return acc;
    }, [])
  );
  const [panoMap, setPanoMap] = useState<Map<string, Set<CreatePanoramaSourceModel>>>(new Map());
  const { api, wordpressApi, mapboxApi } = useSurveyState();

  const sepHost = state.sepHost + "/wp-json";

  useEffect(() => {
    setState((c) => ({
      ...c,
      nextPageDisabled: !c.sourcesToImport.length,
      nextPage: c.sourcesToImport.length ? Pages.Confirm : undefined,
    }));
  }, [setState]);

  const [sources] = usePromise(
    async () => api.surveys.listDataSources(surveyId).then((r) => r.data),
    [surveyId, api]
  );

  const { currentPanoSources, currentLayerSources } = useMemo(
    () => ({
      currentPanoSources: sources?.filter(isPanoramaSource) ?? [],
      currentLayerSources: sources?.filter(isLayerSource) ?? [],
    }),
    [sources]
  );

  const [sepConfig] = usePromise(
    () => wordpressApi.config({ baseUrl: sepHost }).then((r) => r.data),
    [sepHost, wordpressApi]
  );

  const [panoSources] = usePromise(async () => {
    if (!sepConfig) return;

    const scenariosRaw = await wordpressApi.scenarios
      .list(undefined, { baseUrl: sepHost })
      .then((r) => r.data);
    const panosRaw = await wordpressApi.panos
      .list(undefined, { baseUrl: sepHost })
      .then((r) => r.data);

    const panoModelMap = new Map<string, Set<CreatePanoramaSourceModel>>();
    setPanoMap(panoModelMap);

    return panosRaw.flatMap<CreatePanoramaSourceModel>((p) =>
      p.acf.scenarios
        .filter((s) => s.resource.length === 1) // Panos with multiple resources are videos, which are currently not going to be supported.
        .map((s) => {
          const model = {
            name: [
              p.title.rendered,
              scenariosRaw.find((scenario) => scenario.id === s.scenario)?.title.rendered,
            ]
              .filter(Boolean)
              .join(" - "),
            imageUrl: `${state.sepHost}/panoramas/${sepConfig.acf.panorama_folder}/${s.resource[0]}/mres_{f}/l{l}/{v}/l{l}_{f}_{v}_{h}.jpg`,
            type: "PanoramaSource",
            panoType: PanoramaType.Cube,
            levels: [500, 1500, 3000],
          };

          const g = panoModelMap.get(p.title.rendered) ?? new Set();
          g.add(model);
          panoModelMap.set(p.title.rendered, g);

          return model;
        })
    );
  }, [sepConfig, state.sepHost]);

  const [layerSources] = usePromise(async () => {
    if (!sepConfig) return;

    const styleId = sepConfig.acf.mapbox_style_url.substring("mapbox://styles/".length);

    const raw = await mapboxApi.styles
      .get(styleId, sepConfig.acf.mapbox_access_token)
      .then((r) => r.data);

    const customLayers = raw.layers
      .filter((l: AnyLayer): l is Exclude<AnyLayer, CustomLayerInterface> =>
        l.hasOwnProperty("metadata")
      )
      .filter((l) => l.metadata && !l.metadata["mapbox:featureComponent"]);

    const clusters = customLayers.reduce(
      (acc, curr) => {
        const id = curr.metadata["mapbox:group"];
        const name = raw.metadata["mapbox:groups"][id].name;
        if (!acc[name]) acc[name] = [];
        acc[name].push(curr);
        return acc;
      },
      {} as Record<string, Layer[]>
    );

    return Object.entries(clusters).map<CreateLayerSourceModel>(([name, value]) => ({
      name,
      styleId,
      accessToken: sepConfig.acf.mapbox_access_token,
      clusters: value.map((v) => v.id),
      bounds: sepConfig.acf.initial_bounding_box,
      type: isLayerSource.expected,
    }));
  }, [sepConfig, sepHost, mapboxApi]);

  useEffect(() => {
    const selectedPanos = selected
      .filter((s) => s.startsWith(panoValuePrefix))
      .map((s) => s.substring(panoValuePrefix.length))
      .map((s) => panoSources?.find((ls) => ls.name === s))
      .filter((s): s is CreatePanoramaSourceModel => Boolean(s));

    const selectedLayers = selected
      .filter((s) => s.startsWith(layerValuePrefix))
      .map((s) => s.substring(layerValuePrefix.length))
      .map((s) => layerSources?.find((ls) => ls.name === s))
      .filter((s): s is CreateLayerSourceModel => Boolean(s));

    setState((c) => ({
      ...c,
      sourcesToImport: [...selectedPanos, ...selectedLayers],
      nextPage: !selectedPanos.length && !selectedLayers.length ? undefined : Pages.Confirm,
    }));
  }, [selected, layerSources, panoSources, setState]);

  const availablePanoSources = useMemo(
    () =>
      panoSources?.filter(
        (s) => !currentPanoSources.some((cs) => decodeURI(cs.imageUrl) === decodeURI(s.imageUrl))
      ) ?? [],
    [panoSources, currentPanoSources]
  );

  const availableLayerSources = useMemo(
    () =>
      layerSources?.filter(
        (s) =>
          !currentLayerSources.some(
            (cs) => cs.styleId === s.styleId && sequenceEqual(cs.clusters, s.clusters)
          )
      ) ?? [],
    [layerSources, currentLayerSources]
  );

  return (
    <>
      <Text my={2}>Selecteer hieronder de bronnen die je wilt toevoegen.</Text>
      <CheckboxGroup value={selected} onChange={(v) => setSelected(v.map(String))}>
        {!!availablePanoSources?.length && (
          <>
            <Text fontWeight="bold" my={2}>
              Panorama's:{" "}
              {availablePanoSources.length > 1 && (
                selected.filter((s) => s.startsWith(panoValuePrefix)).length ===
                availablePanoSources.length ? (
                  <Checkbox
                    isChecked
                    onChange={() =>
                      setSelected((s) => s.filter((c) => !c.startsWith(panoValuePrefix)))
                    }
                  >
                    Alles deselecteren
                  </Checkbox>
                ) : (
                  <Checkbox
                    isChecked={false}
                    onChange={() =>
                      setSelected((s) => [
                        ...s.filter((c) => !c.startsWith(panoValuePrefix)),
                        ...availablePanoSources.map((p) => panoValuePrefix + p.name),
                      ])
                    }
                  >
                    Alles selecteren
                  </Checkbox>
                )
              )}
            </Text>
            <Stack>
              {Array.from(panoMap).map(([name, models]) => (
                <Fragment key={name}>
                  <Text fontStyle="italic" mt={2}>
                    {name}
                  </Text>
                  {Array.from(models).map((p) => (
                    <Checkbox
                      key={p.name}
                      value={panoValuePrefix + p.name}
                    >
                      {p.name.slice(name.length + 3)}
                    </Checkbox>
                  ))}
                </Fragment>
              ))}
            </Stack>
            {!!layerSources?.length && <StackDivider my={4} />}
          </>
        )}
        {!!availableLayerSources?.length && (
          <>
            <Text fontWeight="bold" my={2}>
              Kaartlagen:{" "}
              {availableLayerSources.length > 1 && (
                selected.filter((s) => s.startsWith(layerValuePrefix)).length ===
                availableLayerSources.length ? (
                  <Checkbox
                    isChecked
                    onChange={() =>
                      setSelected((s) => s.filter((c) => !c.startsWith(layerValuePrefix)))
                    }
                  >
                    Alles deselecteren
                  </Checkbox>
                ) : (
                  <Checkbox
                    isChecked={false}
                    onChange={() =>
                      setSelected((s) => [
                        ...s.filter((c) => !c.startsWith(layerValuePrefix)),
                        ...availableLayerSources.map((p) => layerValuePrefix + p.name),
                      ])
                    }
                  >
                    Alles selecteren
                  </Checkbox>
                )
              )}
            </Text>
            <Stack>
              {availableLayerSources.map((p) => (
                <Checkbox key={p.name} value={layerValuePrefix + p.name}>
                  {p.name}
                </Checkbox>
              ))}
            </Stack>
          </>
        )}
        {panoSources !== undefined &&
          layerSources !== undefined &&
          !availablePanoSources.length &&
          !availableLayerSources.length && (
            <Flex as="p" color="yellow.500" mt={2} alignItems="center">
              <WarningTwoIcon mr={2} />
              Dit platform heeft geen nieuwe bronnen om toe te voegen.
            </Flex>
          )}
      </CheckboxGroup>
    </>
  );
};
