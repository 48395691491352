import { Box, Divider, Heading, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { AnswerModel, LocationPickerAnswerModel } from "../api/ManagerApi";
import {
  isBooleanAnswer,
  isChoiceAnswer,
  isFileUploadAnswer,
  isGeoLocation,
  isLocationPickerAnswer,
  isPanoLocation,
  isRatingAnswer,
  isTextAnswer,
  isTrackingAnswer,
} from "../utils/apiUtils";
import { FileUploadResult } from "../components/FileUploadResult";
import MapResult from "../components/MapResult";
import PanoResult from "../components/PanoResult";
import { TextResult } from "../components/TextResult";
import { BooleanResult } from "../components/BooleanResult";
import { ChoiceResult } from "../components/ChoiceResult";
import { RatingResult } from "../components/RatingResult";
import { TrackingResult } from "../components/TrackingResult";

interface QuestionResultProps {
  entries: AnswerModel[];
  name: string;
  responses: number;
  single?: boolean;
}

export const QuestionResult = ({
  name,
  entries,
  responses,
  single = entries.length === 1,
}: QuestionResultProps) => {
  const result = useMemo(() => {
    if (!entries.length)
      return <Text fontStyle="italic">Er zijn nog geen resultaten voor deze vraag.</Text>;
    if (isCollectionOf(isLocationPickerAnswer, entries)) {
      return (
        <>
          {containsGeoLocation(entries) && <MapResult heatmap={!single} entries={entries} />}
          {containsPanoLocation(entries) && <PanoResult heatmap={!single} entries={entries} />}
        </>
      );
    } else if (isCollectionOf(isTextAnswer, entries)) {
      return <TextResult entries={entries} />;
    } else if (isCollectionOf(isBooleanAnswer, entries)) {
      return <BooleanResult entries={entries} responses={responses} />;
    } else if (isCollectionOf(isChoiceAnswer, entries)) {
      return <ChoiceResult entries={entries} responses={responses} />;
    } else if (isCollectionOf(isRatingAnswer, entries)) {
      return <RatingResult entries={entries} responses={responses} />;
    } else if (isCollectionOf(isFileUploadAnswer, entries)) {
      return <FileUploadResult entries={entries} />;
    } else if (isCollectionOf(isTrackingAnswer, entries)) {
      return <TrackingResult entries={entries} responses={responses} />;
    }
  }, [entries, responses, single]);

  return result ? (
    <Box px={0}>
      <Heading as="h2" fontSize="lg" mb={4}>
        {name}
      </Heading>
      {result}
      <Divider my={6} />
    </Box>
  ) : null;
};

const isCollectionOf = <T extends AnswerModel>(
  predicate: (entry: AnswerModel) => entry is T,
  entries: AnswerModel[]
): entries is T[] => entries.every(predicate);

const containsGeoLocation = (answers: LocationPickerAnswerModel[]): boolean =>
  answers.flatMap((a) => a.pins).some(isGeoLocation);
const containsPanoLocation = (answers: LocationPickerAnswerModel[]): boolean =>
  answers.flatMap((a) => a.pins).some(isPanoLocation);
