import type { ComponentStyleConfig } from "@chakra-ui/react";

export const ModalTheme: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      borderRadius: 0,
      width: "100%",
    },
    header: {
      fontSize: "3xl",
    },
    closeButton: {
      bg: "gray.100",
      height: "3rem",
      width: "3rem",
      top: 3,
      borderRadius: "1000px",
    },
  },
  sizes: {
    "6xl": {
      dialog: {
        maxWidth: "calc(100vw - 8rem)",
      },
    },
  },
};
