import { Box, Flex, Grid, Image, Input, Text, Switch } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { PageNavigation } from "../components/PageNavigation";
import { StatusDropdown } from "../components/StatusDropdown";
import { SurveyBody } from "../compositions/SurveyBody";
import { useSurveyState } from "../SurveyContext";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInWeeks,
} from "date-fns";
import { ManageDataSourcesModal } from "../compositions/modals/ManageDataSourcesModal";
import { ManageDataSourcesContextProvider } from "../compositions/modals/ManageDataSourcesModal/context";

export const EditSurveyPage = () => {
  const surveyNameInputRef = useRef<HTMLInputElement>(null);
  const surveySingleEntryPolicyRef = useRef<HTMLInputElement>(null);
  const surveyId = Number(useParams().surveyId);
  const surveyPageId = Number(useParams().pageId);
  const { api, surveyToken, updateSurveyToken } = useSurveyState();
  const [, updateView] = useForceUpdate("SurveyView");

  const [survey] = usePromise(() => {
    return api.surveys.getSurvey(surveyId).then((r) => {
      const data = r.data;
      if (surveyNameInputRef.current) surveyNameInputRef.current.value = data.name;
      return data;
    });
  }, [surveyId, surveyToken]);

  const [pages] = usePromise(() => {
    return api.surveys.listPages(surveyId).then((r) => r.data);
  }, [surveyId, surveyPageId, surveyToken]);

  const updateSurvey = useCallback(() => {
    api.surveys
      .updateSurvey(surveyId, {
        name: surveyNameInputRef.current!.value,
        singleEntryPolicy: surveySingleEntryPolicyRef.current!.checked,
        questionnaireType: survey!.questionnaireType,
      })
      .then(updateSurveyToken);
  }, [api.surveys, survey, surveyId, updateSurveyToken]);

  const elementCount = useMemo(() => {
    return pages?.reduce((count, page) => count + page.elements, 0) ?? 0;
  }, [pages]);

  useEffect(() => {
    const int = window.setInterval(updateView, 5000);
    return () => window.clearInterval(int);
  }, [updateView]);

  const compareTime = useCallback((now: Date, then: Date): string => {
    if (differenceInWeeks(now, then)) return then.toLocaleDateString();
    if (differenceInDays(now, then) > 1) return `${differenceInDays(now, then)} dagen geleden`;
    if (differenceInHours(now, then) > 1) return `${differenceInHours(now, then)} uur geleden`;
    if (differenceInMinutes(now, then) > 1)
      return `${differenceInMinutes(now, then)} minuten geleden`;
    return "Zojuist";
  }, []);

  return (
    <Grid templateRows="auto 1fr" h="100vh" maxH="100vh" overflow="hidden">
      <Flex
        w="100vw"
        justifyContent={"space-between"}
        p={4}
        borderBottom="1px solid"
        borderColor="brand.grey.300"
        bg="brand.grey.100"
      >
        <Box>
          <Link to="/">
            <Image src="/tim-logo.svg" w="80px" h="80px" />
          </Link>
        </Box>
        <Box>
          <Text fontWeight={"bold"}>Naam</Text>
          <Input ref={surveyNameInputRef} onBlur={updateSurvey} />
        </Box>
        <Box>
          <ManageDataSourcesContextProvider>
            <ManageDataSourcesModal onUpdate={updateSurveyToken} />
          </ManageDataSourcesContextProvider>
          <Text>
            <Switch
              mr={2}
              ref={surveySingleEntryPolicyRef}
              isChecked={survey?.singleEntryPolicy}
              onChange={updateSurvey}
            />
            Eén keer invullen
          </Text>
        </Box>
        <Box>
          <Text fontWeight={"bold"}>Laatste wijziging</Text>
          <Text lineHeight={8}>
            {survey?.updatedDateTime && survey.updatedDateTime !== "0001-01-01T00:00:00"
              ? compareTime(new Date(), new Date(survey.updatedDateTime))
              : "-"}
          </Text>
        </Box>
        <Box>
          <Text fontWeight={"bold"}>Status</Text>
          <StatusDropdown />
        </Box>
        <Box>
          <div>{pages?.length} pagina&apos;s</div>
          <div>{elementCount} vragen</div>
        </Box>
      </Flex>
      <Grid templateColumns="auto 1fr" w="100%" h="100%" mt="0 !important" overflow="hidden">
        <PageNavigation
          updateSurvey={updateSurveyToken}
          surveyId={surveyId}
          pages={pages ?? []}
        />
        <Box overflowY="auto">
          <SurveyBody />
        </Box>
      </Grid>
    </Grid>
  );
};
