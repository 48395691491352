import { RadioGroup, Stack, Radio, Text } from "@chakra-ui/react";
import { useCallback, useContext } from "react";
import { ManageDataSourcesContext, Pages } from "./context";

const options = [
  { value: Pages.StartAddSep, label: "Middels het Stakeholder Engagement Platform" },
  { value: Pages.StartAddCustom, label: "Handmatig instellen" },
];

export const StartAdd = () => {
  const { state, setState } = useContext(ManageDataSourcesContext);

  const updateValue = useCallback((s: Pages) => {
    setState(c => ({ ...c, nextPage: s, selectedTemplate: s, previousText: "Databronnen" }));
  }, [setState])

  return (
    <>
      <Text my={2}>Hoe wil je de databronnen toevoegen?</Text>
      <RadioGroup value={state.selectedTemplate} onChange={(s) => updateValue(Number(s) as Pages)}>
        <Stack>
          {options.map(({ value, label }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </>
  );
};
