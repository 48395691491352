import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { EditSurveyPage } from "./pages/EditSurvey";
import { LandingPage } from "./pages/Landing";
import { SurveyStateProvider } from "./SurveyContext";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme/theme";
import { FrameContextProvider } from "./FrameContext";
import { ResultsPage } from "./pages/Results";
import { ResultsSummary } from "./compositions/ResultsSummary";
import { ResponseList } from "./compositions/ResponseList";
import { SingleResult } from "./compositions/SingleResult";
import { ManageApiKeysPage } from "./pages/ManageApiKeys";

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <SurveyStateProvider>
          <FrameContextProvider>
            <div id="modal-el" />
            {/* <BaseStyles /> */}
            {/* <TokenStyles /> */}
            <Routes>
              <Route index element={<LandingPage />} />
              <Route path="/edit/:surveyId/page/:pageId" element={<EditSurveyPage />} />
              <Route path="results/:surveyId" element={<ResultsPage />}>
                <Route index element={<ResultsSummary />} />
                <Route path="responses">
                  <Route index element={<ResponseList />} />
                  <Route path=":respondentId" element={<SingleResult />} />
                </Route>
                <Route path="*" element={<Navigate to="" />} />
              </Route>
              <Route path="/keys" element={<ManageApiKeysPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </FrameContextProvider>
        </SurveyStateProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
