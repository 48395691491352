import type { ComponentStyleConfig } from "@chakra-ui/react";

export const TableTheme: ComponentStyleConfig = {
  variants: {
    selectable: {
      tbody: {
        tr: {
          borderTop: "1px solid",
          borderColor: "gray.100",
          _hover: {
            bg: "blue.600",
            color: "white",
          },
          "&.selected": {
            bg: "blue.500",
            color: "white",
          },
        },
      },
    },
    basic: {
      thead: {
        tr: {
          th: {
            borderBottom: "1px solid",
            borderColor: "gray.500",
            textTransform: "capitalize",
            fontWeight: "normal",
            py: 0.5,
            px: 2,
          },
        },
      },
      tbody: {
        tr: {
          borderBottom: "1px solid",
          borderColor: "gray.500",
          fontSize: "sm",
          td: {
            py: 0.5,
            px: 2,
          },
        },
      },
    },
  },
};
