import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Checkbox,
  ModalFooter,
  Text,
  CheckboxGroup,
  Stack,
} from "@chakra-ui/react";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useSurveyState } from "../../SurveyContext";
import { ApiKeyModel, UpdateApiKeyModel } from "../../api/ManagerApi";
import { PermissionOperation, PermissionTypeName } from "../../api/enums";

type Props = {
  onUpdate: (updateModel: UpdateApiKeyModel) => void;
  onCancel?: () => void;
  publicKey?: string;
};

export const UpdateApiKeyModal = ({ onUpdate, onCancel, publicKey }: Props) => {
  const { onClose } = useDisclosure();
  const { api } = useSurveyState();
  const [errors, setErrors] = useState<{ [K in keyof UpdateApiKeyModel]?: string[] }>({});
  const [apiKey, setApiKey] = useState<ApiKeyModel>();
  const [checked, setChecked] = useState<string[]>([]);
  const [displayName, setDisplayName] = useState<string>();

  useEffect(() => {
    if (!publicKey) return setApiKey(undefined);

    const cancelToken = Symbol("Request.GetApiKey");

    if (publicKey) {
      api.apikeys.getApiKey(publicKey, { cancelToken }).then((r) => setApiKey(r.data));
    }

    return () => api.abortRequest(cancelToken);
  }, [publicKey, api]);

  const defaultValue = useMemo(
    () => apiKey?.permissions.map((p) => `${p.operation}.${p.typeName}`) ?? [],
    [apiKey?.permissions]
  );

  const update = useCallback(() => {
    const updateModel: UpdateApiKeyModel = {
      displayName: displayName ?? apiKey!.displayName,
      permissions: checked.map((v) => {
        const [operation, typeName] = v.split(".") as [`${PermissionOperation}`, PermissionTypeName];
        return {
          operation: +operation as PermissionOperation,
          typeName,
        };
      }),
    };
    onUpdate(updateModel);
    onClose();
  }, [onUpdate, onClose, checked, apiKey, displayName]);

  const cancel = useCallback(() => {
    onCancel?.();
    onClose();
  }, [onCancel, onClose]);

  const clearError = useCallback((name: keyof UpdateApiKeyModel) => {
    setErrors((e) => ({ ...e, [name]: undefined }));
  }, []);

  const changed = useMemo(
    () =>
      Boolean(
        apiKey?.displayName !== displayName ||
          checked.length !== defaultValue.length ||
          checked.some((v) => !defaultValue.includes(v))
      ),
    [apiKey, checked, displayName, defaultValue]
  );

  return (
    <>
      <Modal isOpen={!!apiKey} onClose={cancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Api key bewerken</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Wat is de naam van de Api Key?</Text>
            <FormControl variant="floating" isInvalid={Boolean(errors.displayName?.length)}>
              <FormLabel>
                <Text fontSize="md">Naam</Text>
              </FormLabel>
              <Input
                required
                defaultValue={apiKey?.displayName}
                onClick={(e) => e.currentTarget.select()}
                onChange={(event) => {
                  setDisplayName(event.currentTarget.value);
                  clearError("displayName");
                }}
              />
              {errors.displayName?.map((e) => <FormErrorMessage key={e}>{e}</FormErrorMessage>)}
            </FormControl>
            {apiKey?.permissions ? (
              <CheckboxGroup
                onChange={(values: string[]) => setChecked(values)}
                defaultValue={defaultValue}
              >
                <Stack mt={4}>
                  <Checkbox
                    value={`${PermissionOperation.READ}.${PermissionTypeName.Questionnaire}`}
                  >
                    Read Surveys
                  </Checkbox>
                  {!apiKey.public && (
                    <>
                      <Checkbox
                        value={`${PermissionOperation.CREATE}.${PermissionTypeName.Questionnaire}`}
                      >
                        Create Surveys
                      </Checkbox>
                      <Checkbox
                        value={`${PermissionOperation.UPDATE}.${PermissionTypeName.Questionnaire}`}
                      >
                        Update Surveys
                      </Checkbox>
                      <Checkbox
                        value={`${PermissionOperation.DELETE}.${PermissionTypeName.Questionnaire}`}
                      >
                        Delete Surveys
                      </Checkbox>
                    </>
                  )}

                  <Checkbox value={`${PermissionOperation.READ}.${PermissionTypeName.Response}`}>
                    Read Responses
                  </Checkbox>
                  <Checkbox value={`${PermissionOperation.CREATE}.${PermissionTypeName.Response}`}>
                    Create Responses
                  </Checkbox>
                  {!apiKey.public && (
                    <>
                      <Checkbox
                        value={`${PermissionOperation.UPDATE}.${PermissionTypeName.Response}`}
                      >
                        Update Responses
                      </Checkbox>
                      <Checkbox
                        value={`${PermissionOperation.DELETE}.${PermissionTypeName.Response}`}
                      >
                        Delete Responses
                      </Checkbox>

                      <Checkbox value={`${PermissionOperation.READ}.${PermissionTypeName.ApiKey}`}>
                        Read ApiKeys
                      </Checkbox>
                      <Checkbox
                        value={`${PermissionOperation.CREATE}.${PermissionTypeName.ApiKey}`}
                      >
                        Create ApiKeys
                      </Checkbox>
                      <Checkbox
                        value={`${PermissionOperation.UPDATE}.${PermissionTypeName.ApiKey}`}
                      >
                        Update ApiKeys
                      </Checkbox>
                      <Checkbox
                        value={`${PermissionOperation.DELETE}.${PermissionTypeName.ApiKey}`}
                      >
                        Delete ApiKeys
                      </Checkbox>
                    </>
                  )}
                </Stack>
              </CheckboxGroup>
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={cancel}>
              Annuleren
            </Button>
            <Button
              disabled={!changed}
              colorScheme={"blue"}
              onClick={update}
            >
              Bijwerken
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
