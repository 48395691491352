import { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import { ManageDataSourcesContext, Pages } from "./context";
import { useSurveyState } from "../../../SurveyContext";
import { useParams } from "react-router-dom";
import { DeleteIcon, ViewIcon, WarningIcon } from "@chakra-ui/icons";
import { Stack, Flex, Button, Text, VStack, StackDivider, Grid, Box } from "@chakra-ui/react";
import { isCreateLayerSource, isCreatePanoramaSource } from "../../../utils/apiUtils";
import { PanoramaType } from "../../../api/enums";
import { CreateDataSourceModel } from "../../../api/ManagerApi";
import { ConfirmModal } from "../ConfirmModal";
import { SimplePanoViewer } from "../../../components/SimplePanoViewer";
import { SimpleMapViewer } from "../../../components/SimpleMapViewer";

export const Confirm = () => {
  const { state, setState } = useContext(ManageDataSourcesContext);
  const { api } = useSurveyState();
  const surveyId = Number(useParams().surveyId);

  const editedNames = useMemo(() => new Map<CreateDataSourceModel, string>(), []);

  useEffect(() => {
    setState((c) => ({ ...c, nextPage: undefined }));

    return () => editedNames.clear();
  }, [setState, editedNames]);

  const doImport = useCallback(async () => {
    for (const source of state.sourcesToImport.splice(0)) {
      if (isCreatePanoramaSource(source)) source.imageUrl = encodeURI(source.imageUrl);
      if (isCreateLayerSource(source)) source.styleId = encodeURI(source.styleId);

      const response = await api.surveys.createDataSource(
        surveyId,
        editedNames.has(source) ? { ...source, name: editedNames.get(source)! } : source
      );
      if (response.ok) {
        setState((c) => ({
          ...c,
          sourcesToImport: c.sourcesToImport.filter((s) => s !== source),
          previousText: "Databronnen",
          previousPage: Pages.DataSourceList,
          sourcesUpdated: true,
        }));
        editedNames.delete(source);
      }
    }
  }, [state.sourcesToImport, api, surveyId, editedNames, setState]);

  const scaffoldEditedName = useCallback(
    (source: CreateDataSourceModel, name: string) => {
      const trimmed = name.trim();
      if (!trimmed || source.name === trimmed) {
        editedNames.delete(source);
        return;
      }
      editedNames.set(source, trimmed);
    },
    [editedNames]
  );

  const panoSources = useMemo(
    () => state.sourcesToImport.filter(isCreatePanoramaSource),
    [state.sourcesToImport]
  );
  const layerSources = useMemo(
    () => state.sourcesToImport.filter(isCreateLayerSource),
    [state.sourcesToImport]
  );

  return (
    <Stack>
      <Text my={2}>Bevestig de toe te voegen bronnen.</Text>
      <Text my={2}>Eventueel kun je hier nog namen aanpassen.</Text>
      {panoSources.length > 0 && (
        <Stack py={4}>
          <Text fontWeight="bold">Panorama's:</Text>
          {panoSources.map((dataSource, i) => (
            <Fragment key={`pano:${i}-${dataSource.name}`}>
              {i > 0 && <StackDivider borderColor="gray.300" borderStyle="solid" borderWidth={1} />}
              <Grid justifyContent="space-between" templateColumns="1fr auto" w="100%">
                <Stack overflow="hidden">
                  <Text>
                    <Text
                      onBlur={(e) => {
                        scaffoldEditedName(
                          dataSource,
                          e.currentTarget.textContent || dataSource.name
                        );
                        if (!editedNames.has(dataSource))
                          e.currentTarget.textContent = dataSource.name;
                      }}
                      contentEditable
                      suppressContentEditableWarning
                      role="textbox"
                      display="inline"
                      color="blue.500"
                    >
                      {dataSource.name}
                    </Text>{" "}
                    (<i>{PanoramaType[dataSource.panoType]}</i>)
                  </Text>
                  <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    title={decodeURI(dataSource.imageUrl)}
                    fontSize="xs"
                  >
                    {decodeURI(dataSource.imageUrl)}
                  </Text>
                </Stack>
                <VStack justifyContent="space-evenly">
                  <Button
                    variant="link"
                    color="red.500"
                    alignSelf="end"
                    onClick={() =>
                      setState((c) => ({
                        ...c,
                        sourcesToImport: c.sourcesToImport.filter((s) => s !== dataSource),
                      }))
                    }
                  >
                    <DeleteIcon mr={2} /> Verwijderen
                  </Button>
                  <ConfirmModal
                    modalProps={{ size: "4xl" }}
                    onConfirm={() => {
                      /* No action */
                    }}
                    buttonNode={<><ViewIcon mr={2} /> Bekijken</>}
                    buttonNodeProps={{ variant: "link", alignSelf: "end" }}
                    titleNode="Panorama voorvertoning"
                    bodyNode={
                      <Box h={"xl"}>
                        {dataSource ? (
                          <SimplePanoViewer source={dataSource} />
                        ) : (
                          <Text color="red.500">
                            <WarningIcon /> De panorama kon niet worden geladen
                          </Text>
                        )}
                      </Box>
                    }
                    cancelNode="Sluiten"
                    confirmNode={null}
                    primaryColor="blue"
                  />
                </VStack>
              </Grid>
            </Fragment>
          ))}
        </Stack>
      )}
      {layerSources.length > 0 && (
        <Stack py={4}>
          <Text fontWeight="bold">Kaartlagen:</Text>
          {layerSources.map((dataSource, i) => (
            <Fragment key={`layer:${i}-${dataSource.name}`}>
              {i > 0 && <StackDivider borderColor="gray.300" borderStyle="solid" borderWidth={1} />}
              <Flex justifyContent="space-between" w="100%">
                <Stack overflow="hidden">
                  <Text>
                    <Text
                      onBlur={(e) => {
                        scaffoldEditedName(
                          dataSource,
                          e.currentTarget.textContent || dataSource.name
                        );
                        if (!editedNames.has(dataSource))
                          e.currentTarget.textContent = dataSource.name;
                      }}
                      contentEditable
                      suppressContentEditableWarning
                      role="textbox"
                      display="inline"
                      color="blue.500"
                    >
                      {dataSource.name}
                    </Text>{" "}
                    (
                    <i>
                      {dataSource.clusters.length} cluster{dataSource.clusters.length !== 1 && "s"}
                    </i>
                    )
                  </Text>
                  <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    title={dataSource.clusters.join(", ")}
                    fontSize="xs"
                  >
                    {dataSource.styleId}
                    <br />
                    {dataSource.clusters.join(", ")}
                  </Text>
                </Stack>
                <VStack justifyContent="space-evenly">
                  <Button
                    variant="link"
                    color="red.500"
                    alignSelf="end"
                    onClick={() =>
                      setState((c) => ({
                        ...c,
                        sourcesToImport: c.sourcesToImport.filter((s) => s !== dataSource),
                      }))
                    }
                  >
                    <DeleteIcon mr={2} /> Verwijderen
                  </Button>
                  <ConfirmModal
                    modalProps={{ size: "4xl" }}
                    onConfirm={() => {
                      /* No action */
                    }}
                    buttonNode={
                      <>
                        <ViewIcon mr={2} /> Bekijken
                      </>
                    }
                    buttonNodeProps={{ variant: "link", alignSelf: "end" }}
                    titleNode="Kaartlaag voorvertoning"
                    bodyNode={
                      <Box h={"xl"}>
                        {dataSource ? (
                          <SimpleMapViewer source={dataSource} />
                        ) : (
                          <Text color="red.500">
                            <WarningIcon /> De kaart kon niet worden geladen
                          </Text>
                        )}
                      </Box>
                    }
                    cancelNode="Sluiten"
                    confirmNode={null}
                    primaryColor="blue"
                  />
                </VStack>
              </Flex>
            </Fragment>
          ))}
        </Stack>
      )}
      <Button isDisabled={!state.sourcesToImport.length} onClick={doImport}>
        Bevestigen en importeren
      </Button>
    </Stack>
  );
};
