import type { ComponentStyleConfig } from "@chakra-ui/react";

export const InputTheme: ComponentStyleConfig = {
  baseStyle: {
    _placeholder: {
      color: "gray.400",
    },
    field: {
      _focusVisible: {
        _focusVisible: {
          borderColor: "blue.500",
          boxShadow: `0px 0px 0px 1px var(--chakra-colors-brand-teal-500)`,
        },
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: "brand.grey.300",
        background: "white",
      },
    },
  },
};
