import type { ComponentStyleConfig } from '@chakra-ui/react';

export const PopoverTheme: ComponentStyleConfig = {
  baseStyle: {
    content: {
      border: 'none',
      borderRadius: 0,
    },
    popper: {
      boxShadow: 'lg',
    },
  },
};
