import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useSurveyState } from "../../SurveyContext";
import { useCallback, useState } from "react";
import { Secure } from "../../components/Secure";

export const LoginModal = () => {
  const { login, logout, isAuthenticated } = useSurveyState();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: !isAuthenticated });
  const [apiKey, setApiKey] = useState("");

  const performLogin = useCallback(() => {
    login(apiKey);
    onClose();
  }, [login, onClose, apiKey]);

  return (
    <>
      <Secure
        message={
          <Button onClick={onOpen} mr={8} ml="auto" variant="outline" color="white">
            Authenticeren
          </Button>
        }
      >
        <Button onClick={logout} mr={8} ml="auto" variant="outline" color="white">
          Uitloggen
        </Button>
      </Secure>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inloggen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl variant="floating">
              <FormLabel>
                <Text fontSize="md">Api Key</Text>
              </FormLabel>
              <Input required value={apiKey} onChange={(e) => setApiKey(e.currentTarget.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Annuleren
            </Button>
            <Button onClick={performLogin}>Authenticeren</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
