import { Box, Button, VStack } from "@chakra-ui/react";
import { useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageListItemModel } from "../api/ManagerApi";
import { useSurveyState } from "../SurveyContext";

type Props = {
  surveyId: number;
  pages: PageListItemModel[];
  updateSurvey: () => void;
};

export const PageNavigation = ({ surveyId, pages, updateSurvey }: Props) => {
  const navigate = useNavigate();
  const surveyPageId = Number(useParams().pageId);
  const { api } = useSurveyState();

  const addPage = useCallback(() => {
    if (!pages) return;
    api.surveys
      .createPage(surveyId, {
        title: "Nieuwe pagina",
        description: "",
        pageNumber: Number(pages?.length),
      })
      .then((response) => {
        updateSurvey();
        navigate(`/edit/${surveyId}/page/${response.data}`)
      });
  }, [api.surveys, navigate, surveyId, pages, updateSurvey]);

  return (
    <VStack w={"15rem"} borderRight="1px solid" borderColor="brand.grey.300" bg="brand.grey.100" overflowY="auto">
      {pages.map((page) => (
        <Link
          to={`/edit/${surveyId}/page/${page.id}`}
          key={page.id}
          style={{ width: "100%", padding: "0.5rem 0.5rem 0 0.5rem" }}
        >
          <Box
            borderStyle="solid"
            borderWidth="1px"
            borderColor={surveyPageId === page.id ? "blue.500" : "brand.grey.300"}
            borderRadius={3}
            h="6rem"
            p={3}
            w="100%"
            bg={surveyPageId === page.id ? "blue.100" : "white"}
          >
            {page.title}
          </Box>
        </Link>
      ))}
      <Button onClick={addPage} colorScheme="blue" flexShrink={0}>
        Pagina toevoegen
      </Button>
    </VStack>
  );
};
