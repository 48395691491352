type Props = {
  selected: boolean;
  color?: string;
};

export const DefaultMarker = ({ selected, color }: Props) => {
  return (
    <svg
      viewBox="8 0 32 49"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <ellipse id="b" cx="16" cy="42.091" rx="11" ry="2.909" />
        <filter
          x="-31.8%"
          y="-85.9%"
          width="163.6%"
          height="340.6%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.0509803922 0 0 0 0 0.101960784 0 0 0 0 0.396078431 0 0 0 0.2 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>

      <g fill="none">
        {/** 1. Shadow */}
        <use filter="url(#a)" xlinkHref="#b" fill="#000" />

        {/** 2. Shape blue / border */}
        <path
          d="M16 1c4.142 0 7.892 1.679 10.607 4.393A14.953 14.953 0 0131 16c0 5.623-5.091 13.939-15 25C6.09 29.938 1 21.622 1 16c0-4.142 1.679-7.892 4.393-10.607A14.953 14.953 0 0116 1z"
          stroke="#FFF"
          strokeWidth="2"
          fill={color}
        />

        {/** 3. Icon dot */}
        <g>
          <path d="M8 8h15.515v15.515H8z" />
          <path
            d="M15.995 9.33a6.667 6.667 0 00-6.665 6.665 6.667 6.667 0 006.665 6.665 6.667 6.667 0 006.665-6.665 6.667 6.667 0 00-6.665-6.665z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </g>

        {/**  4. Icon question */}
        {/**  <g>
      <path d="M8 8h16v16H8z"/>
      <path d="M16 9.333A6.67 6.67 0 009.333 16 6.67 6.67 0 0016 22.667 6.67 6.67 0 0022.667 16 6.67 6.67 0 0016 9.333zm.667 11.334h-1.334v-1.334h1.334v1.334zm1.38-5.167l-.6.613c-.48.487-.78.887-.78 1.887h-1.334v-.333c0-.734.3-1.4.78-1.887l.827-.84c.247-.24.393-.573.393-.94 0-.733-.6-1.333-1.333-1.333s-1.333.6-1.333 1.333h-1.334a2.666 2.666 0 115.334 0c0 .587-.24 1.12-.62 1.5z" fill="#FFF" fill-rule="nonzero"/>
    </g> */}
      </g>
    </svg>
  );
};
