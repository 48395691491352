import { formAnatomy } from "@chakra-ui/anatomy";
import type { ComponentStyleConfig } from "@chakra-ui/react";

const activeLabelStyles = {
  display: "flex",
  transform: "scale(0.80) translateY(-22px) translateX(-10px)",
  color: "black",
};
export const FormTheme: ComponentStyleConfig = {
  parts: formAnatomy.keys,
  baseStyle: {},
  variants: {
    floating: {
      container: {
        mt: 4,
        label: {
          ...activeLabelStyles,
          top: 0,
          left: 0,
          zIndex: 2,
          fontSize: "1rem",
          position: "absolute",
          backgroundColor: "white",
          pointerEvents: "none",
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: "left top",
        },
      },
    },
    floatinggray: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, div > .float + label":
          {
            ...activeLabelStyles,
          },
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          fontSize: "1rem",
          position: "absolute",
          backgroundColor: "gray.100",
          color: "gray.400",
          pointerEvents: "none",
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: "left top",
        },
      },
    },
  },
};
