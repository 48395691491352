/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AnswerModel {
  /** @format int32 */
  id: number;
  /** @format int32 */
  questionId: number;
  type: string;
}

export interface ApiKeyListItemModel {
  displayName: string;
  /** @format uuid */
  publicKey: string;
  public: boolean;
  /** @format int32 */
  permissions: number;
  /** @format date-time */
  created: string;
  /** @format date-time */
  lastAccess: string;
  isRootKey: boolean;
}

export interface ApiKeyModel {
  displayName: string;
  /** @format uuid */
  publicKey: string;
  public: boolean;
  permissions: PermissionListItemModel[];
  /** @format date-time */
  created: string;
  /** @format date-time */
  lastAccess: string;
}

export type BooleanAnswerModel = UtilRequiredKeys<AnswerModel, "type"> & {
  type: string;
  bool: boolean;
};

export type BooleanQuestionModel = UtilRequiredKeys<QuestionModel, "type"> & {
  type: string;
  label: string;
  defaultValue: boolean;
};

export interface BoundaryModel {
  /** @format double */
  north: number;
  /** @format double */
  east: number;
  /** @format double */
  south: number;
  /** @format double */
  west: number;
}

export type ChoiceAnswerModel = UtilRequiredKeys<AnswerModel, "type"> & {
  type: string;
  choices: ChoiceModel[];
};

export interface ChoiceModel {
  value: string;
  selected: boolean;
}

export type ChoiceQuestionModel = UtilRequiredKeys<QuestionModel, "type"> & {
  type: string;
  options: StringOptionModel[];
  allowMultiple: boolean;
  isDropdown: boolean;
  defaultValue?: string | null;
};

export interface CreateApiKeyModel {
  displayName: string;
  public: boolean;
  permissions: PermissionAssignmentModel[];
}

export type CreateBoundaryModel = BoundaryModel;

export interface CreateDataSourceModel {
  /** @minLength 1 */
  name: string;
  type: string;
}

export interface CreateElementModel {
  type: string;
  /**
   * @format int32
   * @min 0
   * @exclusiveMin true
   */
  pageId: number;
  /**
   * @format int32
   * @min 0
   */
  index?: number | null;
}

export type CreateLayerSourceModel = UtilRequiredKeys<CreateDataSourceModel, "type"> & {
  /** @minLength 1 */
  styleId: string;
  /** @minLength 1 */
  accessToken: string;
  clusters: string[];
  bounds: CreateBoundaryModel;
  type: string;
};

export interface CreatePageModel {
  /**
   * @minLength 1
   * @maxLength 100
   */
  title: string;
  /** @maxLength 10000 */
  description: string;
  /** @format int32 */
  pageNumber?: number | null;
}

export type CreatePanoramaSourceModel = UtilRequiredKeys<CreateDataSourceModel, "type"> & {
  panoType: PanoramaType;
  /** @format uri */
  imageUrl: string;
  /** @minItems 1 */
  levels: number[];
  type: string;
};

export interface CreatePinDefinitionModel {
  icon?: string | null;
  label: string;
  color?: string | null;
  /**
   * @format int32
   * @min 0
   */
  minAmount: number;
  /** @format int32 */
  maxAmount?: number | null;
  allowComment: boolean;
}

export interface CreateQuestionnaireModel {
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  questionnaireType: QuestionnaireType;
}

export interface CreateRestrictionLayerModel {
  restrictionType: RestrictionType;
  /** @minLength 1 */
  message: string;
  /** @minItems 1 */
  cluster: string[];
}

export interface DataSourceModel {
  /** @format int32 */
  id: number;
  name: string;
  type: string;
}

export interface DoubleOptionModel {
  /** @format double */
  value: number;
  label?: string | null;
}

export interface ElementListItemModel {
  type: string;
  /** @format int32 */
  id: number;
}

export type ElementModel = UtilRequiredKeys<ElementListItemModel, "type"> & {
  title: string;
  content: string;
  type: string;
};

export interface FileModel {
  /** @format int32 */
  id: number;
  altText: string;
  /** @format uri */
  url: string;
  name: string;
  mimeType: string;
}

export type FileUploadAnswerModel = UtilRequiredKeys<AnswerModel, "type"> & {
  files: FileModel[];
  type: string;
};

export type FileUploadQuestionModel = UtilRequiredKeys<QuestionModel, "type"> & {
  /** @format int32 */
  maxAmount: number;
  /** @format int64 */
  maxFileSize: number;
  allowedTypes: string[];
  type: string;
};

export type GeoLocationPinModel = UtilRequiredKeys<PinModel, "type"> & {
  /** @format int32 */
  layerId: number;
  type: string;
};

export type LayerSourceModel = UtilRequiredKeys<DataSourceModel, "type"> & {
  styleId: string;
  accessToken: string;
  clusters: string[];
  bounds: BoundaryModel | CreateBoundaryModel;
  type: string;
};

export type LocationPickerAnswerModel = UtilRequiredKeys<AnswerModel, "type"> & {
  pins: (PinModel | GeoLocationPinModel | PanoLocationPinModel)[];
  type: string;
};

export type LocationPickerQuestionModel = UtilRequiredKeys<QuestionModel, "type"> & {
  pins: PinDefinitionModel[];
  type: string;
  restrictions: RestrictionLayerModel[];
};

/** @format int32 */
export type Operation = 0 | 1 | 2 | 3 | 4;

export interface PageListItemModel {
  /** @format int32 */
  id: number;
  title: string;
  description: string;
  /** @format int32 */
  elements: number;
  hasDataConnection: boolean;
}

export interface PageModel {
  /** @format int32 */
  id: number;
  title: string;
  description: string;
  elements: (
    | ElementModel
    | QuestionModel
    | BooleanQuestionModel
    | ChoiceQuestionModel
    | FileUploadQuestionModel
    | LocationPickerQuestionModel
    | RatingQuestionModel
    | TextQuestionModel
    | TrackingQuestionModel
  )[];
  /** @format int32 */
  dataSourceId?: number | null;
}

export type PanoLocationPinModel = UtilRequiredKeys<PinModel, "type"> & {
  /** @format int32 */
  panoId: number;
  type: string;
};

export type PanoramaSourceModel = UtilRequiredKeys<DataSourceModel, "type"> & {
  panoType: PanoramaType;
  /** @format uri */
  imageUrl: string;
  levels: number[];
  type: string;
};

/** @format int32 */
export type PanoramaType = 0 | 1;

export interface PermissionAssignmentModel {
  operation: Operation;
  typeName: string;
  /** @format int32 */
  resourceId?: number | null;
}

export interface PermissionListItemModel {
  operation: Operation;
  typeName: string;
  /** @format int32 */
  resourceId?: number | null;
}

export interface PinDefinitionModel {
  /** @format int32 */
  id: number;
  icon: string;
  label: string;
  color?: string | null;
  /** @format int32 */
  minAmount: number;
  /** @format int32 */
  maxAmount?: number | null;
  allowComment: boolean;
}

export interface PinModel {
  /** @format int32 */
  pinDefinition: number;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  /** @format int32 */
  scenarioId: number;
  comment: string;
  type: string;
}

export interface PrivateApiKeyModel {
  /** @format uuid */
  publicKey: string;
  privateKey: string;
}

export type QuestionModel = UtilRequiredKeys<ElementModel, "type"> & {
  required: boolean;
  type: string;
};

export interface QuestionnaireListItemModel {
  /** @format int32 */
  id: number;
  name: string;
  state: SurveyState;
  /** @format int32 */
  responses: number;
  questionnaireType: QuestionnaireType;
  /** @format date-time */
  createdDateTime: string;
  /** @format date-time */
  updatedDateTime: string;
}

export interface QuestionnaireModel {
  /** @format int32 */
  id: number;
  name: string;
  singleEntryPolicy: boolean;
  state: SurveyState;
  pages: PageModel[];
  responses: (ResponseListItemModel | ResponseModel)[];
  questionnaireType: QuestionnaireType;
  /** @format date-time */
  createdDateTime: string;
  /** @format date-time */
  updatedDateTime: string;
  dataSources: (DataSourceModel | LayerSourceModel | PanoramaSourceModel)[];
}

/** @format int32 */
export type QuestionnaireType = 0 | 1;

export type RatingAnswerModel = UtilRequiredKeys<AnswerModel, "type"> & {
  type: string;
  /** @format double */
  rating: number;
};

export type RatingQuestionModel = UtilRequiredKeys<QuestionModel, "type"> & {
  type: string;
  options: DoubleOptionModel[];
  /** @format double */
  defaultValue?: number | null;
};

export interface ResponseListItemModel {
  /** @format int32 */
  id: number;
  /** @format date-time */
  submissionDateTime: string;
}

export type ResponseModel = ResponseListItemModel & {
  answers: (
    | AnswerModel
    | BooleanAnswerModel
    | ChoiceAnswerModel
    | FileUploadAnswerModel
    | LocationPickerAnswerModel
    | RatingAnswerModel
    | TextAnswerModel
    | TrackingAnswerModel
  )[];
};

export interface RestrictionLayerModel {
  /** @format int32 */
  id: number;
  pins: number[];
  cluster: string[];
  restrictionType: RestrictionType;
  message: string;
}

/** @format int32 */
export type RestrictionType = 0 | 1;

export interface StringOptionModel {
  value: string;
  label?: string | null;
}

/** @format int32 */
export type SurveyState = 0 | 1;

export type TextAnswerModel = UtilRequiredKeys<AnswerModel, "type"> & {
  text: string;
  type: string;
};

export type TextQuestionModel = UtilRequiredKeys<QuestionModel, "type"> & {
  subType: string;
  /** @format int32 */
  minLength?: number | null;
  /** @format int32 */
  maxLength?: number | null;
  type: string;
};

export type TrackingAnswerModel = UtilRequiredKeys<AnswerModel, "type"> & {
  value: string;
  type: string;
};

export type TrackingQuestionModel = UtilRequiredKeys<QuestionModel, "type"> & {
  trackingType: TrackingType;
  propertyName: string;
  messageForNoValue: string;
  type: string;
};

/** @format int32 */
export type TrackingType = 0 | 1 | 2 | 3 | 4;

export interface UpdateApiKeyModel {
  displayName: string;
  permissions: PermissionAssignmentModel[];
}

export type UpdateBooleanQuestionModel = UtilRequiredKeys<UpdateQuestionModel, "type"> & {
  type: string;
  /** @maxLength 1000 */
  label: string;
  defaultValue: boolean;
};

export type UpdateChoiceQuestionModel = UtilRequiredKeys<UpdateQuestionModel, "type"> & {
  type: string;
  /** @minItems 1 */
  options: StringOptionModel[];
  allowMultiple: boolean;
  isDropdown: boolean;
  defaultValue?: string | null;
};

export interface UpdateElementModel {
  type: string;
  /**
   * @format int32
   * @min 0
   * @exclusiveMin true
   */
  pageId: number;
  /** @format int32 */
  index?: number | null;
  /** @maxLength 200 */
  title: string;
  /** @maxLength 1000 */
  content: string;
}

export type UpdateFileUploadQuestionModel = UtilRequiredKeys<UpdateQuestionModel, "type"> & {
  /**
   * @format int32
   * @min 1
   * @max 10
   */
  maxAmount: number;
  /**
   * @format int64
   * @max 52428800
   */
  maxFileSize: number;
  /** @minItems 1 */
  allowedTypes: string[];
  type: string;
};

export type UpdateLocationPickerQuestionModel = UtilRequiredKeys<UpdateQuestionModel, "type"> & {
  type: string;
};

export type UpdatePageModel = CreatePageModel & {
  /** @format int32 */
  dataSourceId?: number | null;
  force: boolean;
};

export type UpdatePinDefinitionModel = CreatePinDefinitionModel;

export type UpdateQuestionModel = UtilRequiredKeys<UpdateElementModel, "type"> & {
  type: string;
  required: boolean;
};

export type UpdateQuestionnaireModel = CreateQuestionnaireModel & {
  singleEntryPolicy: boolean;
};

export type UpdateRatingQuestionModel = UtilRequiredKeys<UpdateQuestionModel, "type"> & {
  type: string;
  /** @minItems 1 */
  options: DoubleOptionModel[];
  /** @format double */
  defaultValue?: number | null;
};

export type UpdateRestrictionLayerModel = CreateRestrictionLayerModel & {
  pins: number[];
};

export type UpdateTextQuestionModel = UtilRequiredKeys<UpdateQuestionModel, "type"> & {
  type: string;
  subType: string;
  /** @format int32 */
  maxLength?: number | null;
  /** @format int32 */
  minLength?: number | null;
};

export type UpdateTrackingQuestionModel = UtilRequiredKeys<UpdateQuestionModel, "type"> & {
  type: string;
  trackingType: TrackingType;
  /** @minLength 1 */
  propertyName: string;
  messageForNoValue: string;
};

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title TIM Survey Manager API
 * @version v1
 */
export class ManagerApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  surveys = {
    /**
     * No description
     *
     * @tags Answers
     * @name ListAnswers
     * @summary Retrieves a list of all answers belonging to a survey.
     * @request GET:/surveys/{surveyId}/answers
     * @secure
     */
    listAnswers: (surveyId: number, params: RequestParams = {}) =>
      this.request<
        (
          | AnswerModel
          | BooleanAnswerModel
          | ChoiceAnswerModel
          | FileUploadAnswerModel
          | LocationPickerAnswerModel
          | RatingAnswerModel
          | TextAnswerModel
          | TrackingAnswerModel
        )[],
        void
      >({
        path: `/surveys/${surveyId}/answers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Answers
     * @name ListAnswersForSurveyPage
     * @summary Retrieves a list of all answers belonging to a specific page within a survey.
     * @request GET:/surveys/{surveyId}/pages/{pageId}/answers
     * @secure
     */
    listAnswersForSurveyPage: (surveyId: number, pageId: number, params: RequestParams = {}) =>
      this.request<
        (
          | AnswerModel
          | BooleanAnswerModel
          | ChoiceAnswerModel
          | FileUploadAnswerModel
          | LocationPickerAnswerModel
          | RatingAnswerModel
          | TextAnswerModel
          | TrackingAnswerModel
        )[],
        void
      >({
        path: `/surveys/${surveyId}/pages/${pageId}/answers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Answers
     * @name ListAnswersForSurveyQuestion
     * @summary Retrieves a list of answers belonging to a specific question.
     * @request GET:/surveys/{surveyId}/questions/{questionId}/answers
     * @secure
     */
    listAnswersForSurveyQuestion: (surveyId: number, questionId: number, params: RequestParams = {}) =>
      this.request<
        (
          | AnswerModel
          | BooleanAnswerModel
          | ChoiceAnswerModel
          | FileUploadAnswerModel
          | LocationPickerAnswerModel
          | RatingAnswerModel
          | TextAnswerModel
          | TrackingAnswerModel
        )[],
        void
      >({
        path: `/surveys/${surveyId}/questions/${questionId}/answers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Answers
     * @name GetAnswer
     * @summary Retrieves a specific answer.
     * @request GET:/surveys/{surveyId}/answers/{answerId}
     * @secure
     */
    getAnswer: (surveyId: number, answerId: number, params: RequestParams = {}) =>
      this.request<
        | AnswerModel
        | BooleanAnswerModel
        | ChoiceAnswerModel
        | FileUploadAnswerModel
        | LocationPickerAnswerModel
        | RatingAnswerModel
        | TextAnswerModel
        | TrackingAnswerModel,
        void
      >({
        path: `/surveys/${surveyId}/answers/${answerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataSources
     * @name ListDataSources
     * @summary Retrieves the registered data sources for the given survey.
     * @request GET:/surveys/{surveyId}/datasources
     * @secure
     */
    listDataSources: (surveyId: number, params: RequestParams = {}) =>
      this.request<(DataSourceModel | LayerSourceModel | PanoramaSourceModel)[], void>({
        path: `/surveys/${surveyId}/datasources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataSources
     * @name CreateDataSource
     * @summary Creates a new datasource and assigns it to the given survey.
     * @request POST:/surveys/{surveyId}/datasources
     * @secure
     */
    createDataSource: (
      surveyId: number,
      data: CreateDataSourceModel | CreateLayerSourceModel | CreatePanoramaSourceModel,
      params: RequestParams = {},
    ) =>
      this.request<number, void>({
        path: `/surveys/${surveyId}/datasources`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataSources
     * @name GetDataSource
     * @summary Retrieves a specific datasource.
     * @request GET:/surveys/{surveyId}/datasources/{dataSourceId}
     * @secure
     */
    getDataSource: (surveyId: number, dataSourceId: number, params: RequestParams = {}) =>
      this.request<DataSourceModel | LayerSourceModel | PanoramaSourceModel, void>({
        path: `/surveys/${surveyId}/datasources/${dataSourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataSources
     * @name DeleteDataSource
     * @summary Deletes the given datasource.
     * @request DELETE:/surveys/{surveyId}/datasources/{dataSourceId}
     * @secure
     */
    deleteDataSource: (surveyId: number, dataSourceId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/datasources/${dataSourceId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name ListQuestionsFromSurvey
     * @summary Lists all Questions contained within the given Survey
     * @request GET:/surveys/{surveyId}/elements/questions
     * @secure
     */
    listQuestionsFromSurvey: (surveyId: number, params: RequestParams = {}) =>
      this.request<
        (
          | ElementModel
          | QuestionModel
          | BooleanQuestionModel
          | ChoiceQuestionModel
          | FileUploadQuestionModel
          | LocationPickerQuestionModel
          | RatingQuestionModel
          | TextQuestionModel
          | TrackingQuestionModel
        )[],
        void
      >({
        path: `/surveys/${surveyId}/elements/questions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name ListElementsFromSurveyPage
     * @summary Lists all Elements from the given page in the Survey.
     * @request GET:/surveys/{surveyId}/pages/{pageId}/elements
     * @secure
     */
    listElementsFromSurveyPage: (surveyId: number, pageId: number, params: RequestParams = {}) =>
      this.request<
        (
          | ElementModel
          | QuestionModel
          | BooleanQuestionModel
          | ChoiceQuestionModel
          | FileUploadQuestionModel
          | LocationPickerQuestionModel
          | RatingQuestionModel
          | TextQuestionModel
          | TrackingQuestionModel
        )[],
        void
      >({
        path: `/surveys/${surveyId}/pages/${pageId}/elements`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name GetElement
     * @summary Retrieves the data of a specific Element in a Survey
     * @request GET:/surveys/{surveyId}/elements/{elementId}
     * @secure
     */
    getElement: (surveyId: number, elementId: number, params: RequestParams = {}) =>
      this.request<
        | ElementModel
        | QuestionModel
        | BooleanQuestionModel
        | ChoiceQuestionModel
        | FileUploadQuestionModel
        | LocationPickerQuestionModel
        | RatingQuestionModel
        | TextQuestionModel
        | TrackingQuestionModel,
        void
      >({
        path: `/surveys/${surveyId}/elements/${elementId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name UpdateElement
     * @summary Updates an Element or a Question
     * @request PUT:/surveys/{surveyId}/elements/{elementId}
     * @secure
     */
    updateElement: (
      surveyId: number,
      elementId: number,
      data:
        | UpdateElementModel
        | UpdateBooleanQuestionModel
        | UpdateChoiceQuestionModel
        | UpdateFileUploadQuestionModel
        | UpdateLocationPickerQuestionModel
        | UpdateQuestionModel
        | UpdateRatingQuestionModel
        | UpdateTextQuestionModel
        | UpdateTrackingQuestionModel,
      params: RequestParams = {},
    ) =>
      this.request<number, void>({
        path: `/surveys/${surveyId}/elements/${elementId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name DeleteElement
     * @summary Removes the given Element or Question from the survey
     * @request DELETE:/surveys/{surveyId}/elements/{elementId}
     * @secure
     */
    deleteElement: (surveyId: number, elementId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/elements/${elementId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name CreateElement
     * @summary Creates a new Element or Question.
     * @request POST:/surveys/{surveyId}/elements
     * @secure
     */
    createElement: (surveyId: number, data: CreateElementModel, params: RequestParams = {}) =>
      this.request<number, void>({
        path: `/surveys/${surveyId}/elements`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name ListPages
     * @summary Retrieves a list of Pages related to the given Survey.
     * @request GET:/surveys/{surveyId}/pages
     * @secure
     */
    listPages: (surveyId: number, params: RequestParams = {}) =>
      this.request<PageListItemModel[], void>({
        path: `/surveys/${surveyId}/pages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name CreatePage
     * @summary Creates a Page.
     * @request POST:/surveys/{surveyId}/pages
     * @secure
     */
    createPage: (surveyId: number, data: CreatePageModel | UpdatePageModel, params: RequestParams = {}) =>
      this.request<number, void>({
        path: `/surveys/${surveyId}/pages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetPage
     * @summary Retrieves a Page.
     * @request GET:/surveys/{surveyId}/pages/{pageId}
     * @secure
     */
    getPage: (surveyId: number, pageId: number, params: RequestParams = {}) =>
      this.request<PageModel, void>({
        path: `/surveys/${surveyId}/pages/${pageId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name UpdatePage
     * @summary Updates a page.
     * @request PUT:/surveys/{surveyId}/pages/{pageId}
     * @secure
     */
    updatePage: (surveyId: number, pageId: number, data: UpdatePageModel, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/pages/${pageId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name DeletePage
     * @summary Deletes a page including the Elements it contains.
     * @request DELETE:/surveys/{surveyId}/pages/{pageId}
     * @secure
     */
    deletePage: (surveyId: number, pageId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/pages/${pageId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name ListPinDefinitions
     * @summary Retrieves a list of pin definitions from a question.
     * @request GET:/surveys/{surveyId}/elements/{questionId}/pindefinitions
     * @secure
     */
    listPinDefinitions: (surveyId: number, questionId: number, params: RequestParams = {}) =>
      this.request<PinDefinitionModel[], void>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name CreatePinDefinition
     * @summary Creates a new pin definition and assigns it to the question.
     * @request POST:/surveys/{surveyId}/elements/{questionId}/pindefinitions
     * @secure
     */
    createPinDefinition: (
      surveyId: number,
      questionId: number,
      data: CreatePinDefinitionModel | UpdatePinDefinitionModel,
      params: RequestParams = {},
    ) =>
      this.request<number, void>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name GetPinDefinition
     * @summary Retrieve a specific pin definition from a question.
     * @request GET:/surveys/{surveyId}/elements/{questionId}/pindefinitions/{pinDefinitionId}
     * @secure
     */
    getPinDefinition: (surveyId: number, questionId: number, pinDefinitionId: number, params: RequestParams = {}) =>
      this.request<PinDefinitionModel, void>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions/${pinDefinitionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name UpdatePinDefinition
     * @summary Updates the given pin definition.
     * @request PUT:/surveys/{surveyId}/elements/{questionId}/pindefinitions/{pinDefinitionId}
     * @secure
     */
    updatePinDefinition: (
      surveyId: number,
      questionId: number,
      pinDefinitionId: number,
      data: UpdatePinDefinitionModel,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions/${pinDefinitionId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name DeletePinDefinition
     * @summary Deletes the pin definition.
     * @request DELETE:/surveys/{surveyId}/elements/{questionId}/pindefinitions/{pinDefinitionId}
     * @secure
     */
    deletePinDefinition: (surveyId: number, questionId: number, pinDefinitionId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions/${pinDefinitionId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Responses
     * @name ListResponses
     * @summary Retrieves a list of responses to a survey.
     * @request GET:/surveys/{surveyId}/responses
     * @secure
     */
    listResponses: (surveyId: number, params: RequestParams = {}) =>
      this.request<(ResponseListItemModel | ResponseModel)[], void>({
        path: `/surveys/${surveyId}/responses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Responses
     * @name GetResponse
     * @summary Retrieves a single response to a survey.
     * @request GET:/surveys/{surveyId}/responses/{responseId}
     * @secure
     */
    getResponse: (surveyId: number, responseId: number, params: RequestParams = {}) =>
      this.request<ResponseModel, void>({
        path: `/surveys/${surveyId}/responses/${responseId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Responses
     * @name DownloadResponse
     * @summary Generates a csv file of either a single response or all responses to a survey.
     * @request GET:/surveys/{surveyId}/responses/downloads/tall/{responseId}
     * @secure
     */
    downloadResponse: (surveyId: number, responseId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/responses/downloads/tall/${responseId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RestrictionLayers
     * @name ListRestrictionLayers
     * @summary Retrieves a list of restriction layers applied to a question.
     * @request GET:/surveys/{surveyId}/elements/{questionId}/restrictionlayers
     * @secure
     */
    listRestrictionLayers: (surveyId: number, questionId: number, params: RequestParams = {}) =>
      this.request<RestrictionLayerModel[], void>({
        path: `/surveys/${surveyId}/elements/${questionId}/restrictionlayers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RestrictionLayers
     * @name CreateRestrictionLayer
     * @summary Creates a restriction layer
     * @request POST:/surveys/{surveyId}/elements/{questionId}/restrictionlayers
     * @secure
     */
    createRestrictionLayer: (
      surveyId: number,
      questionId: number,
      data: CreateRestrictionLayerModel | UpdateRestrictionLayerModel,
      params: RequestParams = {},
    ) =>
      this.request<number, void>({
        path: `/surveys/${surveyId}/elements/${questionId}/restrictionlayers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RestrictionLayers
     * @name GetRestrictionLayer
     * @summary Retrieves a single restriction layer.
     * @request GET:/surveys/{surveyId}/elements/{questionId}/restrictionlayers/{restrictionLayerId}
     * @secure
     */
    getRestrictionLayer: (
      surveyId: number,
      questionId: number,
      restrictionLayerId: number,
      params: RequestParams = {},
    ) =>
      this.request<RestrictionLayerModel, void>({
        path: `/surveys/${surveyId}/elements/${questionId}/restrictionlayers/${restrictionLayerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RestrictionLayers
     * @name UpdateRestrictionLayer
     * @summary Updates a restriction layer
     * @request PUT:/surveys/{surveyId}/elements/{questionId}/restrictionlayers/{restrictionLayerId}
     * @secure
     */
    updateRestrictionLayer: (
      surveyId: number,
      questionId: number,
      restrictionLayerId: number,
      data: UpdateRestrictionLayerModel,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/elements/${questionId}/restrictionlayers/${restrictionLayerId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RestrictionLayers
     * @name DeleteRestrictionLayer
     * @summary Deletes a restriction layer
     * @request DELETE:/surveys/{surveyId}/elements/{questionId}/restrictionlayers/{restrictionLayerId}
     * @secure
     */
    deleteRestrictionLayer: (
      surveyId: number,
      questionId: number,
      restrictionLayerId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}/elements/${questionId}/restrictionlayers/${restrictionLayerId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name ListSurveys
     * @summary Retrieves a list of surveys.
     * @request GET:/surveys
     * @secure
     */
    listSurveys: (params: RequestParams = {}) =>
      this.request<QuestionnaireListItemModel[], any>({
        path: `/surveys`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name CreateSurvey
     * @summary Creates a new survey.
     * @request POST:/surveys
     * @secure
     */
    createSurvey: (data: CreateQuestionnaireModel | UpdateQuestionnaireModel, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/surveys`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name GetSurvey
     * @summary Retrieves a single survey.
     * @request GET:/surveys/{surveyId}
     * @secure
     */
    getSurvey: (surveyId: number, params: RequestParams = {}) =>
      this.request<QuestionnaireModel, void>({
        path: `/surveys/${surveyId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name UpdateSurvey
     * @summary Updates a survey.
     * @request PUT:/surveys/{surveyId}
     * @secure
     */
    updateSurvey: (surveyId: number, data: UpdateQuestionnaireModel, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name DeleteSurvey
     * @summary Deletes a survey.
     * @request DELETE:/surveys/{surveyId}
     * @secure
     */
    deleteSurvey: (surveyId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/surveys/${surveyId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  apikeys = {
    /**
     * @description This response does not contain the key itself, as those are treated like a password and cannot be recovered once stored.
     *
     * @tags ApiKeys
     * @name ListApiKeys
     * @summary Retrieves a list of Api key data.
     * @request GET:/apikeys
     * @secure
     */
    listApiKeys: (params: RequestParams = {}) =>
      this.request<ApiKeyListItemModel[], any>({
        path: `/apikeys`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description The public and private keys are returned, Private keys are only accessible as a result from a create or regenerate action.
     *
     * @tags ApiKeys
     * @name CreateApiKey
     * @summary Create a new Api key.
     * @request POST:/apikeys
     * @secure
     */
    createApiKey: (data: CreateApiKeyModel, params: RequestParams = {}) =>
      this.request<PrivateApiKeyModel, void>({
        path: `/apikeys`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This response does not contain the key itself, as those are treated like a password and cannot be recovered once stored.
     *
     * @tags ApiKeys
     * @name GetApiKey
     * @summary Retrieve the details of a specific Api key.
     * @request GET:/apikeys/{key}
     * @secure
     */
    getApiKey: (key: string, params: RequestParams = {}) =>
      this.request<ApiKeyModel, void>({
        path: `/apikeys/${key}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Both the public and private keys will be regenerated and returned, the old key will be invalidated in the process.
     *
     * @tags ApiKeys
     * @name RegenerateApiKey
     * @summary Recreates the given api key.
     * @request POST:/apikeys/{key}
     * @secure
     */
    regenerateApiKey: (key: string, params: RequestParams = {}) =>
      this.request<PrivateApiKeyModel, void>({
        path: `/apikeys/${key}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApiKeys
     * @name UpdateApiKey
     * @summary Update the details of the given Api key.
     * @request PUT:/apikeys/{key}
     * @secure
     */
    updateApiKey: (key: string, data: UpdateApiKeyModel, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/apikeys/${key}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ApiKeys
     * @name DeleteApiKey
     * @summary Delete an Api key.
     * @request DELETE:/apikeys/{key}
     * @secure
     */
    deleteApiKey: (key: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/apikeys/${key}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name GetFile
     * @summary Downloads the requested file
     * @request GET:/files/{fileId}
     * @secure
     */
    getFile: (fileId: number, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/files/${fileId}`,
        method: "GET",
        secure: true,
        format: "blob",
        ...params,
      }),
  };
}
