import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Flex, FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import { useSurveyState } from "../SurveyContext";
import usePromise from "../utils/usePromise";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UpdatePageModel } from "../api/ManagerApi";
import { useForceUpdate } from "../utils/useForceUpdate";
import { isLayerSource, isPanoramaSource } from "../utils/apiUtils";

export const PageOptions = () => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const { api, surveyToken, updateSurveyToken, pageToken } = useSurveyState();

  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => updateQuestionToken(), [pageToken, updateQuestionToken]);
  useEffect(() => setShowOptions(false), [surveyPageId]);

  const [survey] = usePromise(
    () => api.surveys.getSurvey(Number(surveyId)).then((r) => r.data),
    [surveyToken]
  );

  const [page] = usePromise(() => {
    return api.surveys.getPage(Number(surveyId), Number(surveyPageId)).then((r) => r.data);
  }, [pageToken, questionRefreshToken, surveyPageId]);

  const [pages] = usePromise(() => {
    return api.surveys.listPages(Number(surveyId)).then((r) => r.data);
  }, [surveyId, surveyPageId]);

  const pageNumber = useMemo(() => {
    return pages?.findIndex((p) => p.id === Number(surveyPageId)) ?? 0;
  }, [pages, surveyPageId]);

  const layergroupOptions = useMemo<{ id: number; title: string }[]>(
    () =>
      survey?.dataSources.filter(isLayerSource).map((d) => ({
        id: d.id,
        title: d.name,
      })) ?? [],
    [survey?.dataSources]
  );

  const selectedLayerId = useMemo(
    () => layergroupOptions?.find(({ id }) => id === page?.dataSourceId)?.id,
    [layergroupOptions, page]
  );

  const panoramaOptions = useMemo<{ id: number; title: string }[]>(
    () =>
      survey?.dataSources.filter(isPanoramaSource).map((d) => ({
        id: d.id,
        title: d.name,
      })) ?? [],
    [survey?.dataSources]
  );

  const selectedPanoId = useMemo(
    () => panoramaOptions?.find(({ id }) => id === page?.dataSourceId)?.id,
    [panoramaOptions, page]
  );

  const updatePage = useCallback(
    (options: Partial<UpdatePageModel>) => {
      if (!page) return;

      api.surveys
        .updatePage(Number(surveyId), Number(surveyPageId), {
          title: page.title,
          description: page.description,
          pageNumber,
          ...options,
          force: false,
        })
        .then(() => {
          updateSurveyToken();
          updateQuestionToken();
        });
    },
    [page, api.surveys, surveyId, surveyPageId, pageNumber, updateSurveyToken, updateQuestionToken]
  );

  return (
    <Box borderBottom={"1px solid"} borderColor={"brand.grey.300"}>
      <Flex>
        <Box onClick={() => setShowOptions(!showOptions)} p={2} cursor="pointer" flex={1}>
          Koppel beeld aan deze pagina
        </Box>
        <ChevronDownIcon
          onClick={() => setShowOptions(!showOptions)}
          w={5}
          h={5}
          m={2.5}
          transform={showOptions ? "rotate(180deg)" : ""}
          cursor={"pointer"}
        />
      </Flex>
      {showOptions && (
        <Flex
          px={2}
          gap={2}
          maxH={showOptions ? "unset" : 0}
          overflow={"hidden"}
          py={showOptions ? 2 : 0}
        >
          {page && !!layergroupOptions?.length && (
            <FormControl variant="floating">
              <FormLabel>
                <Text fontSize="md">Kaartlaag</Text>
              </FormLabel>
              <Select
                isDisabled={selectedPanoId !== undefined}
                defaultValue={selectedLayerId}
                onChange={(e) => {
                  updatePage({
                    dataSourceId: e.currentTarget.value ? Number(e.currentTarget.value) : null,
                  });
                }}
              >
                <option value={undefined}>Geen</option>
                {layergroupOptions.map(({ id, title }) => (
                  <option key={id} value={id}>
                    {title}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {page && !!panoramaOptions?.length && (
            <FormControl variant="floating">
              <FormLabel>
                <Text fontSize="md">Panorama</Text>
              </FormLabel>
              <Select
                isDisabled={selectedLayerId !== undefined}
                defaultValue={selectedPanoId}
                onChange={(e) => {
                  updatePage({
                    dataSourceId: e.currentTarget.value ? Number(e.currentTarget.value) : null,
                  });
                }}
              >
                <option value={undefined}>Geen</option>
                {panoramaOptions.map(({ id, title }) => (
                  <option key={id} value={id}>
                    {title}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </Flex>
      )}
    </Box>
  );
};
