import type { ComponentStyleConfig } from "@chakra-ui/react";

export const ButtonTheme: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: "blue",
  },
  baseStyle: {
    fontFamily: "body",
  },
  sizes: {
    lg: {
      fontStretch: "normal",
    },
  },
};
