import { Box, Input, Textarea } from "@chakra-ui/react";
import { useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { useSurveyState } from "../SurveyContext";
import usePromise from "../utils/usePromise";
import { AddQuestionModal } from "./modals/AddQuestionModal";
import { FileQuestion } from "./FileQuestion";
import { MultipleLocationsQuestion } from "./MultipleLocationsQuestion";
import { OpenQuestion } from "./OpenQuestion";
import { BooleanQuestion } from "./BooleanQuestion";
import { RatingQuestion } from "./RatingQuestion";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";
import { ElementBlock } from "./ElementBlock";
import { ConfirmModal } from "./modals/ConfirmModal";
import { PageOptions } from "./PageOptions";
import {
  isBooleanQuestion,
  isChoiceQuestion,
  isElement,
  isFileUploadQuestion,
  isLocationPickerQuestion,
  isRatingQuestion,
  isTextQuestion,
  isTrackingQuestion,
} from "../utils/apiUtils";
import { TrackingQuestion } from "./TrackingQuestion";

export const SurveyBody = () => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const { updateSurveyToken, pageToken, updatePageToken } = useSurveyState();
  const navigate = useNavigate();

  const pageNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);

  const { api } = useSurveyState();

  const [pages] = usePromise(() => {
    return api.surveys.listPages(Number(surveyId)).then((r) => r.data);
  }, [surveyId, surveyPageId]);

  const [currentPage] = usePromise(() => {
    return api.surveys.getPage(Number(surveyId), Number(surveyPageId)).then((r) => {
      const data = r.data;
      if (pageNameInputRef.current) pageNameInputRef.current.value = data.title;
      if (descriptionInputRef.current) descriptionInputRef.current.value = data.description;
      return data;
    });
  }, [surveyId, surveyPageId, pageToken]);

  const deletePage = useCallback(() => {
    if (!surveyId || !surveyPageId || !currentPage || !pages) return;
    const pageIndex = pages.findIndex((page) => page.id === currentPage.id);
    const nextId = pages[pageIndex + 1]?.id ?? pages[pageIndex - 1]?.id;

    api.surveys.deletePage(Number(surveyId), Number(surveyPageId)).then((r) => {
      const status = r.status;
      switch (status) {
        case 204:
          navigate(`/edit/${surveyId}/page/${nextId}`);
          updateSurveyToken();
          break;

        case 409:
          alert("You can't delete this page because it contains answered questions");
        // TODO: Add confirm force delete option
      }
    });
  }, [api.surveys, currentPage, navigate, pages, surveyId, surveyPageId, updateSurveyToken]);

  const updatePage = useCallback(() => {
    if (!currentPage || !pages) return;
    const pageNumber = pages.findIndex((page) => page.id === currentPage.id);

    api.surveys
      .updatePage(Number(surveyId), Number(surveyPageId), {
        title: pageNameInputRef.current?.value ?? "",
        description: descriptionInputRef.current?.value ?? "",
        pageNumber,
        force: false,
      })
      .then(() => {
        updateSurveyToken();
        updatePageToken();
      });
  }, [currentPage, pages, api.surveys, surveyId, surveyPageId, updatePageToken, updateSurveyToken]);

  return (
    <>
      {currentPage && <PageOptions />}
      <Box m={6} pos="relative" w="800px" mx="auto">
        {!!pages && pages.length > 1 && (
          <DeleteButtonContainer>
            <ConfirmModal
              onConfirm={deletePage}
              buttonNode="Pagina verwijderen"
              titleNode="Pagina verwijderen"
              bodyNode="Weet je zeker dat je deze pagina wil verwijderen? deze actie kan niet ongedaan gemaakt worden."
              confirmNode="Verwijderen"
              primaryColor="red"
            />
          </DeleteButtonContainer>
        )}
        <Input bg="white" ref={pageNameInputRef} onBlur={updatePage} maxW="60%" />
        <Textarea ref={descriptionInputRef} onBlur={updatePage} mt={4} />
        {currentPage?.elements.map((element) => {
          if (isLocationPickerQuestion(element)) {
            return <MultipleLocationsQuestion key={element.id} questionId={element.id} />;
          } else if (isTextQuestion(element)) {
            return <OpenQuestion key={element.id} questionId={element.id} />;
          } else if (isFileUploadQuestion(element)) {
            return <FileQuestion key={element.id} questionId={element.id} />;
          } else if (isBooleanQuestion(element)) {
            return <BooleanQuestion key={element.id} questionId={element.id} />;
          } else if (isRatingQuestion(element)) {
            return <RatingQuestion key={element.id} questionId={element.id} />;
          } else if (isChoiceQuestion(element)) {
            return <MultipleChoiceQuestion key={element.id} questionId={element.id} />;
          } else if (isTrackingQuestion(element)) {
            return <TrackingQuestion key={element.id} questionId={element.id} />;
          } else if (isElement(element)) {
            return <ElementBlock key={element.id} questionId={element.id} />;
          } else return null;
        })}
        <AddQuestionModal
          onUpdate={() => {
            updateSurveyToken();
            updatePageToken();
          }}
        />
      </Box>
    </>
  );
};

const DeleteButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
