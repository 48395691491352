import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Text,
  FormErrorMessage,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { CreateQuestionnaireModel } from "../../api/ManagerApi";
import { QuestionnaireType } from "../../api/enums";
import { useSurveyState } from "../../SurveyContext";
import { AddIcon } from "@chakra-ui/icons";

type Props = {
  onAdd: (surveyId: number) => void;
};

const questionnaireTypes = [
  { label: "Vragenformulier", value: QuestionnaireType.Questionnaire },
  { label: "Contactformulier", value: QuestionnaireType.ContactForm },
];

export const AddSurveyModal = ({ onAdd }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const nameRef = useRef<HTMLInputElement>(null);
  const [questionnaireType, setQuestionnaireType] = useState(QuestionnaireType.Questionnaire);
  const { api } = useSurveyState();
  const [errors, setErrors] = useState<{ [K in keyof CreateQuestionnaireModel]?: string[] }>({});

  const clearError = useCallback(
    (fieldName: keyof CreateQuestionnaireModel) =>
      setErrors((e) => ({ ...e, [fieldName]: undefined })),
    []
  );

  const createSurvey = useCallback(() => {
    setErrors({ name: undefined });

    const defaultName =
      questionnaireType === QuestionnaireType.Questionnaire
        ? "Nieuwe vragenlijst"
        : questionnaireType === QuestionnaireType.ContactForm
        ? "Nieuw contactformulier"
        : "Nieuwe enquête";

    api.surveys
      .createSurvey({
        name: nameRef.current?.value?.trim() || defaultName,
        questionnaireType,
      })
      .then(
        (response) => {
          onAdd(response.data);
          onClose();
        },
        ({ error }) => {
          setErrors({
            name: error.errors.Name,
          });
        }
      );
  }, [api, onAdd, onClose, questionnaireType]);

  return (
    <>
      <Button onClick={onOpen} my={4} display="flex" ml="auto">
        <AddIcon mr={2} />
        Enquête aanmaken
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enquête aanmaken</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Wat is de naam van de enquête?</Text>
            <FormControl variant="floating" isInvalid={Boolean(errors.name?.length)}>
              <FormLabel>
                <Text fontSize="md">Naam</Text>
              </FormLabel>
              <Input
                required
                ref={nameRef}
                defaultValue="Nieuwe vragenlijst"
                onClick={() => nameRef.current?.select()}
                onChange={() => clearError("name")}
              />
              {errors.name?.map((e) => <FormErrorMessage key={e}>{e}</FormErrorMessage>)}
            </FormControl>
            <Text mt={3}>Wat voor soort enquête wordt het?</Text>
            <RadioGroup
              value={questionnaireType}
              onChange={(value: string) => setQuestionnaireType(Number(value) as QuestionnaireType)}
            >
              <Stack>
                {questionnaireTypes.map((questionnaireType) => (
                  <Radio value={questionnaireType.value} key={questionnaireType.value}>
                    {questionnaireType.label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Annuleren
            </Button>
            <Button colorScheme={"blue"} onClick={createSurvey}>
              Aanmaken
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
