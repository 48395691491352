import styled from "@emotion/styled";
import { TrackingAnswerModel } from "../api/ManagerApi";
import { useMemo } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { formatPercentage } from "../utils/formatPercentage";
import { Bar } from "../elements/Bar";

type Props = {
  entries: TrackingAnswerModel[];
  responses: number;
};

export const TrackingResult = ({ entries, responses }: Props) => {
  const differentEntries = useMemo(
    () =>
      new Map<string, number>(
        entries.map((e, i, a) => [e.value, a.filter(({ value }) => value === e.value).length])
      ),
    [entries]
  );

  const isGroupable = useMemo(
    () => Array.from(differentEntries).some(([, c]) => c > 1),
    [differentEntries]
  );

  return (
    <TrackingResultContainer maxH={96} w="100%" flexDir="column">
      {isGroupable
        ? Array.from(differentEntries).map(([value, count], i) => (
            <Flex key={value} gap={4} px={2} py={1}>
              <Box>{value}:</Box>
              <Box flexGrow={1}>
                <Bar count={count} total={responses} />
              </Box>
              <Box textAlign="right" minW={28}>
                {formatPercentage((count / responses) * 100)} ({count} / {responses})
              </Box>
            </Flex>
          ))
        : entries.map(({ value }) => (
            <Box key={value} px={2} py={1}>
              {value}
            </Box>
          ))}
    </TrackingResultContainer>
  );
};

const TrackingResultContainer = styled(Flex)`
  > :nth-child(even) {
    background: #f5f5f5;
  }
`;
