import type { ComponentStyleConfig } from "@chakra-ui/react";

export const FormLabelTheme: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "sm",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    p: 0,
    m: 0,
    "> p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  variants: {
    underline: {
      color: "black",
      textTransform: "capitalize",
      textDecoration: "underline",
      fontStretch: "normal",
      fontWeight: "regular",
      cursor: "pointer",
      "> span": {
        transition: "filter 0.2s",
        filter: "none",
      },
      _hover: {
        color: "blue.500",
        textDecoration: "underline",
        boxShadow: 0,
        "> span": {
          filter: "var(--filter-teal-500)",
        },
        "&.warning": {
          color: "red.500",
          "> span": {
            filter: "var(--filter-red-500)",
          },
        },
      },
    },
  },
};
