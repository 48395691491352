import { Box, Button, CloseButton, Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { LocationPickerAnswerModel } from "../api/ManagerApi";
import { useSurveyState } from "../SurveyContext";
import { EquirectangularHeatmap } from "../utils/heatmap";
import usePromise from "../utils/usePromise";
import "./Pano.css";
import debounce from "lodash.debounce";
import {
  assertPolymorhpicType,
  isLocationPickerQuestion,
  isPanoLocation,
  isPanoramaSource,
} from "../utils/apiUtils";
import { type ResultsPageOutletContext } from "../pages/Results";
import { PanoramaType } from "../api/enums";
import {
  CubeGeometry,
  CubeTile,
  DynamicAsset,
  EquirectGeometry,
  EquirectTile,
  ImageUrlSource,
  RectilinearView,
  SingleAssetSource,
  Viewer,
} from "marzipano";
import { Event } from "../marzipano";

interface Props {
  entries: LocationPickerAnswerModel[];
  heatmap?: boolean;
}

export default function PanoResult({ entries, heatmap = false }: Props) {
  const { api } = useSurveyState();
  const { survey } = useOutletContext<ResultsPageOutletContext>();
  const { surveyId } = useParams<{ surveyId: string }>();
  const [view, setView] = useState({ yaw: 0, pitch: 0 });
  const [heatmapScale, setHeatmapScale] = useState(1);
  const [filter, setFilter] = useState<number | null>(null);
  const [isHeatmap, setIsHeatmap] = useState(false);

  /** A higher value increases quality, but also increases processing time.  */
  const [heatmapHeight] = useState(1800);
  /** Multiplied by heatmapHeight to resolve actual brush size. A higher value increases the size of the heatmap brush, resulting in bigger blobs for each point set. */
  const [heatmapBrushFactor] = useState(0.01);
  /** Value between 0 and 1, a higher value increases the significance of each individual point within the heatmap. */
  const [heatmapBrushStrength] = useState(0.75);

  const surveyPage = useMemo(
    () => survey.pages.find((p) => p.elements.some((e) => e.id === entries[0].questionId)),
    [survey, entries]
  );

  const panoSource = useMemo(() => {
    const source = survey.dataSources.find((d) => d.id === surveyPage?.dataSourceId);
    assertPolymorhpicType(isPanoramaSource, source);
    return source;
  }, [survey, surveyPage]);

  const panoElementRef = useRef<HTMLDivElement>(null);
  const infoElementRef = useRef<HTMLDivElement>(null);

  const panoElement = panoElementRef.current;
  const infoElement = infoElementRef.current;

  const points = useMemo(() => {
    return entries.flatMap((e) => e.pins).filter(isPanoLocation);
  }, [entries]);

  const filteredPoints = useMemo(
    () => points.filter((l) => filter === null || filter === l.pinDefinition),
    [points, filter]
  );

  const panoUrl = useMemo(() => panoSource.imageUrl, [panoSource]);

  const updateHeatmapScale = useMemo(
    () =>
      debounce((scale) => {
        setHeatmapScale(scale);
      }, 1000),
    [setHeatmapScale]
  );

  useEffect(() => updateHeatmapScale.cancel, [updateHeatmapScale]);

  const [popupInfo, setPopupInfo] = useState<{
    lat: number;
    lon: number;
    description: string;
  } | null>(null);

  const [question] = usePromise(() => {
    return api.surveys.getElement(parseInt(surveyId!), entries[0].questionId).then((r) => {
      if (isLocationPickerQuestion(r.data)) return r.data;
    });
  }, [surveyId]);

  const filters = useMemo(() => {
    if (question) {
      return question?.pins.map((p) => {
        return { pinId: p.id, label: p.label };
      });
    }
    return [];
  }, [question]);

  const marzipanoViewer = useMemo(() => {
    if (!panoElement) return;
    const viewer = new Viewer(panoElement, {
      controls: {
        mouseViewMode: "drag",
      },
    });

    return viewer;
  }, [panoElement]);

  const levels = useMemo(() => {
    switch (panoSource.panoType) {
      case PanoramaType.Cube:
        return panoSource.levels.map((l) => ({
          tileSize: panoSource.levels[0],
          size: l,
          fallbackOnly: l === panoSource.levels[0],
        }));
      case PanoramaType.Sphere:
        return panoSource.levels.map((l) => ({ width: l }));
      default:
        return [];
    }
  }, [panoSource]);

  const geometry = useMemo(() => {
    switch (panoSource.panoType) {
      case PanoramaType.Cube:
        return new CubeGeometry(levels as ConstructorParameters<typeof CubeGeometry>[0]);
      case PanoramaType.Sphere:
        return new EquirectGeometry(levels as ConstructorParameters<typeof EquirectGeometry>[0]);
      default:
        throw new Error("Invalid panorama type");
    }
  }, [panoSource, levels]);

  const imageSource = useMemo(
    () =>
      new ImageUrlSource((tile) => {
        // @ts-ignore
        if (tile instanceof CubeGeometry.Tile) {
          const cubeTile = tile as CubeTile;
          return {
            url: panoUrl
              .replace(/\{h\}/g, String(cubeTile.x + 1))
              .replace(/\{v\}/g, String(cubeTile.y + 1))
              .replace(/\{l\}/g, String(panoSource.levels.length - cubeTile.z))
              .replace(/\{f\}/g, cubeTile.face),
          };
        }

        // @ts-ignore
        if (tile instanceof EquirectGeometry.Tile) {
          const equirectTile = tile as EquirectTile;
          return {
            url: panoUrl.replace(/\{l\}/g, String(equirectTile.z)),
          };
        }

        return {
          url: "",
        };
      }),
    [panoUrl, panoSource.levels]
  );

  const marzipanoView = useMemo(() => {
    const initialView = {
      yaw: view.yaw,
      pitch: view.pitch,
      fov: (90 * Math.PI) / 180,
    };

    const limiter = RectilinearView.limit.traditional(
      panoSource.levels.at(-1)!,
      (120 * Math.PI) / 180
    );

    return new RectilinearView(initialView, limiter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panoSource.levels]);

  const marzipanoScene = useMemo(() => {
    if (!marzipanoViewer || !imageSource) return;

    const scene = marzipanoViewer.createScene({
      source: imageSource,
      geometry: geometry,
      view: marzipanoView,
      pinFirstLevel: true,
    });

    scene.addEventListener(Event.viewChange, () => {
      setView({ yaw: marzipanoView.yaw(), pitch: marzipanoView.pitch() });
      updateHeatmapScale(marzipanoView.fov());
    });

    return scene;
  }, [geometry, imageSource, marzipanoView, marzipanoViewer, updateHeatmapScale]);

  useEffect(
    () => () => {
      if (marzipanoScene && marzipanoViewer?.hasScene(marzipanoScene)) {
        const hotspotContainer = marzipanoScene.hotspotContainer();
        hotspotContainer
          .listHotspots()
          ?.forEach((hotspot) => hotspotContainer.destroyHotspot(hotspot));
        requestAnimationFrame(() => marzipanoViewer.destroyScene(marzipanoScene));
      }
    },
    [marzipanoScene, marzipanoViewer]
  );

  const hotspotContainer = useMemo(() => marzipanoScene?.hotspotContainer(), [marzipanoScene]);

  useEffect(() => {
    if (isHeatmap || !hotspotContainer || !marzipanoScene) return;

    const hotspots = filteredPoints.map((p) => {
      const hotspot = document.createElement("div");
      hotspot.classList.add("hotspot");
      hotspot.onclick = () => {
        setPopupInfo({ lat: p.latitude, lon: p.longitude, description: p.comment });
      };
      panoElement?.appendChild(hotspot);

      const position = {
        yaw: (p.longitude * Math.PI) / 180,
        pitch: (p.latitude * Math.PI) / 180,
      };

      return hotspotContainer.createHotspot(hotspot, position, {
        perspective: { extraTransforms: "translate(-20px, -20px)" },
      });
    });

    marzipanoScene.switchTo();

    return () => {
      if (!hotspotContainer.listHotspots()?.filter(Boolean).length) return;
      hotspots
        .filter((hotspot) => Object.values(hotspot).some(Boolean))
        .forEach((hotspot) => {
          if (hotspotContainer.hasHotspot(hotspot)) hotspotContainer.destroyHotspot(hotspot);
        });
      setPopupInfo(null);
    };
  }, [filteredPoints, panoElement, marzipanoScene, hotspotContainer, isHeatmap]);

  const onSelectAnswer = useCallback(
    (longitude: number, latitude: number) => {
      marzipanoScene?.lookTo(
        {
          yaw: (longitude * Math.PI) / 180,
          pitch: (latitude * Math.PI) / 180,
        },
        { transitionDuration: 600 }
      );
    },
    [marzipanoScene]
  );

  useEffect(() => {
    if (!popupInfo || !hotspotContainer || !infoElement) {
      return;
    }

    const position = {
      yaw: (popupInfo.lon * Math.PI) / 180,
      pitch: (popupInfo.lat * Math.PI) / 180,
    };

    const hotspot = hotspotContainer.createHotspot(infoElement, position, {
      perspective: { extraTransforms: "translate(-20px, -20px)" },
    });

    return () => {
      if (hotspotContainer.hasHotspot(hotspot)) hotspotContainer.destroyHotspot(hotspot);
    };
  }, [popupInfo, hotspotContainer, infoElement]);

  const panoHeatmap = useMemo(() => {
    if (!isHeatmap) return;

    const heatmap = new EquirectangularHeatmap(
      Math.round(heatmapHeight * heatmapBrushFactor * heatmapScale),
      Math.round(heatmapHeight * heatmapBrushFactor * heatmapScale),
      {
        0: "rgba(0,0,255,0)",
        0.4: "blue",
        0.5: "cyan",
        0.6: "lime",
        0.8: "yellow",
        1.0: "red",
      },
      new Image(heatmapHeight * 2, heatmapHeight)
    );

    filteredPoints.forEach((p) =>
      heatmap.drawPoint(p.longitude, -p.latitude, heatmapBrushStrength)
    );

    return heatmap;
  }, [
    filteredPoints,
    isHeatmap,
    heatmapScale,
    heatmapHeight,
    heatmapBrushFactor,
    heatmapBrushStrength,
  ]);

  const [heatmapLayer] = usePromise(async () => {
    if (!panoHeatmap || !marzipanoScene) return;

    await panoHeatmap.colorize();

    const heatmapAsset = new DynamicAsset(panoHeatmap.targetCanvas);
    const source = new SingleAssetSource(heatmapAsset);
    const geometry = new EquirectGeometry([{ width: panoHeatmap.targetCanvas.width }]);

    marzipanoScene.switchTo();

    return marzipanoScene.createLayer({ source, geometry });
  }, [panoHeatmap, marzipanoScene]);

  useEffect(
    () => () => {
      if (!marzipanoScene || !heatmapLayer) return;
      const layers = marzipanoScene?.listLayers() ?? [];
      if (layers.includes(heatmapLayer)) marzipanoScene.destroyLayer(heatmapLayer);
    },
    [marzipanoScene, heatmapLayer]
  );

  // useEffect(() => {
  //   hm.toCubeMap().then((faces) => setFaces(faces));
  // }, []);

  if (!points.length) return <p>Geen punten geplaatst op panorama</p>;

  return (
    <>
      <Flex columnGap={12} rowGap={4} wrap="wrap">
        {heatmap && (
          <Box>
            Toon als:
            <Flex gap={4}>
              <Button onClick={() => setIsHeatmap(true)} bg={isHeatmap ? "blue.800" : "blue.500"}>
                Heatmap
              </Button>
              <Button onClick={() => setIsHeatmap(false)} bg={!isHeatmap ? "blue.800" : "blue.500"}>
                Pins
              </Button>
            </Flex>
          </Box>
        )}
        {filters.length > 1 && (
          <Box>
            Filters:
            <Flex gap={4}>
              <Button
                onClick={() => setFilter(null)}
                bg={filter === null ? "blue.800" : "blue.500"}
              >
                Toon alles
              </Button>
              {filters.map((f) => {
                return (
                  <Button
                    onClick={() => setFilter(f.pinId)}
                    bg={filter === f.pinId ? "blue.800" : "blue.500"}
                    key={f.pinId}
                  >
                    {f.label}
                  </Button>
                );
              })}
            </Flex>
          </Box>
        )}
        {/* {availablePanos.length > 1 && (
          <Box>
            Panorama's:
            <Flex gap={4}>
              {availablePanos.map((p) => (
                <Button
                  key={p.id}
                  onClick={() => setSelectedPano(p.id)}
                  bg={selectedPano === p.id ? "blue.800" : "blue.500"}
                >
                  {p.title.rendered}
                </Button>
              ))}
            </Flex>
          </Box>
        )}
        {availableScenarios.length > 1 && (
          <Box>
            Scenario's:
            <Flex gap={4}>
              <Button
                onClick={() => setSelectedScenario(0)}
                bg={!selectedScenario ? "blue.800" : "blue.500"}
              >
                Alle scenario's
              </Button>
              {availableScenarios.map((p) => (
                <Button
                  key={p.id}
                  onClick={() => setSelectedScenario(p.id)}
                  bg={selectedScenario === p.id ? "blue.800" : "blue.500"}
                >
                  {p.title.rendered}
                </Button>
              ))}
            </Flex>
          </Box>
        )} */}
      </Flex>
      <Box display="flex" gap="1rem" my="1rem">
        <Box ref={panoElementRef} className="pano" h="50vh" position="relative" flex={3}>
          <Box ref={infoElementRef} className="info">
            <Text>{popupInfo?.description || "Geen opmerking"}</Text>
            <CloseButton onClick={() => setPopupInfo(null)} />
          </Box>
        </Box>
        <ListContainer style={{ flex: 1 }}>
          {points
            .filter((p) => p.comment.length > 0)
            .map((l, i) => {
              return (
                <ListItem
                  key={i}
                  onClick={() => {
                    setPopupInfo({
                      lat: l.latitude,
                      lon: l.longitude,
                      description: l.comment,
                    });
                    onSelectAnswer(l.longitude, l.latitude);
                  }}
                >
                  {l.comment}
                </ListItem>
              );
            })}
        </ListContainer>
      </Box>
    </>
  );
}

const ListContainer = styled.div`
  max-height: 50vh;
  flex: 1;
  overflow-y: auto;
`;

const ListItem = styled.div`
  padding: 1rem 0.5rem;
  border-bottom: 1px solid var(--color-neutral-30);
  cursor: pointer;
`;
