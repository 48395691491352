import { ChoiceAnswerModel } from "../api/ManagerApi";
import { Fragment, useCallback, useMemo } from "react";
import { isChoiceQuestion } from "../utils/apiUtils";
import { useParams } from "react-router-dom";
import usePromise from "../utils/usePromise";
import { useSurveyState } from "../SurveyContext";
import { Box, Grid } from "@chakra-ui/react";
import { formatPercentage } from "../utils/formatPercentage";
import { Bar } from "../elements/Bar";

type Props = {
  entries: ChoiceAnswerModel[];
  responses: number;
};

export const ChoiceResult = ({ entries, responses }: Props) => {
  const { surveyId } = useParams<{ surveyId: string }>();
  const { api } = useSurveyState();

  const [question] = usePromise(() => {
    return api.surveys.getElement(parseInt(surveyId!), entries[0].questionId).then((r) => {
      if (isChoiceQuestion(r.data)) return r.data;
    });
  }, [surveyId]);

  const counts = useMemo(
    () =>
      new Map<string, number>(
        question?.options.map((o) => [
          o.value,
          entries.filter((e) => e.choices.some((c) => c.selected && c.value === o.value)).length,
        ])
      ),
    [question, entries]
  );

  const getCount = useCallback((value: string) => counts.get(value) ?? 0, [counts]);

  return (
    <Grid templateColumns={"max-content 1fr auto"} gap={4} maxW="container.sm">
      {question?.options.map((o) => (
        <Fragment key={o.label}>
          <Box>{o.label}:</Box>
          <Bar count={getCount(o.value)} total={responses} />
          <Box textAlign="right" minW={28}>
            {formatPercentage((getCount(o.value) / responses) * 100)} ({getCount(o.value)} /{" "}
            {responses})
          </Box>
        </Fragment>
      ))}
    </Grid>
  );
};
