import styled from "@emotion/styled";
import { TextAnswerModel } from "../api/ManagerApi";
import { Box } from "@chakra-ui/react";

type Props = {
  entries: TextAnswerModel[];
};

export const TextResult = ({ entries }: Props) => {
  return (
    <TextResultContainer>
      {entries.map((e) => (
        <Box key={e.id} py={1} px={2}>
          {e.text}
        </Box>
      ))}
    </TextResultContainer>
  );
};

const TextResultContainer = styled.div`
  max-height: 400px;
  width: 100%;
  overflow-y: auto;

  > :nth-child(even) {
    background-color: #f5f5f5;
  }
`;
