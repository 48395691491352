import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { useFrameContext } from "../../../FrameContext";
import { CreateDataSourceModel } from "../../../api/ManagerApi";
import { useForceUpdate } from "../../../utils/useForceUpdate";

export enum Pages {
  DataSourceList = 1,

  StartAdd,

  StartAddSep,
  SelectSepSources,

  StartAddCustom,
  StartAddCustomPano,
  StartAddCustomLayer,

  Confirm,
}

export interface ModalState {
  overrideDefaultPageHandling: boolean;
  nextText: string;
  nextPage?: Pages;
  previousText: string;
  previousPage?: Pages;
  sepHost: string;
  currentPage: Pages;
  selectedTemplate?: Pages;
  sourcesToImport: CreateDataSourceModel[];
  sourcesUpdated: boolean;
}

export const ManageDataSourcesContext = createContext<{
  state: ModalState;
  setState: Dispatch<SetStateAction<ModalState>>;
  readonly token: symbol;
  updateToken: () => void;
}>({
  state: {
    overrideDefaultPageHandling: false,
    nextText: "Databron toevoegen",
    sepHost: "",
    previousText: "Vorige",
    currentPage: Pages.DataSourceList,
    nextPage: Pages.StartAdd,
    sourcesToImport: [],
    sourcesUpdated: false,
  },
  setState: () => {},
  token: Symbol(""),
  updateToken: () => {},
});

export const ManageDataSourcesContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { state: initialState } = useContext(ManageDataSourcesContext);
  const { origin: parentOrigin } = useFrameContext();
  const [token, updateToken] = useForceUpdate("DataSourcesModal");

  const [state, setState] = useState<ModalState>({ ...initialState, sepHost: parentOrigin ?? "" });

  return (
    <ManageDataSourcesContext.Provider value={{ state, setState, token, updateToken }}>
      {children}
    </ManageDataSourcesContext.Provider>
  );
};
