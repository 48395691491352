import { RatingAnswerModel } from "../api/ManagerApi";
import { Fragment, useMemo } from "react";
import { isRatingQuestion } from "../utils/apiUtils";
import { useParams } from "react-router-dom";
import usePromise from "../utils/usePromise";
import { useSurveyState } from "../SurveyContext";
import { Box, Grid } from "@chakra-ui/react";
import { formatPercentage } from "../utils/formatPercentage";
import { Bar } from "../elements/Bar";

type Props = {
  entries: RatingAnswerModel[];
  responses: number;
};

export const RatingResult = ({ entries, responses }: Props) => {
  const { surveyId } = useParams<{ surveyId: string }>();
  const { api } = useSurveyState();

  const [question] = usePromise(() => {
    return api.surveys.getElement(parseInt(surveyId!), entries[0].questionId).then((r) => {
      if (isRatingQuestion(r.data)) return r.data;
    });
  }, [surveyId]);

  const counts = useMemo(() => {
    const rvObj: { [x: string]: number } = {};
    question?.options.forEach((o) => {
      rvObj[o.value] = 0;
    });
    entries.forEach((e) => {
      if (e.rating) {
        rvObj[e.rating]++;
      }
    });
    return rvObj;
  }, [entries, question?.options]);

  return (
    <Grid templateColumns={"max-content 1fr auto"} gap={4} maxW="container.sm">
      {question?.options.map((o) => (
        <Fragment key={o.value}>
          <Box>
            ({o.value}) {o.label ? o.label + ":" : ""}
          </Box>
          <Bar count={counts[o.value]} total={responses} />
          <Box textAlign="right" minW={28}>
            {formatPercentage((counts[o.value] / responses) * 100)} ({counts[o.value]}/{responses})
          </Box>
        </Fragment>
      ))}
    </Grid>
  );
};
