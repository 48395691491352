import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ButtonProps,
  ModalHeaderProps,
  ModalBodyProps,
  ModalProps,
} from "@chakra-ui/react";
import { ReactNode, useCallback, useEffect } from "react";

type Props = {
  onOpenModal?: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  modalProps?: Omit<ModalProps, "children" | "isOpen" | "onClose">;
  titleNode: ReactNode;
  titleProps?: Omit<ModalHeaderProps, "children">;
  bodyNode: ReactNode;
  bodyProps?: Omit<ModalBodyProps, "children">;
  buttonNode: ReactNode;
  buttonNodeProps?: Omit<ButtonProps, "onClick" | "color" | "colorScheme">;
  primaryColor?: "red" | "blue";
  confirmNode?: ReactNode;
  confirmNodeProps?: Omit<ButtonProps, "onClick" | "color" | "colorScheme">;
  cancelNode?: ReactNode;
  cancelNodeProps?: Omit<ButtonProps, "onClick">;
  open?: boolean;
};

export const ConfirmModal = ({
  onOpenModal = () => {},
  onConfirm,
  onCancel = () => {},
  modalProps = {},
  titleNode,
  titleProps = {},
  bodyNode,
  bodyProps = {},
  buttonNode,
  buttonNodeProps = {},
  primaryColor = "blue",
  confirmNode = "Bevestigen",
  confirmNodeProps = {},
  cancelNode = "Annuleren",
  cancelNodeProps = {},
  open = false,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openFn = useCallback(() => {
    onOpenModal();
    onOpen();
  }, [onOpenModal, onOpen]);

  const cancelFn = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  const confirmFn = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  useEffect(() => {
    if (open) onOpen();
  }, [open, onOpen]);

  return (
    <>
      {buttonNode && (
        <Button onClick={openFn} colorScheme={primaryColor} {...buttonNodeProps}>
          {buttonNode}
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={cancelFn} {...modalProps}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader {...titleProps}>{titleNode}</ModalHeader>
          <ModalCloseButton />
          <ModalBody {...bodyProps}>{bodyNode}</ModalBody>

          <ModalFooter>
            {cancelNode && (
              <Button variant="ghost" mr={3} onClick={cancelFn} {...cancelNodeProps}>
                {cancelNode}
              </Button>
            )}
            {confirmNode && (
              <Button colorScheme={primaryColor} onClick={confirmFn} {...confirmNodeProps}>
                {confirmNode}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
