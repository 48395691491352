import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Flex,
  Button,
  NumberInputField,
  NumberInput,
  CheckboxGroup,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FileUploadQuestionModel, UpdateFileUploadQuestionModel } from "../api/ManagerApi";
import { QuestionBox } from "../components/QuestionBox";
import { useSurveyState } from "../SurveyContext";
import { isFileUploadQuestion } from "../utils/apiUtils";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";

type Props = {
  questionId: number;
};

const allowedTypes = [
  { label: "png", value: "image/png" },
  { label: "jpg", value: "image/jpeg" },
  { label: "gif", value: "image/gif" },
  { label: "tiff", value: "image/tiff" },
  { label: "bmp", value: "image/bmp" },
  { label: "pdf", value: "application/pdf" },
];

const defaultMaxFileSize = 10;
const defaultMaxAmount = 1;

export const FileQuestion = ({ questionId }: Props) => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const { api, updateSurveyToken, updatePageToken, pageToken } = useSurveyState();
  const [valid, setValid] = useState(true);
  const [, updateView] = useForceUpdate("View");

  useEffect(() => updateQuestionToken(), [pageToken, updateQuestionToken]);

  const [question] = usePromise(() => {
    return api.surveys.getElement(Number(surveyId), questionId).then((r) => {
      if (isFileUploadQuestion(r.data)) return r.data;
    });
  }, [questionRefreshToken]);

  const updateQuestion = useCallback(
    (questionModel: FileUploadQuestionModel) => {
      if (!surveyId || !question || !surveyPageId) return;

      api.surveys
        .updateElement(parseInt(surveyId), question.id, {
          title: questionModel.title,
          content: questionModel.content,
          required: questionModel.required,
          type: question.type,
          pageId: parseInt(surveyPageId),
          allowedTypes: questionModel.allowedTypes,
          maxFileSize: questionModel.maxFileSize,
          maxAmount: questionModel.maxAmount,
        } as UpdateFileUploadQuestionModel)
        .then(updateSurveyToken)
        .then(() => setValid(true))
        .then(updateQuestionToken)
        .catch(() => {
          setValid(false);
          Object.assign(question, questionModel);
          updateView();
        });
    },
    [
      api.surveys,
      question,
      surveyId,
      surveyPageId,
      updateSurveyToken,
      updateQuestionToken,
      updateView,
    ]
  );

  const deleteQuestion = useCallback(() => {
    if (!surveyId || !question) return;
    api.surveys
      .deleteElement(parseInt(surveyId), question.id)
      .then(updateSurveyToken)
      .then(updatePageToken);
  }, [api.surveys, question, surveyId, updatePageToken, updateSurveyToken]);

  if (!question) return null;

  return (
    <QuestionBox questionType="Bestand upload" questionTitle={question?.title} valid={valid}>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Vraag</Text>
        </FormLabel>
        <Input
          defaultValue={question?.title}
          onBlur={(e) =>
            updateQuestion({
              ...question,
              title: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Omschrijving</Text>
        </FormLabel>
        <Input
          defaultValue={question?.content}
          onBlur={(e) =>
            updateQuestion({
              ...question,
              content: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <Flex my={4} gap={4} alignItems="end">
        <Checkbox
          defaultChecked={question.required}
          onChange={(e) =>
            updateQuestion({
              ...question,
              required: e.target.checked,
            })
          }
        >
          Verplicht
        </Checkbox>
      </Flex>
      <FormControl my={4}>
        <FormLabel>Geaccepteerde bestandstypes</FormLabel>
        <CheckboxGroup>
          {allowedTypes.map((type) => (
            <Checkbox
              mr={2}
              mt={2}
              key={type.value}
              defaultChecked={question.allowedTypes.includes(type.value)}
              onChange={(e) => {
                if (e.target.checked) {
                  updateQuestion({
                    ...question,
                    allowedTypes: [...question.allowedTypes, type.value],
                  });
                } else {
                  updateQuestion({
                    ...question,
                    allowedTypes: question.allowedTypes.filter((t) => t !== type.value),
                  });
                }
              }}
            >
              .{type.label.toLocaleUpperCase()}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </FormControl>
      <Flex my={4} gap={4} alignItems="end">
        <FormControl variant="floating" flex={2}>
          <FormLabel>
            <Text fontSize="md">Max. grootte per bestand (MB)</Text>
          </FormLabel>
          <NumberInput
            defaultValue={question.maxFileSize / 1024 / 1024 ?? defaultMaxFileSize}
            onChange={(vas, van) =>
              updateQuestion({
                ...question,
                maxFileSize: (vas.length ? van : defaultMaxFileSize) * 1024 * 1024,
              })
            }
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl variant="floating" flex={2}>
          <FormLabel>
            <Text fontSize="md">Max. aantal bestanden</Text>
          </FormLabel>
          <NumberInput
            defaultValue={question.maxAmount ?? defaultMaxAmount}
            onChange={(vas, van) =>
              updateQuestion({
                ...question,
                maxAmount: vas.length ? van : defaultMaxAmount,
              })
            }
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </Flex>
      <Flex>
        <Button ml="auto" colorScheme="red" onClick={deleteQuestion}>
          Vraag verwijderen
        </Button>
      </Flex>
    </QuestionBox>
  );
};
