import { PermissionAssignmentModel } from "../api/ManagerApi";
import { PermissionOperation, PermissionTypeName } from "../api/enums";

const createPermission = (
  typeName: PermissionTypeName,
  operation: PermissionOperation,
  resourceId?: number
): PermissionAssignmentModel => ({
  operation,
  typeName,
  resourceId,
});

export const getPublicPermissions = (): PermissionAssignmentModel[] => [
  createPermission(PermissionTypeName.Questionnaire, PermissionOperation.READ),
  createPermission(PermissionTypeName.Response, PermissionOperation.CREATE),
];

export const getPrivatePermissions = (): PermissionAssignmentModel[] => [
  createPermission(PermissionTypeName.Questionnaire, PermissionOperation.READ),
  createPermission(PermissionTypeName.Questionnaire, PermissionOperation.CREATE),
  createPermission(PermissionTypeName.Questionnaire, PermissionOperation.UPDATE),
  createPermission(PermissionTypeName.Questionnaire, PermissionOperation.DELETE),

  createPermission(PermissionTypeName.Response, PermissionOperation.READ),
  createPermission(PermissionTypeName.Response, PermissionOperation.CREATE),
  createPermission(PermissionTypeName.Response, PermissionOperation.UPDATE),
  createPermission(PermissionTypeName.Response, PermissionOperation.DELETE),

  createPermission(PermissionTypeName.ApiKey, PermissionOperation.READ),
  createPermission(PermissionTypeName.ApiKey, PermissionOperation.CREATE),
  createPermission(PermissionTypeName.ApiKey, PermissionOperation.UPDATE),
  createPermission(PermissionTypeName.ApiKey, PermissionOperation.DELETE),
];
