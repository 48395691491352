/** @file - This file adds missing properties and/or fixes options to the (unofficial) marzipano declarations. */

declare module "marzipano" {
  interface CubeTile {
    x: number;
    y: number;
    z: number;
    face: "f" | "b" | "l" | "r" | "u" | "d";
  }

  interface EquirectTile {
    z: number;
  }

  interface Scene {
    createLayer(opts?: {
      source: Source | SingleAssetSource;
      geometry: Geometry;
      pinFirstLevel?: boolean;
      textureStoreOpts?: TextureStoreOptions;
      layerOpts?: LayerOptions;
    }): Layer;

    addEventListener(event: keyof typeof Event, listener: () => void): void;
    removeEventListener(event: keyof typeof Event, listener: () => void): void;
  }
}

/**
 * declaration is invalid in the context of the marzipano library, since it uses a numeric enum instead of the required string enum.
 * @enum
 */
export enum Event {
  active = "active",
  inactive = "inactive",
  parameterDynamics = "parameterDynamics",
  hotspotsChange = "hotspotsChange",
  afterRender = "afterRender",
  beforeRender = "beforeRender",
  layerChange = "layerChange",
  networkError = "networkError",
  textureCancel = "textureCancel",
  textureError = "textureError",
  textureInvalid = "textureInvalid",
  textureLoad = "textureLoad",
  textureStartLoad = "textureStartLoad",
  textureUnload = "textureUnload",
  timeout = "timeout",
  change = "change",
  resize = "resize",
  sceneChange = "sceneChange",
  viewChange = "viewChange",
  renderComplete = "renderComplete",
  renderInvalid = "renderInvalid",
};