import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Flex,
  Button,
  Select,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TrackingQuestionModel, UpdateTrackingQuestionModel } from "../api/ManagerApi";
import { QuestionBox } from "../components/QuestionBox";
import { useSurveyState } from "../SurveyContext";
import { isTrackingQuestion } from "../utils/apiUtils";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";
import { TrackingType } from "../api/enums";

type Props = {
  questionId: number;
};

const trackingTypes: Array<{ type: TrackingType; displayText: string }> = [
  { type: TrackingType.Query, displayText: "Url parameter" },
  { type: TrackingType.Cookie, displayText: "Cookie" },
  { type: TrackingType.LocalStorage, displayText: "Waarde in localstorage" },
  { type: TrackingType.SessionStorage, displayText: "Waarde in sessionstorage" },
  { type: TrackingType.Custom, displayText: "Overige implementatie" },
];

export const TrackingQuestion = ({ questionId }: Props) => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const [, updateView] = useForceUpdate("View");
  const { api, updatePageToken, updateSurveyToken, pageToken } = useSurveyState();
  const [valid, setValid] = useState(true);

  useEffect(() => updateQuestionToken, [pageToken, updateQuestionToken]);

  const [question] = usePromise(() => {
    return api.surveys.getElement(Number(surveyId), questionId).then((r) => {
      if (isTrackingQuestion(r.data)) return r.data;
    });
  }, [questionRefreshToken]);

  const updateQuestion = useCallback(
    (questionModel: Partial<TrackingQuestionModel>) => {
      if (!surveyId || !question || !surveyPageId) return;
      api.surveys
        .updateElement(Number(surveyId), question.id, {
          pageId: Number(surveyPageId),
          ...question,
          ...questionModel,
        } as UpdateTrackingQuestionModel)
        .then(updateSurveyToken)
        .then(() => setValid(true))
        .then(updateQuestionToken)
        .catch(() => {
          setValid(false);
          Object.assign(question, questionModel);
          updateView();
        });
    },
    [api.surveys, question, surveyId, surveyPageId, updateSurveyToken, updateQuestionToken, updateView]
  );

  const deleteQuestion = useCallback(() => {
    if (!surveyId || !question) return;
    api.surveys.deleteElement(parseInt(surveyId), question.id).then(updateSurveyToken).then(updatePageToken);
  }, [api.surveys, question, surveyId, updatePageToken, updateSurveyToken]);

  if (!question) return null;

  return (
    <QuestionBox
      valid={valid}
      questionType={`Tracker (${
        trackingTypes.find((t) => t.type === question.trackingType)?.displayText ?? "geen type"
      })`}
      questionTitle={question?.title}
    >
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Vraag</Text>
        </FormLabel>
        <Input
          defaultValue={question?.title}
          onBlur={(e) =>
            updateQuestion({
              title: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Omschrijving</Text>
        </FormLabel>
        <Input
          defaultValue={question?.content}
          onBlur={(e) =>
            updateQuestion({
              content: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <Checkbox
        mt={4}
        defaultChecked={question.required}
        onChange={(e) =>
          updateQuestion({
            required: e.target.checked,
          })
        }
      >
        Verplicht
      </Checkbox>
      <FormControl variant="floating" isRequired>
        <FormLabel>
          <Text fontSize="md">Type</Text>
        </FormLabel>
        <Select
          defaultValue={question.trackingType}
          onChange={(e) => {
            updateQuestion({
              trackingType: Number(e.target.value) as TrackingType,
            });
          }}
        >
          {trackingTypes.map((entry) => (
            <option key={entry.type} value={entry.type}>
              {entry.displayText}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="floating" isRequired>
        <FormLabel>
          <Text fontSize="md">Parameter</Text>
        </FormLabel>
        <Input
          defaultValue={question?.propertyName}
          onBlur={(e) =>
            updateQuestion({
              propertyName: e.currentTarget.value,
            })
          }
        />
      </FormControl>
      <FormControl variant={"floating"} isRequired={question.required}>
        <FormLabel>
          <Text fontSize="md">Bericht voor ontbrekende waarde</Text>
        </FormLabel>
        <Input
          defaultValue={question?.messageForNoValue}
          onBlur={(e) => updateQuestion({ messageForNoValue: e.currentTarget.value })}
        />
      </FormControl>
      <Flex>
        <Button ml="auto" mt={4} colorScheme="red" onClick={deleteQuestion}>
          Vraag verwijderen
        </Button>
      </Flex>
    </QuestionBox>
  );
};
