function clamp(x: number, min: number, max: number) {
  return Math.min(max, Math.max(x, min));
}

function mod(x: number, n: number) {
  return ((x % n) + n) % n;
}

const readIndex = (x: number, y: number, width: number) => 4 * (y * width + x);

function copyPixelNearest(read: ImageData, write: ImageData) {
  const {width, height, data} = read;

  return (xFrom: number, yFrom: number, to: number) => {

    const nearest = readIndex(
      clamp(Math.round(xFrom), 0, width - 1),
      clamp(Math.round(yFrom), 0, height - 1),
      width
    );

    for (let channel = 0; channel < 4; channel++) {
      write.data[to + channel] = data[nearest + channel];
    }
  };
}

function copyPixelBilinear(read: ImageData, write: ImageData) {
  const {width, height, data} = read;

  return (xFrom: number, yFrom: number, to: number) => {
    const xl = clamp(Math.floor(xFrom), 0, width - 1);
    const xr = clamp(Math.ceil(xFrom), 0, width - 1);
    const xf = xFrom - xl;

    const yl = clamp(Math.floor(yFrom), 0, height - 1);
    const yr = clamp(Math.ceil(yFrom), 0, height - 1);
    const yf = yFrom - yl;

    const p00 = readIndex(xl, yl, width);
    const p10 = readIndex(xr ,yl, width);
    const p01 = readIndex(xl, yr, width);
    const p11 = readIndex(xr, yr, width);

    for (let channel = 0; channel < 4; channel++) {
      const p0 = data[p00 + channel] * (1 - xf) + data[p10 + channel] * xf;
      const p1 = data[p01 + channel] * (1 - xf) + data[p11 + channel] * xf;
      write.data[to + channel] = Math.ceil(p0 * (1 - yf) + p1 * yf);
    }
  };
}

// performs a discrete convolution with a provided kernel
function kernelResample(read: ImageData, write: ImageData, filterSize: number, kernel: (x: number) => number) {
  const {width, height, data} = read;

  const twoFilterSize = 2*filterSize;
  const xMax = width - 1;
  const yMax = height - 1;
  const xKernel = new Array(4);
  const yKernel = new Array(4);

  return (xFrom: number, yFrom: number, to: number) => {
    const xl = Math.floor(xFrom);
    const yl = Math.floor(yFrom);
    const xStart = xl - filterSize + 1;
    const yStart = yl - filterSize + 1;

    for (let i = 0; i < twoFilterSize; i++) {
      xKernel[i] = kernel(xFrom - (xStart + i));
      yKernel[i] = kernel(yFrom - (yStart + i));
    }

    for (let channel = 0; channel < 4; channel++) {
      let q = 0;

      for (let i = 0; i < twoFilterSize; i++) {
        const y = yStart + i;
        const yClamped = clamp(y, 0, yMax);
        let p = 0;
        for (let j = 0; j < twoFilterSize; j++) {
          const x = xStart + j;
          const index = readIndex(clamp(x, 0, xMax), yClamped, width);
          p += data[index + channel] * xKernel[j];

        }
        q += p * yKernel[i];
      }

      write.data[to + channel] = Math.round(q);
    }
  };
}

function copyPixelBicubic(read: ImageData, write: ImageData) {
  const b = -0.5;
  const kernel = (x: number) => {
    x = Math.abs(x);
    const x2 = x**2;
    const x3 = x**3;
    return x <= 1 ?
      (b + 2)*x3 - (b + 3)*x2 + 1 :
      b*x3 - 5*b*x2 + 8*b*x - 4*b;
  };

  return kernelResample(read, write, 2, kernel);
}

function copyPixelLanczos(read: ImageData, write: ImageData) {
  const filterSize = 5;
  const kernel = (x: number) => {
    if (x === 0) {
      return 1;
    }
    else {
      const xp = Math.PI * x;
      return filterSize * Math.sin(xp) * Math.sin(xp / filterSize) / (xp * xp);
    }
  };

  return kernelResample(read, write, filterSize, kernel);
}

class Cube {
  x = 0;
  y = 0;
  z = 0;
}

const orientations = {
  pz: (out: Cube, x: number, y: number) => {
    out.x = -1;
    out.y = -x;
    out.z = -y;
  },
  nz: (out: Cube, x: number, y: number) => {
    out.x = 1;
    out.y = x;
    out.z = -y;
  },
  px: (out: Cube, x: number, y: number) => {
    out.x = x;
    out.y = -1;
    out.z = -y;
  },
  nx: (out: Cube, x: number, y: number) => {
    out.x = -x;
    out.y = 1;
    out.z = -y;
  },
  py: (out: Cube, x: number, y: number) => {
    out.x = -y;
    out.y = -x;
    out.z = 1;
  },
  ny: (out: Cube, x: number, y: number) => {
    out.x = y;
    out.y = -x;
    out.z = -1;
  }
};

export interface RenderFaceArgs {
  readData: ImageData,
  face: keyof typeof orientations,
  rotation: number,
  interpolation: "bilinear" | "nearest" | "bicubic" | "lanczos",
  maxWidth?: number
}

export const renderFace = ({readData, face, rotation, interpolation, maxWidth = Infinity}: RenderFaceArgs): ImageData => {
  const size = Math.min(maxWidth, readData.width / 4);

  const cube = new Cube();
  const orientation = orientations[face];

  const writeData = new ImageData(size, size);

  const copyPixel =
    interpolation === 'bilinear' ? copyPixelBilinear(readData, writeData) :
    interpolation === 'bicubic' ? copyPixelBicubic(readData, writeData) :
    interpolation === 'lanczos' ? copyPixelLanczos(readData, writeData) :
    copyPixelNearest(readData, writeData);

  for (let x = 0; x < size; x++) {
    for (let y = 0; y < size; y++) {
      const to = 4 * (y * size + x);

      // fill alpha channel
      writeData.data[to + 3] = 255;

      // get position on cube face
      // cube is centered at the origin with a side length of 2
      orientation(cube, (2 * (x + 0.5) / size - 1), (2 * (y + 0.5) / size - 1));

      // project cube face onto unit sphere by converting cartesian to spherical coordinates
      const r = (cube.x ** 2 + cube.y ** 2 + cube.z ** 2) ** .5;
      const lon = mod(Math.atan2(cube.y, cube.x) + rotation, 2 * Math.PI);
      const lat = Math.acos(cube.z / r);

      copyPixel(readData.width * lon / Math.PI / 2 - 0.5, readData.height * lat / Math.PI - 0.5, to);
    }
  }

  return writeData;
}