import type { ComponentStyleConfig } from "@chakra-ui/react";

export const NumberInputTheme: ComponentStyleConfig = {
  baseStyle: {
    field: {
      _focusVisible: {
        _focusVisible: {
          borderColor: "blue.500",
          boxShadow: `0px 0px 0px 1px var(--chakra-colors-brand-teal-500)`,
        },
      },
      "&[aria-required=true]": {
        bg: "url(/images/required-asterisk.svg) no-repeat right top",
        backgroundSize: "0.75rem",
      },
    },
  },
  variants: {
    outline: {
      field: {
        bg: "white",
        border: "1px solid",
        borderColor: "gray.500",
        _hover: {
          borderColor: "gray.600",
        },
        _disabled: {
          backgroundColor: "gray.300",
        },
      },
      _focusVisible: {
        borderColor: "blue.500",
        boxShadow: `0px 1px 0px 1px var(--chakra-colors-brand-teal-500)`,
      },
    },
  },
};
