import { Flex, Heading, Divider, Box, Image } from "@chakra-ui/react";
import { Link, Navigate, NavLink, Outlet, useParams } from "react-router-dom";
import { useSurveyState } from "../SurveyContext";
import usePromise from "../utils/usePromise";
import { QuestionnaireModel } from "../api/ManagerApi";
import styled from "@emotion/styled";

export interface ResultsPageOutletContext {
  survey: QuestionnaireModel
}

export const ResultsPage = () => {
  const { surveyId } = useParams<{ surveyId: string }>();
  const { api } = useSurveyState();

  const [survey, error] = usePromise(
    () => api.surveys.getSurvey(Number(surveyId)).then((r) => r.data),
    [surveyId]
  );

  if (error) return <Navigate to="/" />;

  return (
    <Box m={6}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" justify="flex-start">
          <Link to="/">
            <Image src="/tim-logo.svg" w="100px" h="100px" />
          </Link>
          <Heading as="h1" fontSize={"2xl"}>
            Resultaten van {survey?.name}
          </Heading>
        </Flex>
        <Flex maxW="container.xl" w="100%" mx="auto" px={4} alignItems="center" justify="flex-end">
          <Toggle end to="">Samenvatting</Toggle>
          <Toggle to="responses">Respondenten</Toggle>
        </Flex>
      </Flex>
      <Divider my={6} />
      {survey && <Outlet context={{ survey }} />}
    </Box>
  );
};

const Toggle = styled(NavLink)`
  display: block;
  padding: 0.5rem 1rem;
  color: var(--chakra-colors-white);
  text-decoration: none;
  background-color: var(--chakra-colors-blue-500);
  border-radius: 0.25rem;
  margin-right: 0.5rem;

  &.active {
    background-color: var(--chakra-colors-blue-800);
  }
`;
