import {
  ArrowLeftIcon,
  ArrowRightIcon,
  SettingsIcon,
  WarningIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import { ManageDataSourcesContext, Pages } from "./context";
import { StartAdd } from "./StartAdd";
import { StartAddSep } from "./StartAddSep";
import { DataSourceList } from "./DataSourceList";
import { SelectSepSources } from "./SelectSepSources";
import { StartAddCustom } from "./StartAddCustom";
import { Confirm } from "./Confirm";
import { StartAddCustomPano } from "./StartAddCustomPano";
import { StartAddCustomLayer } from "./StartAddCustomLayer";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotAvailable = ({ children }: PropsWithChildren<{}>) => (
  <>
    {children}
    <Flex as="p" color="yellow.500" mt={2} alignItems="center">
      <WarningTwoIcon mr={2} /> Deze functie is nog niet beschikbaar
    </Flex>
  </>
);

const NotFound = ({ children }: PropsWithChildren<{}>) => (
  <>
    {children}
    <Flex as="p" color="red.500" mt={2} alignItems="center">
      <WarningIcon mr={2} /> Pagina niet gevonden
    </Flex>
  </>
);

const pageDictionary: Record<Pages, () => JSX.Element> = {
  [Pages.DataSourceList]: () => <DataSourceList />,
  [Pages.StartAdd]: () => <StartAdd />,
  [Pages.StartAddSep]: () => <StartAddSep />,
  [Pages.SelectSepSources]: () => <SelectSepSources />,
  [Pages.StartAddCustom]: () => <StartAddCustom />,
  [Pages.StartAddCustomPano]: () => <StartAddCustomPano />,
  [Pages.StartAddCustomLayer]: () => <StartAddCustomLayer />,
  [Pages.Confirm]: () => <Confirm />,
};

interface Props {
  onUpdate: () => void;
}

export const ManageDataSourcesModal = ({ onUpdate }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state, setState } = useContext(ManageDataSourcesContext);

  const updateFn = useCallback(() => {
    if (state.sourcesUpdated) onUpdate();
  }, [state.sourcesUpdated, onUpdate]);

  const reset = useCallback(() => {
    setState((c) => ({
      sepHost: c.sepHost,
      overrideDefaultPageHandling: false,
      nextText: "Databron toevoegen",
      previousText: "Vorige",
      currentPage: Pages.DataSourceList,
      nextPage: Pages.StartAdd,
      sourcesToImport: [],
      sourcesUpdated: false,
    }));
  }, [setState]);

  useLayoutEffect(() => reset, [reset]);

  const next = useCallback(
    () =>
      setState((c) =>
        c.nextPage
          ? {
              ...c,
              currentPage: c.nextPage,
              previousPage: c.currentPage,
              previousText: "Vorige",
              nextText: "Volgende",
            }
          : c
      ),
    [setState]
  );

  const previous = useCallback(
    () =>
      setState((c) =>
        c.previousPage
          ? {
              ...c,
              currentPage: c.previousPage,
              nextPage: c.currentPage,
              previousText: "Vorige",
              nextText: "Volgende",
            }
          : c
      ),
    [setState]
  );

  const ActivePage = useMemo(
    () =>
      pageDictionary[state.currentPage] ?? (() => <NotFound>{Pages[state.currentPage]}</NotFound>),
    [state.currentPage]
  );

  useEffect(
    () =>
      setState((c) => {
        if (c.overrideDefaultPageHandling) return { ...c, overrideDefaultPageHandling: false };

        switch (state.currentPage) {
          case Pages.DataSourceList:
            return { ...c, previousPage: undefined, nextText: "Nieuwe toevoegen" };
          case Pages.StartAdd:
            return {
              ...c,
              previousPage: Pages.DataSourceList,
              nextPage: c.selectedTemplate,
              nextText: "Volgende",
              previousText: "Databronnen",
            };
          case Pages.StartAddSep:
          case Pages.StartAddCustom:
            return { ...c, previousPage: Pages.StartAdd, previousText: "Vorige" };
          case Pages.SelectSepSources:
            return { ...c, previousPage: Pages.StartAddSep };
          case Pages.StartAddCustomPano:
          case Pages.StartAddCustomLayer:
            return { ...c, previousPage: Pages.StartAddCustom };
          case Pages.Confirm:
            return { ...c, nextPage: undefined };
        }
        return c;
      }),
    [state.currentPage, setState]
  );

  return (
    <>
      <Button onClick={onOpen} my={4} display="flex" ml="auto">
        <SettingsIcon mr={2} />
        Beheer databronnen
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          updateFn();
          reset();
          onClose();
        }}
        size="xl"
        closeOnOverlayClick={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Beheer databronnen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ActivePage />
          </ModalBody>

          <ModalFooter w="100%" justifyContent="space-between">
            <Button variant={"blue"} onClick={previous} isDisabled={!state.previousPage}>
              <ArrowLeftIcon mr={2} /> {state.previousText}
            </Button>
            <Button
              variant="ghost"
              mx={3}
              onClick={() => {
                updateFn();
                reset();
                onClose();
              }}
            >
              Annuleren
            </Button>
            <Button colorScheme={"blue"} onClick={next} isDisabled={!state.nextPage}>
              {state.nextText} <ArrowRightIcon ml={2} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
