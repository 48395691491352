import { extendTheme } from "@chakra-ui/react";

import { AccordionTheme } from "./components/accordion-theme";
import { ButtonTheme } from "./components/buttons-theme";
import { CheckboxTheme } from "./components/checkbox-theme";
import { FormTheme } from "./components/form-theme";
import { FormLabelTheme } from "./components/formlabel-theme";
import { HeadingTheme } from "./components/heading-theme";
import { InputTheme } from "./components/input-theme";
import { ModalTheme } from "./components/modal-theme";
import { NumberInputTheme } from "./components/number-input-theme";
import { PopoverTheme } from "./components/popover-theme";
import { RadioTheme } from "./components/radio-theme";
import { SelectTheme } from "./components/select-theme";
import { TabTheme } from "./components/tab-theme";
import { TableTheme } from "./components/table-theme";

export const theme = extendTheme({
  textStyles: {
    h1: {
      fontSize: "2rem",
    },
  },
  styles: {
    global: {
      //   body: { overflowY: "hidden" },
      //   "::-webkit-scrollbar": {
      //     width: 2,
      //     height: 2,
      //     backgroundColor: "grey.100",
      //     borderRadius: "1000px",
      //   },
      //   "::-webkit-scrollbar-thumb": {
      //     backgroundColor: "grey.600",
      //     borderRadius: "1000px",
      //   },
      //   "::-webkit-scrollbar-track": {},
      //   "::selection": {
      //     color: "white",
      //     backgroundColor: "blue.900",
      //   },
    },
  },
  colors: {
    brand: {
      blue: {
        "100": "#dbf4fa",
        "500": "#004976",
        "600": "#0c2340",
      },
      mint: {
        "100": "#edfcfa",
        "500": "#0ac8be",
        "600": "#08aaa1",
      },
      grey: {
        "100": "#f7f9fb",
        "200": "#e7e9ec",
        "300": "#d5d8dc",
        "400": "#85919f",
        "500": "#707070",
      },
      red: {
        "100": "#ffe5e5",
        "500": "#e50707",
      },
      yellow: {
        "100": "#FFF5E5",
        "500": "#de8415",
      },
      green: {
        "500": "#3dc460",
      },
      white: {
        "100": "#ffffff",
        "008": "rgba(255, 255, 255, 0.08)",
      },
    },
  },
  components: {
    Accordion: AccordionTheme,
    Button: ButtonTheme,
    Checkbox: CheckboxTheme,
    Form: FormTheme,
    FormLabel: FormLabelTheme,
    Heading: HeadingTheme,
    Input: InputTheme,
    Modal: ModalTheme,
    NumberInput: NumberInputTheme,
    Popover: PopoverTheme,
    Radio: RadioTheme,
    Select: SelectTheme,
    Table: TableTheme,
    Tabs: TabTheme,
  },
});
