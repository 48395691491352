import type { ComponentStyleConfig } from "@chakra-ui/react";

export const RadioTheme: ComponentStyleConfig = {
  baseStyle: {
    container: {
      bg: "gray.100",
      pl: 2,
      py: 1,
      pr: 4,
      fontSize: "1rem",
      borderRadius: "1000px",
      whiteSpace: "nowrap",
      _hover: {
        ".chakra-radio__control": {
          boxShadow: `0 0 0 2px var(--chakra-colors-brand-gray-100),
                      0 0 0 3px var(--chakra-colors-brand-teal-500)`,
        },
      },
      _disabled: {
        _hover: {
          ".chakra-radio__control": {
            boxShadow: `none`,
          },
        },
      },
    },
    control: {
      bg: "white",
      borderColor: "white",
      boxShadow: `0 0 0 2px #fff,
                  0 0 0 3px #000`,
      transition: "all 0.2s",
      _checked: {
        bg: "blue.500",
        borderColor: "blue.500",
        boxShadow: `0 0 0 2px var(--chakra-colors-brand-gray-100),
                    0 0 0 3px var(--chakra-colors-brand-teal-500)`,
        _hover: {
          bg: "blue.500",
          borderColor: "blue.500",
        },
        _before: {
          display: "none",
        },
      },
      _disabled: {
        boxShadow: "none",
      },
    },
  },
  defaultProps: {
    size: "sm",
    colorScheme: "blue",
  },
  sizes: {
    sm: {
      label: {
        fontSize: "1rem",
      },
    },
  },
};
