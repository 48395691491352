import { BooleanAnswerModel } from "../api/ManagerApi";
import { useMemo } from "react";
import { formatPercentage } from "../utils/formatPercentage";
import { Bar } from "../elements/Bar";
import { Box, Flex } from "@chakra-ui/react";

type Props = {
  entries: BooleanAnswerModel[];
  responses: number;
};

export const BooleanResult = ({ entries, responses }: Props) => {
  const percentage = useMemo(() => {
    const total = entries.length;

    return (total / responses) * 100;
  }, [entries.length, responses]);

  return (
    <Flex maxW="container.sm" gap={4}>
      <Bar count={entries.filter((e) => e.bool).length} total={responses} />
      <Box textAlign="right" minW={28}>
        {formatPercentage(percentage)} ({entries.filter((e) => e.bool).length} / {responses})
      </Box>
    </Flex>
  );
};