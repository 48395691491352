import MapGL, { MapRef } from "react-map-gl";

import { CreateLayerSourceModel, LayerSourceModel } from "../api/ManagerApi";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useForceUpdate } from "../utils/useForceUpdate";

interface Props {
  source: LayerSourceModel | CreateLayerSourceModel;
}

export const SimpleMapViewer = forwardRef<MapRef, Props>(({ source }, ref) => {
  const mapRef = useRef<MapRef>(null!);
  const [map, setMap] = useState(() => mapRef.current?.getMap());
  const [token, updateToken] = useForceUpdate("MapViewer");

  useImperativeHandle(ref, () => mapRef.current);

  useEffect(() => {
    if (!map) {
      if (mapRef.current) {
        const newMap = mapRef.current.getMap();
        if (newMap) return setMap(newMap);
      }
    }
    updateToken();
  }, [map, token, updateToken]);

  useEffect(() => {
    if (map?.isStyleLoaded()) {
      map
        .getStyle()
        .layers.filter(
          (l: any) =>
            l.type !== "background" &&
            !l.source?.startsWith("mapbox://") &&
            !l.metadata?.hasOwnProperty("mapbox:featureComponent")
        )
        .forEach((layer) => {
          map.setLayoutProperty(
            layer.id,
            "visibility",
            source.clusters.includes(layer.id) ? "visible" : "none"
          );
        });
    }
  }, [source, token, map]);

  return (
    <MapGL
      initialViewState={{
        bounds: [source.bounds.east, source.bounds.north, source.bounds.west, source.bounds.south],
      }}
      mapStyle={"mapbox://styles/" + source.styleId}
      ref={mapRef}
      mapboxAccessToken={source.accessToken}
      style={{
        width: "100%",
      }}
    ></MapGL>
  );
});
