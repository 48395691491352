import {
  PinModel,
  PanoLocationPinModel,
  GeoLocationPinModel,
  ElementModel,
  LocationPickerQuestionModel,
  QuestionModel,
  AnswerModel,
  LocationPickerAnswerModel,
  TextQuestionModel,
  TextAnswerModel,
  FileUploadQuestionModel,
  FileUploadAnswerModel,
  BooleanAnswerModel,
  BooleanQuestionModel,
  RatingQuestionModel,
  RatingAnswerModel,
  ChoiceAnswerModel,
  ChoiceQuestionModel,
  TrackingQuestionModel,
  TrackingAnswerModel,
  DataSourceModel,
  LayerSourceModel,
  PanoramaSourceModel,
  CreateDataSourceModel,
  CreateLayerSourceModel,
  CreatePanoramaSourceModel,
} from "../api/ManagerApi";

export interface PolymorphicModel { type: string; }
export interface PolymorphicModelTypePredicate<TInput extends PolymorphicModel, TOutput extends TInput> {
  (model?: TInput): model is TOutput;
  expected: string;
  operator: "contains" | "startsWith" | "endsWith" | "equals";
}

export const createPolymorphicTypePredicate = <
  TInput extends PolymorphicModel,
  TOutput extends TInput
>(expectedType: string): PolymorphicModelTypePredicate<TInput, TOutput> => {
  const match =  /^(?:(?:\*([^*]*)\*)|(?:\*([^*]*))|(?:([^*]*)\*)|([^*]*))$/.exec(expectedType);

  if (match) {
    const [,contains,endsWith,startsWith,equals] : Array<string|undefined> = match;
    switch (false) {
      case !contains:
        return Object.assign(
          (model?: TInput): model is TOutput => !!model && model.type.includes(contains),
          { expected: contains, operator: "contains" } as const
        );

      case !endsWith:
        return Object.assign(
          (model?: TInput): model is TOutput => !!model && model.type.endsWith(endsWith),
          { expected: endsWith, operator: "endsWith" } as const
        );

      case !startsWith:
        return Object.assign(
          (model?: TInput): model is TOutput => !!model && model.type.startsWith(startsWith),
          { expected: startsWith, operator: "startsWith" } as const
        );

      case !equals:
        return Object.assign(
          (model?: TInput): model is TOutput => !!model && model.type === equals,
          { expected: equals, operator: "equals" } as const
        );
    }
  }

  throw new Error(`Invalid expected type: ${expectedType}`);
}

export const isPanoLocation = createPolymorphicTypePredicate<PinModel, PanoLocationPinModel>("PanoLocation");
export const isGeoLocation = createPolymorphicTypePredicate<PinModel, GeoLocationPinModel>("GeoLocation");

export const isElement = createPolymorphicTypePredicate<ElementModel, ElementModel>("Element");
export const isQuestion = createPolymorphicTypePredicate<ElementModel, QuestionModel>("*Question");
export const isLocationPickerQuestion = createPolymorphicTypePredicate<ElementModel, LocationPickerQuestionModel>("LocationPickerQuestion");
export const isTextQuestion = createPolymorphicTypePredicate<ElementModel, TextQuestionModel>("TextQuestion");
export const isFileUploadQuestion = createPolymorphicTypePredicate<ElementModel, FileUploadQuestionModel>("FileUploadQuestion");
export const isBooleanQuestion = createPolymorphicTypePredicate<ElementModel, BooleanQuestionModel>("BooleanQuestion");
export const isRatingQuestion = createPolymorphicTypePredicate<ElementModel, RatingQuestionModel>("RatingQuestion");
export const isChoiceQuestion = createPolymorphicTypePredicate<ElementModel, ChoiceQuestionModel>("ChoiceQuestion");
export const isTrackingQuestion = createPolymorphicTypePredicate<ElementModel, TrackingQuestionModel>("TrackingQuestion");

export const isLocationPickerAnswer = createPolymorphicTypePredicate<AnswerModel, LocationPickerAnswerModel>("LocationPickerAnswer");
export const isTextAnswer = createPolymorphicTypePredicate<AnswerModel, TextAnswerModel>("TextAnswer");
export const isFileUploadAnswer = createPolymorphicTypePredicate<AnswerModel, FileUploadAnswerModel>("FileUploadAnswer");
export const isBooleanAnswer = createPolymorphicTypePredicate<AnswerModel, BooleanAnswerModel>("BooleanAnswer");
export const isRatingAnswer = createPolymorphicTypePredicate<AnswerModel, RatingAnswerModel>("RatingAnswer");
export const isChoiceAnswer = createPolymorphicTypePredicate<AnswerModel, ChoiceAnswerModel>("ChoiceAnswer");
export const isTrackingAnswer = createPolymorphicTypePredicate<AnswerModel, TrackingAnswerModel>("TrackingAnswer");

export const isLayerSource = createPolymorphicTypePredicate<DataSourceModel, LayerSourceModel>("LayerSource");
export const isPanoramaSource = createPolymorphicTypePredicate<DataSourceModel, PanoramaSourceModel>("PanoramaSource");
export const isCreateLayerSource = createPolymorphicTypePredicate<CreateDataSourceModel, CreateLayerSourceModel>("LayerSource");
export const isCreatePanoramaSource = createPolymorphicTypePredicate<CreateDataSourceModel, CreatePanoramaSourceModel>("PanoramaSource");

export function assertPolymorhpicType<
  TInput extends PolymorphicModel = PolymorphicModel,
  TOutput extends TInput = TInput
>(
  assertion: PolymorphicModelTypePredicate<TInput, TOutput>,
  test?: TInput
): asserts test is TOutput {
  if (!!test && assertion(test)) return;

  throw new TypeError(`[Assertion failed${assertion.operator ? ` (${assertion.operator})` : ""})]: Expected ${assertion.expected} but got ${test?.type ?? "undefined"}`);
}