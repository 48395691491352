import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import { QuestionBox } from "../components/QuestionBox";
import { isRatingQuestion } from "../utils/apiUtils";
import { useForceUpdate } from "../utils/useForceUpdate";
import usePromise from "../utils/usePromise";
import { useSurveyState } from "../SurveyContext";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { RatingQuestionModel, UpdateRatingQuestionModel } from "../api/ManagerApi";
import { useDebounce } from "@uidotdev/usehooks";

type Props = {
  questionId: number;
};

export const RatingQuestion = ({ questionId }: Props) => {
  const { surveyId, pageId: surveyPageId } = useParams();
  const [sliderValue, setSliderValue] = useState(2);
  const [questionRefreshToken, updateQuestionToken] = useForceUpdate("Question");
  const { api, updatePageToken, updateSurveyToken, pageToken } = useSurveyState();
  const debouncedSliderValue = useDebounce(sliderValue, 500);
  const [valid, setValid] = useState(true);
  const [, updateView] = useForceUpdate("View");

  useEffect(() => updateQuestionToken(), [pageToken, updateQuestionToken]);

  const [question] = usePromise(() => {
    return api.surveys.getElement(Number(surveyId), questionId).then((r) => {
      if (isRatingQuestion(r.data)) {
        setSliderValue(r.data.options?.length ?? 2);
        return r.data;
      }
    });
  }, [questionRefreshToken]);

  const updateQuestion = useCallback(
    (questionModel: Partial<RatingQuestionModel>) => {
      if (!surveyId || !question || !surveyPageId) return;
      api.surveys
        .updateElement(Number(surveyId), question.id, {
          pageId: Number(surveyPageId),
          ...question,
          ...questionModel,
        } as UpdateRatingQuestionModel)
        .then(updateSurveyToken)
        .then(() => setValid(true))
        .then(updateQuestionToken)
        .catch(() => {
          setValid(false);
          Object.assign(question, questionModel);
          updateView();
        });
    },
    [api.surveys, question, surveyId, surveyPageId, updateSurveyToken, updateQuestionToken, updateView]
  );

  const updateOptions = useCallback(
    (opts: Partial<{ lowerDescr?: string; higherDescr?: string; optionsAmount?: number }>) => {
      const array = new Array<{ value: number; label?: string | null }>();
      for (let i = 0; i < (opts.optionsAmount ?? question?.options?.length ?? 2); i++) {
        array.push({ value: i, label: null });
      }
      array[0].label = opts.lowerDescr ?? question?.options?.[0]?.label ?? "Laagste waardering";
      array[array.length - 1].label =
        opts.higherDescr ??
        question?.options?.[question?.options?.length - 1]?.label ??
        "Hoogste waardering";
      updateQuestion({ options: array });
    },
    [question?.options, updateQuestion]
  );

  useEffect(() => {
    updateOptions({ optionsAmount: debouncedSliderValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSliderValue]);

  const deleteQuestion = useCallback(() => {
    if (!surveyId || !question) return;
    api.surveys.deleteElement(parseInt(surveyId), question.id).then(updateSurveyToken).then(updatePageToken);
  }, [api.surveys, question, surveyId, updatePageToken, updateSurveyToken]);

  if (!question) return null;

  return (
    <QuestionBox questionType="Waarderingsvraag" questionTitle={question.title} valid={valid}>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Vraag</Text>
        </FormLabel>
        <Input
          defaultValue={question.title}
          onBlur={(e) => updateQuestion({ title: e.currentTarget.value })}
        />
      </FormControl>
      <FormControl variant="floating">
        <FormLabel>
          <Text fontSize="md">Omschrijving</Text>
        </FormLabel>
        <Input
          defaultValue={question.content}
          onBlur={(e) => updateQuestion({ content: e.currentTarget.value })}
        />
      </FormControl>
      <Flex my={4} gap={4} alignItems="end">
        <Checkbox
          defaultChecked={question.required}
          onChange={(e) =>
            updateQuestion({
              ...question,
              required: e.target.checked,
            })
          }
        >
          Verplicht
        </Checkbox>
      </Flex>
      <Box display={"flex"} mt={4} gap={4}>
        <Box display={"flex"} flex={2}>
          <Text fontSize={"md"} flex={2} mt={1}>
            Aantal stappen
          </Text>
          <NumberInput value={question.options?.length} flex={1} isDisabled isReadOnly>
            <NumberInputField readOnly disabled />
          </NumberInput>
        </Box>
        {question.options && (
          <Slider
            aria-label="slider-ex-6"
            onChange={(val) => setSliderValue(val)}
            min={2}
            max={10}
            flex={2}
            defaultValue={sliderValue}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        )}
      </Box>
      <Box display={"flex"} gap={4}>
        <FormControl variant="floating" flex={1}>
          <FormLabel>
            <Text fontSize="md">Omschrijving laagste waardering</Text>
          </FormLabel>
          <Input
            defaultValue={question.options?.[0]?.label ?? undefined}
            onBlur={(e) => updateOptions({ lowerDescr: e.currentTarget.value })}
          />
        </FormControl>
        <FormControl variant="floating" flex={1}>
          <FormLabel>
            <Text fontSize="md">Omschrijving hoogste waardering</Text>
          </FormLabel>
          <Input
            defaultValue={question.options?.[question.options?.length - 1]?.label ?? undefined}
            onBlur={(e) => updateOptions({ higherDescr: e.currentTarget.value })}
          />
        </FormControl>
      </Box>

      {/* <Checkbox onChange={(e) => console.log(e.target.checked)} mt={4}>
        Gekleurde waarderingsblokken
      </Checkbox> */}

      <Flex>
        <Button ml="auto" mt={4} colorScheme="red" onClick={deleteQuestion}>
          Vraag verwijderen
        </Button>
      </Flex>
    </QuestionBox>
  );
};
