import { renderFace } from "./equirectangularToCubemap";

interface Gradient {
  [fraction: number]: string;
}

export const dummyData = new Array(1000)
  .fill([0, 0])
  .map((_) => [Math.random() * 360 - 180, Math.random() * 180 - 90]) as [number, number][];

export class EquirectangularHeatmap {
  public brushCanvas = document.createElement("canvas");
  public gradientCanvas = document.createElement("canvas");
  public targetCanvas = document.createElement("canvas");

  public gradientPixelData = new Uint8ClampedArray();
  
  public color = "black";
  public colorized = false;

  faces: { [key in keyof typeof Cubeface.positions]: Cubeface } = {} as any;

  constructor(
    public solidRadius: number,
    public blurRadius: number,
    public gradient: Gradient,
    image: HTMLImageElement
  ) {
    this.brushCanvas.width = this.brushCanvas.height = this.brushDiameter;
    (window as any).heatmap = this;

    this.gradientCanvas.width = 1;
    this.gradientCanvas.height = 256;

    this.targetCanvas.width = image.width;
    this.targetCanvas.height = image.height;

    this.updateGradient();
    this.updateBrush();
  }

  get brushRadius() {
    return this.solidRadius + this.blurRadius;
  }

  get brushDiameter() {
    return this.brushRadius * 2;
  }

  updateBrush() {
    const brushContext = this.brushCanvas.getContext("2d");

    if (!brushContext) return console.warn("Update brush failed");

    brushContext.shadowOffsetX = this.brushDiameter;
    brushContext.shadowBlur = this.blurRadius;
    brushContext.shadowColor = this.color;

    brushContext.beginPath();
    brushContext.arc(-this.brushRadius, this.brushRadius, this.solidRadius, 0, Math.PI * 2, true);
    brushContext.closePath();
    brushContext.fill();
  }

  updateGradient() {
    const gradientContext = this.gradientCanvas.getContext("2d");

    if (!gradientContext) return console.error("Update gradient failed");

    const gradient = gradientContext.createLinearGradient(0, 0, 0, this.gradientCanvas.height);

    Object.entries(this.gradient)
      .map<[number, string]>(([fraction, color]) => [Number(fraction), color])
      .filter(([fraction]) => fraction >= 0 && fraction <= 1)
      .forEach(([fraction, color]) => gradient.addColorStop(fraction, color));

    gradientContext.clearRect(0, 0, this.gradientCanvas.width, this.gradientCanvas.height);

    gradientContext.fillStyle = gradient;
    gradientContext.fillRect(0, 0, this.gradientCanvas.width, this.gradientCanvas.height);

    this.gradientPixelData = gradientContext.getImageData(
      0,
      0,
      this.gradientCanvas.width,
      this.gradientCanvas.height
    ).data;
  }

  /**
   * Draws a point with the center on the given longitude and latitude
   * @param longitude The yaw value of the point
   * @param latitude The pitch value of the point
   * @param alpha The global alpha value of the brush
   *
   * @todo The current implementation is inaccurate, latitudes nearing the -90 or 90. It can be made more accurate using the following steps
   *   - Calculate brush width/-height by determining the difference between the lat/long extremes;
   *   - Use a dynamic brush consisting of two semi-ellipses, which represent the distortion of the top and bottom halves;
   *   - Find an equation for drawing a circle onto a equirectangular projection
   */
  drawPoint(longitude: number, latitude: number, alpha = 0.5) {
    const context = this.targetCanvas.getContext("2d");

    if (!context) return console.warn("Draw point failed");

    const sphereRadius = this.targetCanvas.height;
    const halfArcSize = Math.abs((Math.atan(this.brushRadius / sphereRadius) * 180) / Math.PI);
    const minBrushSize = halfArcSize * (sphereRadius / 180);

    const brushWidth = this.calculateWidthDistortion(latitude) * this.brushDiameter;
    const brushHeight = Math.max(
      minBrushSize,
      this.calculateHeightDistortion(latitude) * this.brushDiameter
    );
    const offsetXCenter = (longitude + 180) * (sphereRadius / 180) - brushWidth / 2;
    const offsetYCenter = (180 - (latitude + 90)) * (sphereRadius / 180) - brushHeight / 2;

    context.globalAlpha = alpha;
    context.drawImage(this.brushCanvas, offsetXCenter, offsetYCenter, brushWidth, brushHeight);

    // Wrap extreme x-values
    if (offsetXCenter > this.targetCanvas.width - brushWidth) {
      context.drawImage(
        this.brushCanvas,
        offsetXCenter - this.targetCanvas.width,
        offsetYCenter,
        brushWidth,
        brushHeight
      );

      if (offsetYCenter < 0) {
        context.drawImage(
          this.brushCanvas,
          (offsetXCenter + sphereRadius) % this.targetCanvas.width,
          offsetYCenter,
          brushWidth,
          brushHeight
        );
      } else if (offsetYCenter > this.targetCanvas.height - brushHeight) {
        context.drawImage(
          this.brushCanvas,
          (offsetXCenter + sphereRadius) % this.targetCanvas.width,
          offsetYCenter,
          brushWidth,
          brushHeight
        );
      }
    } else if (offsetXCenter < 0) {
      context.drawImage(
        this.brushCanvas,
        offsetXCenter + this.targetCanvas.width,
        offsetYCenter,
        brushWidth,
        brushHeight
      );

      if (offsetYCenter < 0) {
        context.drawImage(
          this.brushCanvas,
          (offsetXCenter + sphereRadius) % this.targetCanvas.width,
          -(offsetYCenter + brushHeight),
          brushWidth,
          brushHeight
        );
      } else if (offsetYCenter > this.targetCanvas.height - brushHeight) {
        context.drawImage(
          this.brushCanvas,
          (offsetXCenter + sphereRadius) % this.targetCanvas.width,
          this.targetCanvas.height - (brushHeight - offsetYCenter),
          brushWidth,
          brushHeight
        );
      }
    }

    // Wrap extreme y-values
    if (offsetYCenter < 0) {
      context.drawImage(
        this.brushCanvas,
        (offsetXCenter + sphereRadius) % this.targetCanvas.width,
        -(offsetYCenter + brushHeight),
        brushWidth,
        brushHeight
      );
    } else if (offsetYCenter > this.targetCanvas.height - brushHeight) {
      context.drawImage(
        this.brushCanvas,
        (offsetXCenter + sphereRadius) % this.targetCanvas.width,
        this.targetCanvas.height - (brushHeight - offsetYCenter),
        brushWidth,
        brushHeight
      );
    }
  }

  /** Inaccurate, this does only calculate the world-height of a plane lying on the surface of a sphere/circle, but is good enough for y-values in the range [-70, 70] */
  private calculateHeightDistortion = (y: number) => Math.cos((y * Math.PI) / 180);

  private calculateWidthDistortion = (y: number) => {
    const sphereRadius = this.targetCanvas.height / 2;
    const yMapped = y / (90 / sphereRadius);
    return 1 / (((sphereRadius ** 2 - yMapped ** 2) ** 0.5 * 2) / (sphereRadius * 2));
  };

  async colorize() {
    if (this.colorized) return;
    const context = this.targetCanvas.getContext("2d");

    if (!context) return console.error("Invalid target");

    const imageData = context.getImageData(0, 0, this.targetCanvas.width, this.targetCanvas.height);

    if (imageData.data.length % 4) return console.error("Invalid pixel data");

    for (let i = 3; i < imageData.data.length; i += 4) {
      const alpha = imageData.data[i] / 0x100;

      const offset = Math.floor(alpha * 0xff);

      imageData.data.set(this.gradientPixelData.slice(offset * 4, offset * 4 + 4), i - 3);
    }

    context.putImageData(imageData, 0, 0);
    this.colorized = true;
  }

  async toCubeMap(): Promise<{ [key in keyof typeof Cubeface.positions]: Cubeface }> {
    const context = this.targetCanvas.getContext("2d");

    if (!context) throw new Error("Invalid target");

    const imageData = context.getImageData(0, 0, this.targetCanvas.width, this.targetCanvas.height);

    const faces = await Promise.all((Object.keys(Cubeface.positions) as (keyof typeof Cubeface.positions)[])
      .map((face) => {
        return new Promise((resolve, reject) => {
          const cubeface = new Cubeface(face);

          const writtenData = renderFace({
            readData: imageData,
            face: cubeface.name,
            rotation: 0,
            interpolation: "lanczos",
            maxWidth: Math.floor(this.targetCanvas.height / 2)
          });

          const faceCanvas = document.createElement("canvas");
          faceCanvas.width = writtenData.width;
          faceCanvas.height = writtenData.height;

          const faceContext = faceCanvas.getContext("2d");

          if (!faceContext) return reject("Invalid face");

          faceContext.putImageData(writtenData, 0, 0);

          faceCanvas.toBlob(
            (blob) => {
              if (!blob) return reject();

              const url = URL.createObjectURL(blob);
              cubeface.imageUrl = url;
              resolve({ [face]: cubeface });
            },
            "image/png",
            0.92
          );
        });
      })
    );

    return (this.faces = Object.assign({}, ...faces));
  }
}

class Cubeface {
  static positions = {
    pz: { x: 1, y: 1 },
    nz: { x: 3, y: 1 },
    px: { x: 2, y: 1 },
    nx: { x: 0, y: 1 },
    py: { x: 1, y: 0 },
    ny: { x: 1, y: 2 },
  };

  x: number;
  y: number;
  imageUrl = "";

  constructor(public name: keyof typeof Cubeface.positions) {
    const { x, y } = Cubeface.positions[name];

    this.x = x;
    this.y = y;
  }
}
