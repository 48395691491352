import { useParams } from "react-router-dom";
import { useSurveyState } from "../../../SurveyContext";
import usePromise from "../../../utils/usePromise";
import { Box, Flex, Grid, Stack, StackDivider, Text, VStack } from "@chakra-ui/react";
import { isLayerSource, isPanoramaSource } from "../../../utils/apiUtils";
import { DeleteIcon, ViewIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { ManageDataSourcesContext, Pages } from "./context";
import { PanoramaType } from "../../../api/enums";
import { ConfirmModal } from "../ConfirmModal";
import { SimplePanoViewer } from "../../../components/SimplePanoViewer";
import { SimpleMapViewer } from "../../../components/SimpleMapViewer";

export const DataSourceList = () => {
  const { api } = useSurveyState();
  const surveyId = Number(useParams().surveyId);
  const { setState, token, updateToken } = useContext(ManageDataSourcesContext);

  useEffect(() => {
    setState((c) => ({ ...c, nextPage: Pages.StartAdd }));
  }, [setState]);

  const [dataSources] = usePromise(
    () => token && api.surveys.listDataSources(surveyId).then((r) => r.data),
    [api, surveyId, token]
  );

  const panoSources = useMemo(() => dataSources?.filter(isPanoramaSource) ?? [], [dataSources]);
  const layerSources = useMemo(() => dataSources?.filter(isLayerSource) ?? [], [dataSources]);

  const deleteDataSource = useCallback(
    async (id: number) => {
      await api.surveys.deleteDataSource(surveyId, id);

      setState((c) => ({ ...c, sourcesUpdated: true }));
      updateToken();
    },
    [api, surveyId, updateToken, setState]
  );

  return (
    <Stack>
      <Text my={2}>Gekoppelde bronnen:</Text>
      {panoSources.length > 0 && (
        <Stack py={4}>
          <Text fontWeight="bold">Panorama's:</Text>
          {panoSources.map((dataSource, i) => (
            <>
              {i > 0 && <StackDivider borderColor="gray.300" borderStyle="solid" borderWidth={1} />}
              <Grid
                key={dataSource.name}
                justifyContent="space-between"
                templateColumns="1fr auto"
                w="100%"
              >
                <Stack overflow="hidden">
                  <Text>
                    {dataSource.name} (<i>{PanoramaType[dataSource.panoType]}</i>)
                  </Text>
                  <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    title={decodeURI(dataSource.imageUrl)}
                    fontSize="xs"
                  >
                    {decodeURI(dataSource.imageUrl)}
                  </Text>
                </Stack>
                <VStack justifyContent="space-evenly">
                  <ConfirmModal
                    onConfirm={() => deleteDataSource(dataSource.id)}
                    buttonNode={
                      <>
                        <DeleteIcon mr={2} /> Verwijderen
                      </>
                    }
                    buttonNodeProps={{ variant: "link", alignSelf: "end" }}
                    titleNode="Bron verwijderen"
                    bodyNode="Weet je zeker dat je deze bron wil verwijderen? deze actie kan niet ongedaan worden gemaakt."
                    confirmNode="Verwijderen"
                    primaryColor="red"
                  />
                  <ConfirmModal
                    modalProps={{ size: "4xl" }}
                    onConfirm={() => {
                      /* No action */
                    }}
                    buttonNode={
                      <>
                        <ViewIcon mr={2} /> Bekijken
                      </>
                    }
                    buttonNodeProps={{ variant: "link", alignSelf: "end" }}
                    titleNode="Panorama voorvertoning"
                    bodyNode={
                      <Box h={"xl"}>
                        {dataSource ? (
                          <SimplePanoViewer source={dataSource} />
                        ) : (
                          <Text color="red.500">
                            <WarningIcon /> De panorama kon niet worden geladen
                          </Text>
                        )}
                      </Box>
                    }
                    cancelNode="Sluiten"
                    confirmNode={null}
                    primaryColor="blue"
                  />
                </VStack>
              </Grid>
            </>
          ))}
        </Stack>
      )}
      {layerSources.length > 0 && (
        <Stack py={4}>
          <Text fontWeight="bold">Kaartlagen:</Text>
          {layerSources.map((dataSource, i) => (
            <>
              {i > 0 && <StackDivider borderColor="gray.300" borderStyle="solid" borderWidth={1} />}
              <Flex key={dataSource.name} justifyContent="space-between" w="100%">
                <Stack overflow="hidden">
                  <Text>
                    {dataSource.name} (
                    <i>
                      {dataSource.clusters.length} cluster{dataSource.clusters.length !== 1 && "s"}
                    </i>
                    )
                  </Text>
                  <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    title={dataSource.clusters.join(", ")}
                    fontSize="xs"
                  >
                    {dataSource.styleId}
                    <br />
                    {dataSource.clusters.join(", ")}
                  </Text>
                </Stack>
                <VStack justifyContent="space-evenly">
                  <ConfirmModal
                    onConfirm={() => deleteDataSource(dataSource.id)}
                    buttonNode={
                      <>
                        <DeleteIcon mr={2} /> Verwijderen
                      </>
                    }
                    buttonNodeProps={{ variant: "link", alignSelf: "end" }}
                    titleNode="Bron verwijderen"
                    bodyNode="Weet je zeker dat je deze bron wil verwijderen? deze actie kan niet ongedaan worden gemaakt."
                    confirmNode="Verwijderen"
                    primaryColor="red"
                  />
                  <ConfirmModal
                    modalProps={{ size: "4xl" }}
                    onConfirm={() => {
                      /* No action */
                    }}
                    buttonNode={
                      <>
                        <ViewIcon mr={2} /> Bekijken
                      </>
                    }
                    buttonNodeProps={{ variant: "link", alignSelf: "end" }}
                    titleNode="Kaartlaag voorvertoning"
                    bodyNode={
                      <Box h={"xl"}>
                        {dataSource ? (
                          <SimpleMapViewer source={dataSource} />
                        ) : (
                          <Text color="red.500">
                            <WarningIcon /> De kaart kon niet worden geladen
                          </Text>
                        )}
                      </Box>
                    }
                    cancelNode="Sluiten"
                    confirmNode={null}
                    primaryColor="blue"
                  />
                </VStack>
              </Flex>
            </>
          ))}
        </Stack>
      )}
      {dataSources && !dataSources.length && (
        <Flex as="p" color="yellow.500" mt={2} alignItems="center">
          <WarningTwoIcon mr={2} />
          Er zijn nog geen bronnen gekoppeld.
        </Flex>
      )}
    </Stack>
  );
};
